import styled, { keyframes } from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;

export const Container = styled.div`
  margin-left: 20%;
  margin-right: 2%;
  display: flex;
  flex-direction: column;
  animation: ${fade} 1s;
  header {
    display: flex;
    align-items: center;
    align-self: center;
    margin-bottom: 20px;
    button {
      border: 0;
      background: none;
    }
    strong {
      color: #4285f4;
      font-size: 24px;
      margin: 0 15px;
    }
  }
  h1 {
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
    margin-top: 3%;
    margin-bottom: 3%;
  }
`;

export const Time = styled.li`
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  opacity: ${(props) => (props.past ? 0.6 : 1)};
  strong {
    display: block;
    color: ${(props) => (props.available ? '#999' : '#7159c1')};
    font-size: 20px;
    font-weight: normal;
  }
  span {
    display: block;
    margin-top: 3px;
    color: ${(props) => (props.available ? '#999' : '#666')};
  }
`;
