import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  MDBDataTable,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from 'mdbreact';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { Container, Button, InputBig, InputSmall, Content } from './styles';
import useForm from '../../utils/useForm';

function ShowServices() {
  const [usersFranchisee, setUsersFranchisee] = useState([]);

  const profile = useSelector((state) => state.user.profile);
  const [reload, setReload] = useState(false);
  const [modal, setModal] = useState(false);
  const [idsa, setId] = useState();
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [morning, setMorning] = useState(false);
  const [evening, setEvening] = useState(false);
  const [seg, setSeg] = useState(false);
  const [loading, setLoading] = useState(false);

  const [ter, setTer] = useState(false);
  const [quar, setQuar] = useState(false);
  const [quin, setQuin] = useState(false);
  const [sex, setSex] = useState(false);
  const [sab, setSab] = useState(false);
  const [dom, setDom] = useState(false);
  async function deactivateUser(deactivate, id) {
    await api.put('servicesFrancheeseController', {
      deactivate: !deactivate,
      id,
    });
    setReload(!reload);
  }
  async function deleteService(id) {
    await api.post('servicesFrancheeseController', {
      id,
    });
    setReload(!reload);
  }

  async function editService(ids) {
    setModal(true);
    setId(ids);
  }
  async function UpdateService() {
    setLoading(true);
    const response = await api.put('allServicesUpdate', {
      name: values.name,
      crm: values.crm,
      product: false,
      evening,
      morning,
      seg,
      ter,
      quar,
      quin,
      sex,
      sab,
      dom,
      pricecard: values.pricecard,
      price: values.price,
      split: values.split,
      splitcard: values.splitcard,
      id: idsa,
    });

    if (response.status === 200) {
      toast.success('Serviço editado com sucesso.');
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(
        'Erro ao editar serviço, verifque os dados ou você não possui permissão.'
      );
    }
    setReload(!reload);
    setModal(false);
  }
  useEffect(() => {
    async function Appointments() {
      const response = await api.get('servicesFrancheeseController');
      const datas = response.data.filter((info) => {
        return info.product === false;
      });
      const datas2 = datas.filter((info) => {
        return info.created.created.id === profile.id;
      });
      setUsersFranchisee({
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 80,
          },
          {
            label: 'Código',
            field: 'code',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Grupo',
            field: 'group',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Sub-Grupo',
            field: 'subgroup',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Prodecimento',
            field: 'procedure',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Nome do médico',
            field: 'name',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'CRM',
            field: 'crm',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Preço com cartão',
            field: 'pricecard',
            sort: 'asc',
            width: 180,
          },
          {
            label: 'Preço sem cartão',
            field: 'price',
            sort: 'asc',
            width: 180,
          },
          {
            label: 'Split sem cartão',
            field: 'split',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Split com cartão',
            field: 'splitcard',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Editar',
            field: 'edit',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Desativar',
            field: 'deactivate',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Deletar',
            field: 'deleteService',
            sort: 'asc',
            width: 200,
          },
        ],
        rows: datas2.map((events) => ({
          ...events,
          deactivate: (
            <>
              {events.deactivate ? (
                <Button
                  onClick={() => deactivateUser(events.deactivate, events.id)}
                >
                  Ativar
                </Button>
              ) : (
                <Button
                  onClick={() => deactivateUser(events.deactivate, events.id)}
                >
                  Desativar
                </Button>
              )}
            </>
          ),
          deleteService: (
            <Button onClick={() => deleteService(events.id)}>Deletar</Button>
          ),
          edit: (
            <Button
              onClick={() => {
                editService(events.id);
                setDom(events.dom);
                setSeg(events.seg);
                setTer(events.ter);
                setQuar(events.quar);
                setQuin(events.quin);
                setSex(events.sex);
                setSab(events.sab);
                setDom(events.dom);
                setEvening(events.evening);
                setMorning(events.morning);
              }}
            >
              Editar
            </Button>
          ),
        })),
      });
    }
    Appointments();
  }, [reload]);

  return (
    <Container>
      <h1>Buscar Serviços</h1>
      <MDBModal size="lg" isOpen={modal}>
        <MDBModalHeader>
          <h1>Informações</h1>
        </MDBModalHeader>
        <Content>
          <MDBModalBody>
            <form>
              <h2>Nome do médico</h2>
              <InputBig onChange={handleChange} name="name" type="text" />
              <h2>CRM do médico</h2>
              <InputBig onChange={handleChange} name="crm" type="text" />
              <h2>Preço com cartão</h2>
              <InputBig onChange={handleChange} name="pricecard" type="text" />
              <h2>Preço sem cartão</h2>
              <InputBig onChange={handleChange} name="price" type="text" />
              <div style={{ marginLeft: '20%', marginRight: '20%' }}>
                <div>
                  <h2>Manhã</h2>
                  <InputSmall
                    type="checkbox"
                    checked={morning}
                    onChange={() => setMorning(!morning)}
                    name="morning"
                  />
                </div>
                <div>
                  <h2>Tarde</h2>
                  <InputSmall
                    type="checkbox"
                    checked={evening}
                    onChange={() => setEvening(!evening)}
                    name="evening"
                  />
                </div>
              </div>
              <div>
                <div>
                  <h2>Seg</h2>
                  <InputSmall
                    type="checkbox"
                    checked={seg}
                    onChange={() => setSeg(!seg)}
                    name="seg"
                  />
                </div>
                <div>
                  <h2 style={{ marginLeft: 0 }}>Ter</h2>
                  <InputSmall
                    type="checkbox"
                    checked={ter}
                    onChange={() => setTer(!ter)}
                    name="ter"
                  />
                </div>
                <div>
                  <h2 style={{ marginLeft: 0 }}>Quar</h2>
                  <InputSmall
                    type="checkbox"
                    checked={quar}
                    onChange={() => setQuar(!quar)}
                    name="quar"
                  />
                </div>
                <div>
                  <h2 style={{ marginLeft: 0 }}>Quin</h2>
                  <InputSmall
                    type="checkbox"
                    checked={quin}
                    onChange={() => setQuin(!quin)}
                    name="quin"
                  />
                </div>
                <div>
                  <h2 style={{ marginLeft: 0 }}>Sex</h2>
                  <InputSmall
                    type="checkbox"
                    checked={sex}
                    onChange={() => setSex(!sex)}
                    name="morning"
                  />
                </div>
                <div>
                  <h2 style={{ marginLeft: 0 }}>Sab</h2>
                  <InputSmall
                    type="checkbox"
                    checked={sab}
                    onChange={() => setSab(!sab)}
                    name="evening"
                  />
                </div>
                <div>
                  <h2 style={{ marginLeft: 0 }}>Dom</h2>
                  <InputSmall
                    type="checkbox"
                    checked={dom}
                    onChange={() => setDom(!dom)}
                    name="morning"
                  />
                </div>
              </div>
              <h2>Preço com cartão</h2>
              <InputBig onChange={handleChange} name="pricecard" type="text" />
              <div>
                <div>
                  <h2>Split com cartão (%) </h2>
                  <InputBig
                    onChange={handleChange}
                    style={{ width: 100 }}
                    name="splitcard"
                    type="text"
                  />
                </div>
                <div>
                  <h2 style={{ marginLeft: -450 }}>
                    Valor Rede: R$
                    {Number.isNaN((values.splitcard / 100) * values.pricecard)
                      ? 0
                      : ((values.splitcard / 100) * values.pricecard).toFixed(
                          2
                        )}
                  </h2>
                  <h2 style={{ marginLeft: 0 }}>
                    Valor Prestador: R$
                    {Number.isNaN(
                      values.pricecard -
                        (values.splitcard / 100) * values.pricecard
                    )
                      ? 0
                      : (
                          values.pricecard -
                          (values.splitcard / 100) * values.pricecard
                        ).toFixed(2)}
                  </h2>
                </div>
              </div>
              <h2>Preço sem cartão</h2>
              <InputBig onChange={handleChange} name="price" type="text" />
              <div>
                <div>
                  <h2>Split sem cartão (%) </h2>
                  <InputBig
                    onChange={handleChange}
                    style={{ width: 100 }}
                    name="split"
                    type="text"
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <h2 style={{ marginLeft: -450 }}>
                    Valor Rede: R$
                    {Number.isNaN((values.split / 100) * values.price)
                      ? 0
                      : ((values.split / 100) * values.price).toFixed(2)}
                  </h2>
                  <h2 style={{ marginLeft: 0 }}>
                    Valor Prestador: R$
                    {Number.isNaN(
                      values.price - (values.split / 100) * values.price
                    )
                      ? 0
                      : (
                          values.price -
                          (values.split / 100) * values.price
                        ).toFixed(2)}
                  </h2>
                </div>
              </div>
            </form>
          </MDBModalBody>
        </Content>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={() => UpdateService()}>
            Enviar
          </MDBBtn>
          <MDBBtn color="primary" onClick={() => setModal(false)}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <MDBDataTable
        striped
        responsive
        entries={10}
        maxHeight={500}
        scrollX
        data={usersFranchisee}
      />
    </Container>
  );
}

export default ShowServices;
