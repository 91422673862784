import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ceps from 'cep-promise';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import {
  Container,
  Content,
  InputBig,
  InputSmall,
  Button,
  RegisterButton,
} from './styles';
import { signUpRequest } from '../../store/modules/auth/actions';
import useForm from '../../utils/useForm';

export default function AddClient() {
  const dispatch = useDispatch();
  const [{ values, loading }, handleChange, handleSubmit] = useForm();
  const [cep, setCep] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [find, setFind] = useState(false);
  const [loading2, setLoading2] = useState(false);

  function enviarContato() {
    if (find === true) {
      if (
        values.name &&
        values.email &&
        values.password &&
        values.rg &&
        values.cpf &&
        values.phone &&
        cep &&
        values.number &&
        neighborhood &&
        address &&
        city &&
        values.born &&
        state !== ''
      ) {
        dispatch(
          signUpRequest(
            values.name,
            values.email,
            values.password,
            values.rg,
            values.cpf,
            values.born,
            values.phone,
            cep,
            values.number,
            neighborhood,
            address,
            city,
            state
          )
        );
      } else {
        toast.error('Todos os campos devem estar preenchidos!');
      }
    } else {
      toast.error('Valide o cep!');
    }
  }

  function checkCep() {
    setLoading2(true);
    ceps(cep)
      .then((res) => {
        setLoading2(false);
        setFind(true);
        setCity(res.city);
        setAddress(res.street);
        setNeighborhood(res.neighborhood);
        setState(res.state);
      })
      .catch(() => {
        setLoading2(false);
        setFind(false);
        toast.error('Cep invalido!');
      });
  }

  return (
    <Container>
      <Content>
        <h1>Cadastrar cliente</h1>
        <form onSubmit={handleSubmit(enviarContato)}>
          <h2>Nome</h2>
          <InputBig onChange={handleChange} name="name" type="text" />
          <h2>E-mail</h2>
          <InputBig
            onChange={handleChange}
            name="email"
            autocapitalize="off"
            type="email"
          />
          <h2>RG</h2>
          <InputBig onChange={handleChange} name="rg" mask="99.999.999-99" />
          <h2>Senha</h2>
          <InputBig onChange={handleChange} name="password" type="password" />
          <h2>CPF</h2>
          <InputBig onChange={handleChange} name="cpf" mask="999.999.999-99" />
          <h2>Data de nascimento</h2>
          <InputBig
            onChange={handleChange}
            name="born"
            maskPlaceholder="dd/mm/yyyy"
            mask="99/99/9999"
          />
          <h2>Telefone</h2>
          <div>
            <InputBig onChange={handleChange} name="phone" maxlength="11" />
          </div>
          <h2>CEP</h2>
          <div>
            <div>
              <InputBig
                onChange={(e) => setCep(e.target.value)}
                value={cep}
                name="cep"
                mask="99999-999"
              />
              {loading2 ? (
                <Button type="button">
                  <MDBIcon icon="compass" spin size="1x" fixed />
                  <span className="sr-only">Carregando...</span>
                </Button>
              ) : (
                <Button type="button" onClick={checkCep}>
                  <MDBIcon icon="compass" size="1x" fixed />
                </Button>
              )}
            </div>
            <h2>Número</h2>
            <div>
              <InputSmall onChange={handleChange} name="number" type="number" />
            </div>
          </div>
          <h2>Bairro</h2>
          <InputBig
            onChange={(e) => setNeighborhood(e.target.value)}
            value={neighborhood}
            name="neighborhood"
            type="text"
          />
          <h2>Rua</h2>
          <div>
            <InputBig
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              name="address"
              type="text"
            />
          </div>
          <h2>Cidade</h2>
          <div>
            <div>
              <InputBig
                onChange={(e) => setCity(e.target.value)}
                value={city}
                name="city"
                type="text"
              />
            </div>
            <h2>Estado</h2>
            <div>
              <InputSmall
                onChange={(e) => setState(e.target.value)}
                value={state}
                name="state"
                type="text"
                maxlength="2"
              />
            </div>
          </div>
          <RegisterButton type="submit">
            {loading ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Cadastrar'
            )}
          </RegisterButton>
        </form>
      </Content>
    </Container>
  );
}
