export function addToCartRequest(id) {
  return {
    type: '@cartProduct/ADD_REQUEST',
    id,
  };
}

export function addToCartSuccess(product) {
  return {
    type: '@cartProduct/ADD_SUCCESS',
    product,
  };
}

export function removeFromCart(id) {
  return {
    type: '@cartProduct/REMOVE',
    id,
  };
}
