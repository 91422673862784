/* eslint-disable no-inner-declarations */
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getMonth,
  parseISO,
  format,
  subMonths,
  addMonths,
  getYear,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import { MDBDataTable } from 'mdbreact';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { Container, Button } from './styles';
import AdminCardSection1 from '../../components/SectionsMoney/AdminCardSection1';
import api from '../../services/api';
import { formatPrice } from '../../utils/format';

export default function DashboardMoney() {
  const [info1, setInfo1] = useState([]);
  const [info2, setInfo2] = useState([]);
  const [info3, setInfo3] = useState([]);
  const [info4, setInfo4] = useState([]);
  const [frans, setFrans] = useState([]);
  const [sellers, setSellers] = useState([]);

  const [emitps, setEmitps] = useState([]);

  const [fran, setFran] = useState(false);
  const [issuer, setIssuer] = useState(true);
  const [seller, setSeller] = useState(true);
  const [date, setDate] = useState(new Date());
  const profile = useSelector((state) => state.user.profile);

  const dateFormatted = useMemo(() => format(date, 'MM/yyyy', { locale: pt }), [
    date,
  ]);
  function handlePrevDay() {
    setDate(subMonths(date, 1));
  }

  function handleNextDay() {
    setDate(addMonths(date, 1));
  }
  useEffect(() => {
    if (profile.admin) {
      async function getInfo1() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });

        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const datas3 = datas2.filter((info) => {
          return info.money === true;
        });
        const total = formatPrice(
          datas3.reduce((sumTotal, product) => sumTotal + product.valueadmin, 0)
        );
        setInfo1(total);
      }
      async function getInfo2() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });

        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const datas3 = datas2.filter((info) => {
          return info.money === true;
        });
        const total = formatPrice(
          datas3.reduce((sumTotal, product) => sumTotal + product.valuefran, 0)
        );
        setInfo2(total);
      }
      async function getInfo3() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });

        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const datas3 = datas2.filter((info) => {
          return info.money === true;
        });
        const total = formatPrice(
          datas3.reduce((sumTotal, product) => sumTotal + product.valueemitp, 0)
        );
        setInfo3(total);
      }
      async function getInfo4() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });

        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const datas3 = datas2.filter((info) => {
          return info.money === true;
        });
        const total = formatPrice(
          datas3.reduce((sumTotal, product) => sumTotal + product.valueemit, 0)
        );
        setInfo4(total);
      }
      async function getInfo5() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });

        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const datas3 = datas2.filter((info) => {
          return info.money === true;
        });
        const datas4 = await datas3.filter((info) => {
          return info.admin.id === profile.id;
        });
        const pickerData = await datas4.map((info) => {
          return {
            name: info.fran.name,
            valor: formatPrice(
              datas4
                .filter(({ fran }) => fran.id === info.fran.id)
                .reduce((sumTotal, product) => sumTotal + product.valuefran, 0)
            ),
          };
        });
        const reduced = [];
        pickerData.forEach((item) => {
          const duplicated =
            reduced.findIndex((redItem) => {
              return item.name === redItem.name;
            }) > -1;

          if (!duplicated) {
            reduced.push(item);
          }
        });
        setFrans({
          columns: [
            {
              label: 'Nome',
              field: 'name',
              sort: 'asc',
              width: 1100,
            },
            {
              label: 'Valor líquido',
              field: 'valor',
              sort: 'asc',
              width: 150,
            },
          ],
          rows: reduced.map((events) => ({
            ...events,
          })),
        });
      }
      async function getInfo6() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });

        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const datas3 = datas2.filter((info) => {
          return info.money === true;
        });
        const datas4 = await datas3.filter((info) => {
          return info.admin.id === profile.id;
        });

        const pickerData = await datas4.map((info) => {
          return {
            name: info.fature.name,
            valor: formatPrice(
              datas4
                .filter(({ fature }) => fature.name === info.fature.name)
                .reduce(
                  (sumTotal, product) =>
                    sumTotal + product.valueemit + product.valueemitp,
                  0
                )
            ),
            valorbrute: formatPrice(
              datas4
                .filter(({ fature }) => fature.name === info.fature.name)
                .reduce(
                  (sumTotal, product) =>
                    sumTotal +
                    product.valueemit +
                    product.valueadmin +
                    product.valueemitp +
                    product.valueseller +
                    product.valuefran,
                  0
                )
            ),
          };
        });

        const reduced = [];
        pickerData.forEach((item) => {
          const duplicated =
            reduced.findIndex((redItem) => {
              return item.name === redItem.name;
            }) > -1;

          if (!duplicated) {
            reduced.push(item);
          }
        });
        setEmitps({
          columns: [
            {
              label: 'Nome',
              field: 'name',
              sort: 'asc',
              width: 950,
            },
            {
              label: 'Valor líquido',
              field: 'valor',
              sort: 'asc',
              width: 150,
            },
            {
              label: 'Valor bruto',
              field: 'valorbrute',
              sort: 'asc',
              width: 150,
            },
          ],
          rows: reduced.map((events) => ({
            ...events,
          })),
        });
      }
      async function getInfo7() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });

        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const datas3 = datas2.filter((info) => {
          return info.money === true;
        });
        const datas4 = await datas3.filter((info) => {
          return info.admin.id === profile.id;
        });
        const pickerData = await datas4.map((info) => {
          return {
            name: info.seller.name,
            valor: formatPrice(
              datas4
                .filter(({ seller }) => seller.id === info.seller.id)
                .reduce(
                  (sumTotal, product) => sumTotal + product.valueseller,
                  0
                )
            ),
          };
        });
        const reduced = [];
        pickerData.forEach((item) => {
          const duplicated =
            reduced.findIndex((redItem) => {
              return item.name === redItem.name;
            }) > -1;

          if (!duplicated) {
            reduced.push(item);
          }
        });
        setSellers({
          columns: [
            {
              label: 'Nome',
              field: 'name',
              sort: 'asc',
              width: 1100,
            },
            {
              label: 'Valor líquido',
              field: 'valor',
              sort: 'asc',
              width: 150,
            },
          ],
          rows: reduced.map((events) => ({
            ...events,
          })),
        });
      }

      getInfo1();
      getInfo2();
      getInfo3();
      getInfo4();
      getInfo5();
      getInfo6();
      getInfo7();
    }
  }, [date]);
  return (
    <Container>
      <h1>Pagamentos em dinheiro</h1>
      <header style={{ marginBottom: 40 }}>
        <button type="button">
          <MdChevronLeft size={36} color="#4285F4" onClick={handlePrevDay} />
        </button>
        <strong>{dateFormatted}</strong>
        <button type="button">
          <MdChevronRight size={36} color="#4285F4" onClick={handleNextDay} />
        </button>
      </header>
      <AdminCardSection1
        info1={info1}
        info2={info2}
        info3={info3}
        info4={info4}
      />
      {!fran ? (
        <>
          <h1>Franqueados</h1>
          <MDBDataTable
            striped
            responsive
            entries={10}
            maxHeight={200}
            scrollX
            data={frans}
          />
          <Button
            type="button"
            onClick={() => {
              setFran(true);
              setSeller(true);
              setIssuer(false);
            }}
          >
            Emitente
          </Button>
        </>
      ) : null}
      {!issuer ? (
        <>
          <h1>Emitente</h1>
          <MDBDataTable
            striped
            responsive
            entries={10}
            maxHeight={200}
            scrollX
            data={emitps}
          />
          <div style={{ flexDirection: 'row', alignSelf: 'center' }}>
            <Button
              type="button"
              style={{ marginRight: 10 }}
              onClick={() => {
                setFran(false);
                setSeller(true);
                setIssuer(true);
              }}
            >
              Franqueados
            </Button>
            <Button
              type="button"
              onClick={() => {
                setFran(true);
                setSeller(false);
                setIssuer(true);
              }}
            >
              Vendedor
            </Button>
          </div>
        </>
      ) : null}
      {!seller ? (
        <>
          <h1>Vendedores</h1>
          <MDBDataTable
            striped
            responsive
            entries={10}
            maxHeight={200}
            scrollX
            data={sellers}
          />
          <Button
            type="button"
            onClick={() => {
              setFran(true);
              setSeller(true);
              setIssuer(false);
            }}
          >
            Emitente
          </Button>
        </>
      ) : null}
    </Container>
  );
}
