import { all, takeLatest, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';
import { updateProfileSuccess, updateProfileFailure } from './actions';

export function* updateProfile({ payload }) {
  try {
    const {
      name,
      email,
      split,
      splitcard,
      splitproduct,
      cnpj,
      phone,
      cpf,
      rg,
      provider,
      issuer,
      card,
      products,
      appointments,
      ...rest
    } = payload.data[0];

    let productss = false;
    let cards = false;
    let appointmentss = false;
    if (!issuer) {
      productss = false;
      cards = false;
      appointmentss = false;
    } else {
      productss = products;
      cards = card;
      appointmentss = appointments;
    }
    const profile = {
      issuer,
      name,
      email,
      split: appointments ? split : 0,
      splitcard: card ? splitcard : 0,
      splitproduct: products ? splitproduct : 0,
      card: cards,
      products: productss,
      appointments: appointmentss,
      cnpj,
      phone,
      cpf,
      rg,
      provider,
      ...(rest.password ? rest : {}),
    };
    const response = yield call(api.put, 'userSeller', profile);

    toast.success('Perfil atualizado com sucesso!');

    yield put(updateProfileSuccess(response.data));
  } catch (err) {
    toast.error('Erro ao atualizar perfil, confira seus dados!');

    yield put(updateProfileFailure());
  }
}

export default all([takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile)]);
