export function addToCartRequest(datas) {
  return {
    type: '@cartpayment/ADD_REQUEST',
    datas,
  };
}

export function addToCartSuccess(product) {
  return {
    type: '@cartpayment/ADD_SUCCESS',
    product,
  };
}

export function removeFromCart(id) {
  return {
    type: '@cartpayment/REMOVE',
    id,
  };
}
