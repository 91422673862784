import React, { useState } from 'react';
// import cep from 'cep-promise';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import { Container, Content, InputBig, RegisterButton } from './styles';
import api from '../../services/api';

import useForm from '../../utils/useForm';

export default function AddServices() {
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [loading2, setLoading] = useState(false);

  async function enviar() {
    if (
      values.code &&
      values.group &&
      values.subgroup &&
      values.procedure !== ''
    ) {
      setLoading(true);

      const response = await api.post('general', {
        code: Number(values.code),
        group: values.group,
        subgroup: values.subgroup,
        procedure: values.procedure,
        product: false,
      });
      setLoading(true);

      if (response.status === 200) {
        toast.success('Serviço criado com sucesso.');
        setLoading(false);
      } else if (response.status === 203) {
        setLoading(false);
        toast.error(
          'Erro ao criar serviço, verifque os dados ou você não possui permissão.'
        );
      }
    } else {
      toast.error('Todos os campos devem estar preenchidos!');
    }
  }

  return (
    <Container>
      <Content horizontal={false}>
        <h1>Cadastrar serviço</h1>
        <form onSubmit={handleSubmit(enviar)}>
          <h2>Código</h2>
          <InputBig onChange={handleChange} name="code" type="number" />
          <h2>Grupo</h2>
          <InputBig onChange={handleChange} name="group" type="text" />
          <h2>Sub-grupo</h2>
          <InputBig onChange={handleChange} name="subgroup" type="text" />
          <h2>Procedimento</h2>
          <InputBig onChange={handleChange} name="procedure" type="text" />
          <RegisterButton type="submit">
            {loading2 ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Cadastrar'
            )}
          </RegisterButton>
        </form>
      </Content>
    </Container>
  );
}
