import React, { useState, useEffect } from 'react';
import { MDBIcon } from 'mdbreact';
import { useDispatch } from 'react-redux';
import { Container, InputBig, RegisterButton } from './styles';
import api from '../../services/api';
import useForm from '../../utils/useForm';
import { addToCartRequest } from '../../store/modules/paymantCardProduct/actions';

function ShowAppointments() {
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    async function getUsers() {
      const response = await api.get('users');
      setUsers(response.data);
    }

    getUsers();
  }, []);
  function handleChangeEmail(e) {
    setEmail(e);
  }
  async function enviar() {
    if (email !== '') {
      setLoading(true);
      const response = await api.get('allAppointmentsAdmin');
      const datas = await response.data.filter((info) => {
        return info.user.email === email;
      });
      const datas2 = await datas.filter((info) => {
        return info.service.product === true;
      });
      const datas3 = await datas2.filter((info) => {
        return info.payed === false;
      });

      dispatch(addToCartRequest(datas3));

      setLoading(false);
    }
  }
  return (
    <Container>
      <h1>Buscar Produtos</h1>
      <form onSubmit={handleSubmit(enviar)}>
        <div>
          <h2>Email do paciente</h2>
          <select
            onChange={(e) => handleChangeEmail(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o nome do cliente</option>
            {users.map(function (object) {
              return (
                <option value={object.email}>
                  {object.name} || {object.email}
                </option>
              );
            })}
          </select>
        </div>
        <RegisterButton type="submit">
          {loading ? (
            <>
              <MDBIcon icon="spinner" spin size="2x" fixed />
              <span className="sr-only">Carregando...</span>
            </>
          ) : (
            'Procurar'
          )}
        </RegisterButton>
      </form>
    </Container>
  );
}

export default ShowAppointments;
