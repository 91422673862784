import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  MDBDataTable,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from 'mdbreact';
import { toast } from 'react-toastify';
import { Container, Button, InputBig } from './styles';
import api from '../../services/api';
import useForm from '../../utils/useForm';

function ShowCards() {
  const [card, setCard] = useState([]);
  const [reload, setReload] = useState(false);
  const [modal, setModal] = useState(false);
  const [idsa, setId] = useState();
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [loadingFiles, setLoadingFiles] = useState([]);
  const profile = useSelector((state) => state.user.profile);
  async function deleteUser(id) {
    await api.post('cardDelete', {
      id,
    });
    setReload(!reload);
  }
  async function deactivateUser(deactivate, id) {
    await api.put('card', {
      deactivate: !deactivate,
      id,
    });
    setReload(!reload);
  }
  async function editCard(ids) {
    setModal(true);
    setId(ids);
  }
  function handleChangePreview(e) {
    setFile(e);
    const datas = loadingFiles.filter((info) => {
      return info.id === Number(e);
    });
    setPreview(datas[0].url);
  }
  async function UpdateCard() {
    const response = await api.put('cardEdit', {
      due_date: values.due_date,
      price: values.price,
      id: idsa,
      avatar_id: Number(file),
    });
    if (response.status === 200) {
      toast.success('Cartão editado com sucesso.');
    } else {
      toast.error(
        'Erro ao editar cartão, verifque os dados ou você não possui permissão.'
      );
    }
    setReload(!reload);
    setModal(false);
  }

  useEffect(() => {
    async function loadFiles() {
      const response = await api.get('files');
      setLoadingFiles(response.data);
    }
    async function loadCards() {
      const response = await api.get('cardFranchiseeController');
      const datas = response.data.filter((info) => {
        return info.created.created.id === profile.id;
      });

      setCard({
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Validade',
            field: 'due_date',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Preço',
            field: 'price',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Editar',
            field: 'edit',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Desativar',
            field: 'deactivate',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Deletar',
            field: 'deleteService',
            sort: 'asc',
            width: 200,
          },
        ],
        rows: datas.map((events) => ({
          ...events,
          deactivate: (
            <>
              {events.deactivate ? (
                <Button
                  onClick={() => deactivateUser(events.deactivate, events.id)}
                >
                  Ativar
                </Button>
              ) : (
                <Button
                  onClick={() => deactivateUser(events.deactivate, events.id)}
                >
                  Desativar
                </Button>
              )}
            </>
          ),
          deleteService: (
            <Button onClick={() => deleteUser(events.id)}>Deletar</Button>
          ),
          edit: <Button onClick={() => editCard(events.id)}>Editar</Button>,
        })),
      });
    }
    loadFiles();
    loadCards();
  }, [reload]);

  return (
    <Container>
      <h1>Lista de cartões</h1>
      <MDBModal isOpen={modal} toggle={setModal}>
        <MDBModalHeader toggle={setModal}>
          <h1>Informações</h1>
        </MDBModalHeader>
        <MDBModalBody>
          <h2>Preço do cartão</h2>
          <InputBig onChange={handleChange} name="price" type="number" />
          <h2>Validade do cartão em dias</h2>
          <InputBig
            style={{ marginBottom: 30 }}
            onChange={handleChange}
            name="due_date"
            type="number"
          />
          <select
            style={{ width: '70%' }}
            onChange={(e) => handleChangePreview(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha a imagem do cartão</option>
            {loadingFiles.map(function (object) {
              return <option value={object.id}>{object.name}</option>;
            })}
          </select>
        </MDBModalBody>
        {!preview ? null : (
          <img
            style={{ alignSelf: 'center' }}
            width="200"
            height="200"
            src={preview}
            alt=""
          />
        )}
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={() => UpdateCard()}>
            Enviar
          </MDBBtn>
          <MDBBtn color="primary" onClick={() => setModal(false)}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <MDBDataTable
        striped
        responsive
        entries={10}
        maxHeight={500}
        scrollX
        data={card}
      />
    </Container>
  );
}

export default ShowCards;
