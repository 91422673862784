/* eslint-disable no-inner-declarations */
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getMonth,
  parseISO,
  format,
  subMonths,
  addMonths,
  getYear,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { signOut } from '../../store/modules/auth/actions';
import { Container } from './styles';
import AdminCardSection1 from '../../components/sections/AdminCardSection1';
import ChartSection1 from '../../components/sections/ChartSection1';
import api from '../../services/api';

import { formatPrice } from '../../utils/format';

export default function Dashboard() {
  const dispatch = useDispatch();

  const [info1, setInfo1] = useState([]);
  const [info2, setInfo2] = useState([]);
  const [info3, setInfo3] = useState([]);
  const [info4, setInfo4] = useState([]);
  const [deactivate, setDeactivate] = useState(false);
  const [modal, setModal] = useState(false);

  const [dataBar, setDataBar] = useState();
  const [dataPie, setDataPie] = useState();
  const [date, setDate] = useState(new Date());
  const profile = useSelector((state) => state.user.profile);

  const dateFormatted = useMemo(() => format(date, 'MM/yyyy', { locale: pt }), [
    date,
  ]);

  function handleLogout() {
    dispatch(signOut());
  }
  async function changeModal() {
    setModal(!modal);
  }
  function handlePrevDay() {
    setDate(subMonths(date, 1));
  }

  function handleNextDay() {
    setDate(addMonths(date, 1));
  }
  useEffect(() => {
    async function getUserInfo() {
      const response2 = await api.get('sessions');
      setDeactivate(response2.data[0].deactivate);
    }
    if (profile.admin) {
      async function getInfo1() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });

        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const total = formatPrice(
          datas2.reduce((sumTotal, product) => sumTotal + product.valueadmin, 0)
        );
        setInfo1(total);
      }
      async function getInfo2() {
        const response = await api.get('usersFranchisee');

        setInfo2(response.data);
      }
      async function getInfo3() {
        const response = await api.get('usersIssuer');
        setInfo3(response.data);
      }
      async function getInfo4() {
        const response = await api.get('allAppointments');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });
        const datas2 = datas.filter((info) => {
          return getMonth(parseISO(info.date)) === date.getMonth();
        });
        const datas3 = datas2.filter((info) => {
          return info.service.product === false;
        });

        setInfo4(datas3);
      }
      async function getDataBar() {
        const accomplished = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const response = await api.get('allAppointments');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });
        await datas.filter((info) => {
          const data = getMonth(parseISO(info.created_at));
          accomplished[data] += 1;
        });
        const payeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const response2 = await api.get('allAppointmentsPayed');
        const datas2 = response2.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });
        await datas2.filter((info) => {
          const data = getMonth(parseISO(info.created_at));
          payeds[data] += 1;
        });
        const notPayeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const response3 = await api.get('allAppointmentsNotPayed');
        const datas3 = response3.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });
        await datas3.filter((info) => {
          const data = getMonth(parseISO(info.created_at));
          notPayeds[data] += 1;
        });
        const all = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const response4 = await api.get('allAppointmentsAdmin');
        const datas4 = response4.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });
        await datas4.filter((info) => {
          const data = getMonth(parseISO(info.created_at));
          all[data] += 1;
        });
        setDataBar({
          labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
          datasets: [
            {
              label: 'Registrados',
              data: all,
              backgroundColor: 'rgba(13, 74, 60, 0.5)',
              borderWidth: 1,
            },
            {
              label: 'Não Pago',
              data: notPayeds,
              backgroundColor: 'rgba(245, 74, 85, 0.5)',
              borderWidth: 1,
            },
            {
              label: 'Pagos',
              data: payeds,
              backgroundColor: 'rgba(90, 173, 246, 0.5)',
              borderWidth: 1,
            },
            {
              label: 'Realizados',
              data: accomplished,
              backgroundColor: 'rgba(245, 192, 50, 0.5)',
              borderWidth: 1,
            },
          ],
        });
      }
      async function getDataPie() {
        const response = await api.get('fature');
        const datas = response.data.filter((info) => {
          return getYear(parseISO(info.date)) === date.getFullYear();
        });
        const fature = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        await datas.filter((info) => {
          const data = getMonth(parseISO(info.created_at));
          fature[data] += info.valueadmin;
        });
        setDataPie({
          labels: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
          ],
          datasets: [
            {
              label: 'Rendimento',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(75,192,192,1)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(75,192,192,1)',
              pointHoverBorderColor: 'rgba(220,220,220,1)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: fature,
            },
          ],
        });
      }
      getInfo1();
      getInfo2();
      getInfo3();
      getInfo4();
      getDataBar();
      getDataPie();
    }
    if (!deactivate) {
      if (profile.franchisee) {
        async function getInfo1() {
          const response = await api.get('fature');
          const datas = await response.data.filter((info) => {
            return info.fran.id === Number(profile.id);
          });

          const datas2 = datas.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas3 = datas2.filter((info) => {
            return getMonth(parseISO(info.date)) === date.getMonth();
          });
          const total = formatPrice(
            datas3.reduce(
              (sumTotal, product) => sumTotal + product.valuefran,
              0
            )
          );
          setInfo1(total);
        }
        async function getInfo2() {
          const response = await api.get('getusersIssuer');
          setInfo2(response.data);
        }
        async function getInfo3() {
          const response = await api.get('getSellerUserController');

          const datas = await response.data.filter((info) => {
            return info.created.created.id === profile.id;
          });
          setInfo3(datas);
        }
        async function getInfo4() {
          const response = await api.get('allAppointments');
          const datas = await response.data.filter((info) => {
            return info.seller.created.created.id === Number(profile.id);
          });
          const datas2 = datas.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas3 = datas2.filter((info) => {
            return getMonth(parseISO(info.date)) === date.getMonth();
          });
          const datas4 = datas3.filter((info) => {
            return info.service.product === false;
          });

          setInfo4(datas4);
        }
        async function getDataBar() {
          const accomplished = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response = await api.get('allAppointments');
          const datas = response.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas2 = await datas.filter((info) => {
            return info.seller.created.created.id === Number(profile.id);
          });
          await datas2.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            accomplished[data] += 1;
          });
          const payeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response2 = await api.get('allAppointmentsPayed');
          const datas3 = response2.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas4 = await datas3.filter((info) => {
            return info.seller.created.created.id === Number(profile.id);
          });
          await datas4.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            payeds[data] += 1;
          });
          const notPayeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response3 = await api.get('allAppointmentsNotPayed');
          const datas5 = response3.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas6 = await datas5.filter((info) => {
            return info.seller.created.created.id === Number(profile.id);
          });
          await datas6.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            notPayeds[data] += 1;
          });
          const all = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response4 = await api.get('allAppointmentsAdmin');
          const datas7 = response4.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas8 = await datas7.filter((info) => {
            return info.seller.created.created.id === Number(profile.id);
          });
          await datas8.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            all[data] += 1;
          });

          setDataBar({
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ],
            datasets: [
              {
                label: 'Registrados',
                data: all,
                backgroundColor: 'rgba(13, 74, 60, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Não Pago',
                data: notPayeds,
                backgroundColor: 'rgba(245, 74, 85, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Pagos',
                data: payeds,
                backgroundColor: 'rgba(90, 173, 246, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Realizados',
                data: accomplished,
                backgroundColor: 'rgba(245, 192, 50, 0.5)',
                borderWidth: 1,
              },
            ],
          });
        }
        async function getDataPie() {
          const response = await api.get('fature');
          const datas = response.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const fature = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const datas2 = await datas.filter((info) => {
            return info.fran.id === Number(profile.id);
          });
          await datas2.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            fature[data] += info.valuefran;
          });
          setDataPie({
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ],
            datasets: [
              {
                label: 'Rendimento',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: fature,
              },
            ],
          });
        }
        getUserInfo();
        getInfo1();
        getInfo2();
        getInfo3();
        getInfo4();
        getDataBar();
        getDataPie();
      }
    } else {
      changeModal();
    }
    if (!deactivate) {
      if (profile.issuer || profile.provider) {
        async function getInfo1() {
          const response = await api.get('fature');
          const datas = await response.data.filter((info) => {
            return info.emit_id === Number(profile.id);
          });

          const datas2 = datas.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas3 = datas2.filter((info) => {
            return getMonth(parseISO(info.date)) === date.getMonth();
          });

          const total = datas3.reduce(
            (sumTotal, product) => sumTotal + product.valueemit,
            0
          );

          const datas4 = await response.data.filter((info) => {
            return info.emitp_id === Number(profile.id);
          });
          const datas5 = datas4.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas6 = datas5.filter((info) => {
            return getMonth(parseISO(info.date)) === date.getMonth();
          });

          const total2 = datas6.reduce(
            (sumTotal, product) => sumTotal + product.valueemitp,
            0
          );
          setInfo1(formatPrice(total + total2));
        }
        async function getInfo2() {
          const response = await api.get('card');
          setInfo2(response.data);
        }
        async function getInfo3() {
          const response = await api.get('usersSeller');
          setInfo3(response.data);
        }
        async function getInfo4() {
          const response = await api.get('allAppointments');
          const datas = await response.data.filter((info) => {
            return info.seller.created.id === Number(profile.id);
          });
          const datas2 = datas.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas3 = datas2.filter((info) => {
            return getMonth(parseISO(info.date)) === date.getMonth();
          });
          const datas4 = datas3.filter((info) => {
            return info.service.product === false;
          });
          setInfo4(datas4);
        }
        async function getDataBar() {
          const accomplished = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response = await api.get('allAppointments');
          const datas = response.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas2 = await datas.filter((info) => {
            return info.seller.created.id === Number(profile.id);
          });
          await datas2.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            accomplished[data] += 1;
          });
          const payeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response2 = await api.get('allAppointmentsPayed');
          const datas3 = response2.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas4 = await datas3.filter((info) => {
            return info.seller.created.id === Number(profile.id);
          });
          await datas4.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            payeds[data] += 1;
          });
          const notPayeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response3 = await api.get('allAppointmentsNotPayed');
          const datas5 = response3.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas6 = await datas5.filter((info) => {
            return info.seller.created.id === Number(profile.id);
          });
          await datas6.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            notPayeds[data] += 1;
          });
          const all = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response4 = await api.get('allAppointmentsAdmin');
          const datas7 = response4.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas8 = await datas7.filter((info) => {
            return info.seller.created.id === Number(profile.id);
          });
          await datas8.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            all[data] += 1;
          });

          setDataBar({
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ],
            datasets: [
              {
                label: 'Registrados',
                data: all,
                backgroundColor: 'rgba(13, 74, 60, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Não Pago',
                data: notPayeds,
                backgroundColor: 'rgba(245, 74, 85, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Pagos',
                data: payeds,
                backgroundColor: 'rgba(90, 173, 246, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Realizados',
                data: accomplished,
                backgroundColor: 'rgba(245, 192, 50, 0.5)',
                borderWidth: 1,
              },
            ],
          });
        }
        async function getDataPie() {
          const response = await api.get('fature');
          const datas = response.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const fature = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          const datas2 = await datas.filter((info) => {
            return info.emit_id === Number(profile.id);
          });
          await datas2.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            fature[data] += info.valueemit;
          });
          const datas3 = await datas2.filter((info) => {
            return info.emitp_id === Number(profile.id);
          });
          await datas3.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            fature[data] += info.valueemitp;
          });
          setDataPie({
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ],
            datasets: [
              {
                label: 'Rendimento',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: fature,
              },
            ],
          });
        }
        getUserInfo();
        getInfo1();
        getInfo2();
        getInfo3();
        getInfo4();
        getDataBar();
        getDataPie();
      }
    } else {
      changeModal();
    }
    if (!deactivate) {
      if (profile.seller) {
        async function getInfo1() {
          const response = await api.get('fature');
          const datas = await response.data.filter((info) => {
            return info.seller.id === Number(profile.id);
          });
          const datas2 = datas.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas3 = datas2.filter((info) => {
            return getMonth(parseISO(info.date)) === date.getMonth();
          });
          const total = formatPrice(
            datas3.reduce(
              (sumTotal, product) => sumTotal + product.valueseller,
              0
            )
          );
          setInfo1(total);
        }
        async function getInfo2() {
          const response = await api.get(`paymantCardSeller/${profile.id}`);
          const datas = await response.data.filter((info) => {
            return info.payed_at !== null;
          });
          setInfo2(datas);
        }
        async function getInfo3() {
          const response = await api.get(`userSeller/${profile.id}`);
          setInfo3(response.data);
        }
        async function getInfo4() {
          const response = await api.get('allAppointments');
          const datas = await response.data.filter((info) => {
            return info.seller.id === Number(profile.id);
          });
          const datas2 = datas.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas3 = datas2.filter((info) => {
            return getMonth(parseISO(info.date)) === date.getMonth();
          });
          const datas4 = datas3.filter((info) => {
            return info.service.product === false;
          });
          setInfo4(datas4);
        }
        async function getDataBar() {
          const accomplished = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response = await api.get('allAppointments');
          const datas = response.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas2 = await datas.filter((info) => {
            return info.seller.id === Number(profile.id);
          });
          await datas2.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            accomplished[data] += 1;
          });
          const payeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response2 = await api.get('allAppointmentsPayed');
          const datas3 = response2.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas4 = await datas3.filter((info) => {
            return info.seller.id === Number(profile.id);
          });
          await datas4.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            payeds[data] += 1;
          });
          const notPayeds = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response3 = await api.get('allAppointmentsNotPayed');
          const datas5 = response3.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas6 = await datas5.filter((info) => {
            return info.seller.id === Number(profile.id);
          });
          await datas6.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            notPayeds[data] += 1;
          });
          const all = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          const response4 = await api.get('allAppointmentsAdmin');
          const datas7 = response4.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const datas8 = await datas7.filter((info) => {
            return info.seller.id === Number(profile.id);
          });
          await datas8.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            all[data] += 1;
          });

          setDataBar({
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ],
            datasets: [
              {
                label: 'Registrados',
                data: all,
                backgroundColor: 'rgba(13, 74, 60, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Não Pago',
                data: notPayeds,
                backgroundColor: 'rgba(245, 74, 85, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Pagos',
                data: payeds,
                backgroundColor: 'rgba(90, 173, 246, 0.5)',
                borderWidth: 1,
              },
              {
                label: 'Realizados',
                data: accomplished,
                backgroundColor: 'rgba(245, 192, 50, 0.5)',
                borderWidth: 1,
              },
            ],
          });
        }
        async function getDataPie() {
          const response = await api.get('fature');
          const datas = response.data.filter((info) => {
            return getYear(parseISO(info.date)) === date.getFullYear();
          });
          const fature = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          const datas2 = await datas.filter((info) => {
            return info.seller.id === Number(profile.id);
          });
          await datas2.filter((info) => {
            const data = getMonth(parseISO(info.created_at));
            fature[data] += info.valueseller;
          });

          setDataPie({
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ],
            datasets: [
              {
                label: 'Rendimento',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: fature,
              },
            ],
          });
        }
        getUserInfo();
        getInfo1();
        getInfo2();
        getInfo3();
        getInfo4();
        getDataBar();
        getDataPie();
      }
    } else {
      changeModal();
    }
  }, [date, deactivate]);

  return (
    <Container>
      <h1>Dashboard</h1>
      <MDBModal backdrop={false} isOpen={modal} toggle={changeModal}>
        <MDBModalHeader toggle={changeModal}>
          <h1>Bloqueio temporário</h1>
        </MDBModalHeader>
        <MDBModalBody>
          <h4>
            <strong>Entre em contato com a Rede Climed Brasil</strong>
          </h4>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={handleLogout}>
            Ok
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <AdminCardSection1
        info1={info1}
        info2={info2.length}
        info3={info3.length}
        info4={info4.length}
      />
      <header>
        <button type="button">
          <MdChevronLeft size={36} color="#4285F4" onClick={handlePrevDay} />
        </button>
        <strong>{dateFormatted}</strong>
        <button type="button">
          <MdChevronRight size={36} color="#4285F4" onClick={handleNextDay} />
        </button>
      </header>
      <ChartSection1 dataBar={dataBar} dataPie={dataPie} />
    </Container>
  );
}
