import React, { useState, useEffect } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { toast } from 'react-toastify';

import { subHours } from 'date-fns';

import {
  Container,
  Time,
  ContainerCalendar,
  ConfirmationButton,
} from './styles';
import api from '../../services/api';
import 'react-calendar/dist/Calendar.css';

function ShowAppointmentsUsers() {
  const [appointments, setAppointments] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get('users');
      setUsers(response.data);
    }

    loadUsers();
  }, []);

  async function changeDate(date, ids, item) {
    const response = await api.put('appointments', {
      ids,
      date: subHours(new Date(date), 3),
    });

    const response2 = await api.get('allAppointmentsAdmin');
    const datas = await response2.data.filter((info) => {
      return info.user.id === Number(item.id);
    });
    const datas2 = await datas.filter((info) => {
      return info.service.product === false;
    });
    if (response.status === 200) {
      toast.success('Data alterada com sucesso.');
    } else {
      toast.success('Erro ao alterar data.');
    }
    setAppointments(datas2.reverse());
  }
  async function DeleteAppointment(id, item) {
    const response = await api.post('appointmentsDelete', {
      id,
    });
    const response2 = await api.get('allAppointmentsAdmin');
    const datas = await response2.data.filter((info) => {
      return info.user.id === Number(item.id);
    });
    const datas2 = await datas.filter((info) => {
      return info.service.product === false;
    });
    if (response.status === 200) {
      toast.success('Agendamento deletado com sucesso .');
    } else {
      toast.success('Erro ao deletar agendamento.');
    }
    setAppointments(datas2.reverse());
  }

  async function handleOnSelect(item) {
    const response = await api.get('allAppointmentsAdmin');
    const datas = await response.data.filter((info) => {
      return info.user.id === Number(item.id);
    });
    const datas2 = await datas.filter((info) => {
      return info.service.product === false;
    });

    setAppointments(datas2.reverse());
  }

  return (
    <>
      <ContainerCalendar>
        <h1>Pesquisar Cliente</h1>
        <ReactSearchAutocomplete
          items={users}
          onSelect={handleOnSelect}
          autoFocus
        />
      </ContainerCalendar>
      <Container>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div style={{ flexDirection: 'row', height: 200 }}>
            <ul>
              {appointments.map((appointment) => (
                <div style={{ flexDirection: 'row' }}>
                  <Time
                    key={appointment.id}
                    past={appointment.payed}
                    available={appointment.payed}
                  >
                    <div>
                      <div style={{ flexDirection: 'column' }}>
                        <strong>{appointment.user.name}</strong>
                        <span>C.P.F: {appointment.user.cpf}</span>
                        <span>
                          Procedimento: {appointment.service.procedure}
                        </span>
                        <span>Local: {appointment.service.created.name}</span>
                        <DateTimePicker
                          disabled={appointment.payed}
                          value={appointment.date}
                          style={{ fontWeight: 'bold', color: '#000' }}
                          ampm={false}
                          onChange={(date) =>
                            changeDate(
                              date,
                              appointment.id,
                              appointment.user.id
                            )
                          }
                          format="DD/MM/YYYY"
                        />
                      </div>
                      <div
                        style={{
                          width: 130,
                          borderRadius: 5,
                          height: 40,
                          marginTop: 5,
                          marginLeft: '30%',
                          background: '#4285F4',
                          alignSelf: 'flex-end',
                          justifyContent: 'center',
                        }}
                      >
                        <ConfirmationButton
                          disabled={appointment.payed}
                          onClick={() =>
                            DeleteAppointment(
                              appointment.id,
                              appointment.user.id
                            )
                          }
                        >
                          Remover
                        </ConfirmationButton>
                      </div>
                    </div>
                  </Time>
                </div>
              ))}
            </ul>
          </div>
        </MuiPickersUtilsProvider>
      </Container>
    </>
  );
}

export default ShowAppointmentsUsers;
