/* eslint-disable prefer-spread */
import React, { useEffect, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';

import { Container } from './styles';
import { updateProfileRequest } from '../../store/modules/user/actions';
import api from '../../services/api';

export default function Profile() {
  const [users, setUsers] = useState([]);
  const [usersIndividual, setIndividual] = useState([]);

  const [products, setProducts] = useState(false);
  const [appointments, setAppointments] = useState(false);
  const [card, setCard] = useState(false);
  const profile = useSelector((s) => s.user.profile);

  const dispatch = useDispatch();
  const formRef = useRef(null);
  useEffect(() => {
    async function getUsers() {
      const response = await api.get('AllSellerUserController');
      const datas = response.data.filter((info) => {
        return info.created.created.id === profile.id;
      });

      setUsers(datas);
    }

    getUsers();
  }, []);

  function handleSubmit(datas) {
    const data = [{ ...datas, products, appointments, card }];

    if (100 - profile.split - datas.split - usersIndividual.created.split < 0) {
      toast.error(
        `valor permitido para agendamentos de até, ${
          100 - profile.split - usersIndividual.created.split
        }`
      );
    } else if (
      100 -
        profile.splitcard -
        datas.splitcard -
        usersIndividual.created.splitcard <
      0
    ) {
      toast.error(
        `valor permitido para cartão de até, ${
          100 - profile.splitcard - usersIndividual.created.splitcard
        }`
      );
    } else if (
      100 -
        profile.splitproduct -
        datas.splitproduct -
        usersIndividual.created.splitproduct <
      0
    ) {
      toast.error(
        `valor permitido para produtos de até, ${
          100 - profile.splitproduct - usersIndividual.created.splitproduct
        }`
      );
    } else {
      dispatch(updateProfileRequest(data));
    }
  }
  async function userInfo(email) {
    const datas = await users.filter((info) => {
      return info.email === email;
    });
    await setIndividual(datas[0]);
    await setAppointments(datas[0].appointments);
    await setProducts(datas[0].products);
    await setCard(datas[0].card);
    setTimeout(function () {
      formRef.current.setData(datas[0]);
    }, 300);
  }

  return (
    <Container>
      <Form ref={formRef} autocomplete="false" onSubmit={handleSubmit}>
        <h1>Editar Vendedor</h1>
        <h2>Buscar Vendedor</h2>
        <select
          onChange={(e) => userInfo(e.currentTarget.value)}
          name="Pacient"
        >
          <option value={1}>Escolha o nome</option>
          {users.map(function (object) {
            return <option value={object.email}>{object.name}</option>;
          })}
        </select>

        <h2>E-mail</h2>
        <Input
          name="email"
          autocapitalize="off"
          readOnly
          placeholder="Seu endereço completo"
        />
        <h2>Nome</h2>
        <Input name="name" placeholder="Nome completo" />
        <h2>Senha</h2>
        <Input type="password" name="password" placeholder="Nova senha" />
        <h2>Cpf</h2>
        <InputMask
          name="cpf"
          placeholder="Digite o cpf"
          mask="999.999.999-99"
        />
        <h2>Rg</h2>
        <InputMask name="rg" placeholder="Digite o cpf" mask="99.999.999-99" />
        <h2>Telefone</h2>

        <Input name="phone" placeholder="Digite o telefone" maxlength="11" />
        <div style={{ marginTop: 30 }}>
          <h2 style={{ marginRight: -300 }}>Produtos</h2>
          <input
            style={{ marginRight: -232 }}
            type="checkbox"
            checked={products}
            onChange={() => setProducts(!products)}
          />
          {products ? (
            <div>
              <h2>Split de produto em %</h2>
              <Input style={{ marginLeft: 60 }} name="splitproduct" number />
            </div>
          ) : null}
        </div>
        <div>
          <h2 style={{ marginRight: -278 }}>Cartão</h2>
          <input
            style={{ marginRight: -232 }}
            type="checkbox"
            checked={card}
            onChange={() => setCard(!card)}
          />
          {card ? (
            <div>
              <h2>Split de cartão em %</h2>
              <Input style={{ marginLeft: 72 }} name="splitcard" number />
            </div>
          ) : null}
        </div>
        <div>
          <h2 style={{ marginRight: -349 }}>Agendamento</h2>
          <input
            style={{ marginRight: -232 }}
            type="checkbox"
            checked={appointments}
            onChange={() => setAppointments(!appointments)}
          />
          {appointments ? (
            <div>
              <h2>Split de agendamento em %</h2>
              <Input name="split" number />
            </div>
          ) : null}
        </div>
        <button type="submit">Atualizar perfil</button>
      </Form>
    </Container>
  );
}
