import React, { useState } from 'react';
import ceps from 'cep-promise';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Container,
  Content,
  InputBig,
  InputSmall,
  Button,
  RegisterButton,
} from './styles';
import api from '../../services/api';

import useForm from '../../utils/useForm';

export default function AddIssuer() {
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [issuer, setIssuer] = useState(false);
  const [provider, setProvider] = useState(false);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState(false);
  const [products, setProducts] = useState(false);
  const [appointments, setAppointments] = useState(false);
  const [cep, setCep] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [find, setFind] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const profile = useSelector((s) => s.user.profile);

  async function enviar() {
    if (100 - profile.split - values.split < 0) {
      toast.error(
        `valor permitido para agendamentos de até, ${100 - profile.split}`
      );
    } else if (100 - profile.splitcard - values.splitcard < 0) {
      toast.error(
        `valor permitido para cartão de até, ${100 - profile.splitcard}`
      );
    } else if (100 - profile.splitproduct - values.splitproduct < 0) {
      toast.error(
        `valor permitido para produtos de até, ${100 - profile.splitproduct}`
      );
    } else if (find === true) {
      if (
        values.name &&
        values.email &&
        values.password &&
        values.cnpj &&
        values.phone &&
        cep &&
        values.number &&
        neighborhood &&
        address &&
        city &&
        state !== ''
      ) {
        setLoading(true);
        const response = await api.post('usersIssuer', {
          email: values.email,
          name: values.name,
          split: values.split,
          provider,
          issuer,
          splitcard: values.splitcard,
          splitproduct: values.splitproduct,
          appointments,
          products,
          card,
          password: values.password,
          cnpj: values.cnpj,
          phone: values.phone,
        });
        if (response.status === 200) {
          const response2 = await api.post('address', {
            email: values.email,
            cep,
            number: Number(values.number),
            neighborhood,
            address,
            city,
            state,
          });
          if (response2.status === 200) {
            toast.success('Usuario criado com sucesso.');
            setLoading(false);
          } else if (response2.status === 203) {
            toast.error(
              'Erro ao fazer o cadastramento do usuario, verifque os dados ou você não possui permissão.'
            );
            setLoading(false);
          } else if (response.status === 203) {
            toast.error(
              'Erro ao fazer o cadastramento do usuario, verifque os dados ou você não possui permissão.'
            );
            setLoading(false);
          }
        } else if (response.status === 203) {
          setLoading(false);
          toast.error(
            'Erro ao fazer o cadastramento do usuario, verifque os dados ou você não possui permissão.'
          );
        }
      } else {
        toast.error('Digite todos os dados!');
      }
    } else {
      toast.error('Valide o cep!');
    }
  }

  function checkCep() {
    setLoading2(true);
    ceps(cep)
      .then((res) => {
        setLoading2(false);
        setFind(true);
        setCity(res.city);
        setAddress(res.street);
        setNeighborhood(res.neighborhood);
        setState(res.state);
      })
      .catch(() => {
        setLoading2(false);
        setFind(false);
        toast.error('Cep invalido!');
      });
  }

  return (
    <Container>
      <Content>
        <h1>Cadastrar Emintente ou prestador</h1>
        <form onSubmit={handleSubmit(enviar)}>
          <h2>Nome</h2>
          <InputBig onChange={handleChange} name="name" type="text" />
          <h2>E-mail</h2>
          <InputBig
            onChange={handleChange}
            name="email"
            autocapitalize="off"
            type="email"
          />

          <h2>Senha</h2>
          <InputBig onChange={handleChange} name="password" type="password" />
          <h2>CNPJ</h2>
          <InputBig
            onChange={handleChange}
            name="cnpj"
            mask="99.999.999/9999-99"
          />
          <h2>Telefone</h2>
          <div>
            <InputBig onChange={handleChange} name="phone" maxlength="11" />
          </div>
          <div style={{ marginLeft: 3 }}>
            <div>
              <h2>Emitente</h2>
              <InputSmall
                type="checkbox"
                value={issuer}
                onChange={() => setIssuer(!issuer)}
                name="seg"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -50 }}>Prestador</h2>
              <InputSmall
                type="checkbox"
                value={provider}
                onChange={() => setProvider(!provider)}
                name="ter"
              />
            </div>
          </div>

          {issuer ? (
            <>
              <div style={{ marginLeft: 3 }}>
                <div>
                  <h2>Agendamento</h2>
                  <InputSmall
                    style={{ marginLeft: -70 }}
                    type="checkbox"
                    value={appointments}
                    onChange={() => setAppointments(!appointments)}
                  />
                </div>
                {appointments ? (
                  <div>
                    <h2 style={{ marginLeft: -243 }}>
                      Split de agendamento em %
                    </h2>
                    <InputSmall onChange={handleChange} name="split" number />
                  </div>
                ) : null}
              </div>

              <div style={{ marginLeft: 3 }}>
                <div>
                  <h2>Produtos</h2>
                  <InputSmall
                    style={{ marginLeft: -70 }}
                    type="checkbox"
                    value={products}
                    onChange={() => setProducts(!products)}
                  />
                </div>
                {products ? (
                  <div>
                    <h2 style={{ marginLeft: -200 }}>Split de produtos em %</h2>
                    <InputSmall
                      onChange={handleChange}
                      name="splitproduct"
                      number
                    />
                  </div>
                ) : null}
              </div>
              <div style={{ marginLeft: 3 }}>
                <div>
                  <h2>Cartão</h2>
                  <InputSmall
                    style={{ marginLeft: -70 }}
                    type="checkbox"
                    value={card}
                    onChange={() => setCard(!card)}
                  />
                </div>
                {card ? (
                  <div>
                    <h2 style={{ marginLeft: -178 }}>Split de cartão em %</h2>
                    <InputSmall
                      onChange={handleChange}
                      name="splitcard"
                      number
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          <h2>CEP</h2>
          <div>
            <div>
              <InputBig
                onChange={(e) => setCep(e.target.value)}
                value={cep}
                name="cep"
                mask="99999-999"
              />
              {loading2 ? (
                <Button type="button">
                  <MDBIcon icon="compass" spin size="1x" fixed />
                  <span className="sr-only">Carregando...</span>
                </Button>
              ) : (
                <Button type="button" onClick={checkCep}>
                  <MDBIcon icon="compass" size="1x" fixed />
                </Button>
              )}
            </div>
            <h2>Número</h2>
            <div>
              <InputSmall onChange={handleChange} name="number" type="number" />
            </div>
          </div>
          <h2>Bairro</h2>
          <InputBig
            onChange={(e) => setNeighborhood(e.target.value)}
            value={neighborhood}
            name="neighborhood"
            type="text"
          />
          <h2>Rua</h2>
          <div>
            <InputBig
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              name="address"
              type="text"
            />
          </div>
          <h2>Cidade</h2>
          <div>
            <div>
              <InputBig
                onChange={(e) => setCity(e.target.value)}
                value={city}
                name="city"
                type="text"
              />
            </div>
            <h2>Estado</h2>
            <div>
              <InputSmall
                onChange={(e) => setState(e.target.value)}
                value={state}
                name="state"
                type="text"
                maxlength="2"
              />
            </div>
          </div>
          <RegisterButton type="submit">
            {loading ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Cadastrar'
            )}
          </RegisterButton>
        </form>
      </Content>
    </Container>
  );
}
