import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';
// import { Input } from '@rocketseat/unform';
import PerfectScrollbar from 'react-scrollbar';
import Input from 'react-input-mask';

const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;
const nono = keyframes`{
  0%,
  100% {
    transform: translateX(0);
  }
  35% {
    transform: translateX(-15%);
  }
  70% {
    transform: translateX(15%);
  }
}`;
export const Container = styled.div`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  @media (min-width: 1000px) {
    height: 100%;
  }
`;

export const InputBig = styled(Input)`
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #0b7269;
  border-radius: 4px;
  height: 28px;
  width: 70%;
  color: #0b7269;
  margin: 0 0 10px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  &::placeholder {
    color: rgba(255, 255, 255, 1);
  }
`;

export const InputSmall = styled(Input)`
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #0b7269;
  border-radius: 4px;
  height: 28px;
  width: 36.5%;
  color: #0b7269;
  margin: 0 0 10px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  &::placeholder {
    color: rgba(255, 255, 255, 1);
  }
`;

export const Button = styled.button`
  padding: 10px;
  margin-left: 10px;
  width: 10%;
  height: 28px;
  background: #0b7269;
  color: #fff;
  border: 0;
  align-items: center;
  border-radius: 5px;
  font-size: 17px;
  transition: background 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: ${darken(0.1, '#0b7269')};
  }
`;

export const RegisterButton = styled.button`
  margin: 20px 0px;
  padding: 10px;
  width: 70%;
  height: 50;
  background: #0b7269;
  color: #fff;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  transition: background 0.2s;
  align-self: flex-start;
  font: 15px 'Bebas Neue', sans-serif;
  &:hover {
    background: ${darken(0.1, '#0b7269')};
  }
`;

export const Content = styled(PerfectScrollbar)`
  margin-top: 3%;
  padding-left: 15%;
  padding-right: 5%;
  margin-bottom: 3%;
  width: 100%;
  max-width: 80%;
  align-self: center;
  animation: ${fade} 1s;
  h1 {
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }
  h2 {
    font: 12px 'Bebas Neue', sans-serif;
    color: #0b7269;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    h2 {
      font: 20px 'Bebas Neue', sans-serif;
      color: #0b7269;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    span {
      animation: ${nono} 200ms linear, fade paused;
      animation-iteration-count: 2;
      color: #f44335;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    div {
      display: flex;
      margin-top: 5px;
      flex-direction: row;
      a {
        margin: 5px 30px;
        padding: 5px;
        width: 30%;
        height: 39;
        color: #0b7269;
        font-size: 14px;
        border: 1px solid #0b7269;
        border-radius: 5px;
        font-size: 14px;
        transition: background 0.2s;
        justify-content: center;
        align-items: center;
        font: 15px 'Bebas Neue', sans-serif;
        &:hover {
          background: ${darken(0.1, '#fff')};
          color: #000;
        }
      }
    }
  }
`;
