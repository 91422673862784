/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import { Container, Content, RegisterButton } from './styles';
import api from '../../services/api';

export default function UploadCards() {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [preview, setPreview] = useState();

  async function handleUpload(e) {
    setLoading(true);
    const data = new FormData();
    data.append('file', e.target.files[0]);
    const response = await api.post('files', data);
    const { id, url } = response.data;
    setFile(id);
    setPreview(url);
    setLoading(false);
  }
  async function deleteImage() {
    setLoading(true);
    const response = await api.delete(`files/${file}`);
    if (response.status === 200) {
      setFile('');
      setPreview('');
      setLoading(false);
      toast.success('Imagem deletada.');
      setLoading(false);
    } else if (response.status === 203) {
      setLoading(false);
      toast.error('Erro ao deletar imagem.');
    }
  }

  return (
    <Container>
      <Content>
        <h1>Upload de imagem para cartão</h1>
        <form>
          <h2>Imagem do cartão</h2>
          {loading ? (
            <>
              <MDBIcon icon="spinner" spin size="2x" fixed />
              <span className="sr-only">Carregando...</span>
            </>
          ) : !preview ? null : (
            <>
              <img src={preview} alt="" />
              <RegisterButton type="submit" onClick={deleteImage}>
                {loading ? (
                  <>
                    <MDBIcon icon="spinner" spin size="2x" fixed />
                    <span className="sr-only">Carregando...</span>
                  </>
                ) : (
                  'Deletar'
                )}
              </RegisterButton>
            </>
          )}

          <input
            style={{ marginTop: 20 }}
            type="file"
            id="avatar"
            accept="image/*"
            data-file={file}
            onChange={handleUpload}
          />
        </form>
      </Content>
    </Container>
  );
}
