import React, { useState, useEffect } from 'react';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import { Container, Content, InputBig, RegisterButton } from './styles';
import useForm from '../../utils/useForm';
import api from '../../services/api';

export default function AddCard() {
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [loading2, setLoading] = useState(false);
  const [allIssuer, setAllIssuer] = useState([]);
  const [issuer, setIssuer] = useState([]);
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [loadingFiles, setLoadingFiles] = useState([]);
  useEffect(() => {
    async function loadIssuers() {
      const response = await api.get('usersIssuerCreated');
      setAllIssuer(response.data);
    }

    async function loadFiles() {
      const response = await api.get('files');
      setLoadingFiles(response.data);
    }

    loadIssuers();
    loadFiles();
  }, []);
  async function enviarContato() {
    if (values.price && values.due_date !== '') {
      setLoading(true);
      const response = await api.post('card', {
        id: Number(issuer),
        price: Number(values.price),
        due_date: Number(values.due_date),
        avatar_id: Number(file),
      });
      if (response.status === 200) {
        toast.success('Cartão criado com sucesso.');
        setLoading(false);
      } else if (response.status === 203) {
        setLoading(false);
        toast.error(
          'Erro ao criar cartão, verifque os dados ou você não possui permissão.'
        );
      }
    } else {
      toast.error('Todos os campos devem estar preenchidos!');
    }
  }
  function handleChangeIssuer(e) {
    setIssuer(e);
  }
  function handleChangePreview(e) {
    setFile(e);
    const datas = loadingFiles.filter((info) => {
      return info.id === Number(e);
    });
    setPreview(datas[0].url);
  }
  // async function handleUpload(e) {
  //   const data = new FormData();
  //   data.append('file', e.target.files[0]);
  //   const response = await api.post('files', data);
  //   const { id, url } = response.data;
  //   setFile(id);
  //   setPreview(url);
  // }
  return (
    <Container>
      <Content>
        <h1>Cadastrar cartão</h1>
        <form onSubmit={handleSubmit(enviarContato)}>
          <h2>Emitente ou Prestador</h2>
          <select
            style={{ width: '70%' }}
            onChange={(e) => handleChangeIssuer(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o prestador ou emitente</option>
            {allIssuer.map(function (object) {
              return <option value={object.id}>{object.name}</option>;
            })}
          </select>

          <h2>Preço do cartão</h2>
          <InputBig onChange={handleChange} name="price" type="number" />
          <h2>Validade do cartão em dias</h2>
          <InputBig onChange={handleChange} name="due_date" type="number" />
          <h2>Imagem do cartão</h2>
          <select
            style={{ width: '70%' }}
            onChange={(e) => handleChangePreview(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha a imagem do cartão</option>
            {loadingFiles.map(function (object) {
              return <option value={object.id}>{object.name}</option>;
            })}
          </select>
          {!preview ? null : <img src={preview} alt="" />}

          <RegisterButton type="submit">
            {loading2 ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Cadastrar'
            )}
          </RegisterButton>
        </form>
      </Content>
    </Container>
  );
}
