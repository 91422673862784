import React, { useState, useEffect } from 'react';
// import cep from 'cep-promise';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { Container, Content, InputBig, RegisterButton } from './styles';
import api from '../../services/api';

import useForm from '../../utils/useForm';

export default function AddProducts() {
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [loading2, setLoading] = useState(false);
  const [general, setGeneral] = useState([]);
  const [allGeneral, setAllGeneral] = useState([]);

  const [sub, setSub] = useState([]);
  const [group, setGroup] = useState([]);
  const [procedure, setProcedure] = useState([]);
  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    async function loadGeneral() {
      const response = await api.get('generalProducts');

      const servicelocal = response.data.map(function (item) {
        return item.group;
      });
      const result = servicelocal.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
      setGeneral(result);

      setAllGeneral(response.data);
    }

    loadGeneral();
  }, []);
  async function enviar() {
    if (procedure && values.price && values.split !== '') {
      setLoading(true);

      const response = await api.post('services', {
        id: profile.id,
        code: Number(procedure[0].code),
        group: procedure[0].group,
        subgroup: procedure[0].subgroup,
        procedure: procedure[0].procedure,
        name: false,
        crm: false,
        product: true,
        evening: false,
        morning: false,
        seg: false,
        ter: false,
        quar: false,
        quin: false,
        sex: false,
        sab: false,
        dom: false,
        pricecard: 0,
        price: values.price,
        split: values.split,
      });
      setLoading(true);

      if (response.status === 200) {
        toast.success('Produto criado com sucesso.');
        setLoading(false);
      } else if (response.status === 203) {
        setLoading(false);
        toast.error(
          'Erro ao criar o produto, verifque os dados ou você não possui permissão.'
        );
      }
    } else {
      toast.error('Todos os campos devem estar preenchidos!');
    }
  }

  function handleChangeGeneral(e) {
    const datas = allGeneral.filter((info) => {
      return info.procedure === e;
    });
    setProcedure(datas);
  }
  function handleChangeSubGroup(e) {
    const datas = allGeneral.filter((info) => {
      return info.subgroup === e;
    });
    const servicelocal = datas.map(function (item) {
      return item.procedure;
    });
    const result = servicelocal.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setSub(result);
  }

  function handleChangeGroup(e) {
    const datas = allGeneral.filter((info) => {
      return info.group === e;
    });
    const servicelocal = datas.map(function (item) {
      return item.subgroup;
    });
    const result = servicelocal.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setGroup(result);
  }
  function handleChangeCode(e) {
    const datas = allGeneral.filter((info) => {
      return info.code === Number(e);
    });
    setProcedure(datas);
  }
  return (
    <Container>
      <Content horizontal={false}>
        <h1>Liberar produto</h1>

        <form onSubmit={handleSubmit(enviar)}>
          <h2>Grupo</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeGroup(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o grupo</option>
            {general.map(function (object) {
              return <option value={object}>{object}</option>;
            })}
          </select>
          <h2>Sub-Grupo</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeSubGroup(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o sub-grupo</option>
            {group.map(function (object) {
              return <option value={object}>{object}</option>;
            })}
          </select>
          <h2>Procedimento</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeGeneral(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o procedimento</option>
            {sub.map(function (object) {
              return <option value={object}>{object}</option>;
            })}
          </select>

          <h2>Código</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeCode(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o código</option>
            {procedure.map(function (object) {
              return <option value={object.code}>{object.code}</option>;
            })}
          </select>
          <h2>Preço</h2>
          <InputBig onChange={handleChange} name="price" type="number" />
          <h2>Split em %</h2>
          <InputBig onChange={handleChange} name="split" type="text" />

          <RegisterButton type="submit">
            {loading2 ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Cadastrar'
            )}
          </RegisterButton>
        </form>
      </Content>
    </Container>
  );
}
