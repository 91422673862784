/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete } from 'react-icons/md';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from 'mdbreact';
import { toast } from 'react-toastify';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { subHours } from 'date-fns';
import api from '../../services/api';

import { formatPrice } from '../../utils/format';
import { removeFromCart } from '../../store/modules/cart/actions';
import { Container, ProductTable, Total } from './styles';

export default function Cart() {
  const [modal, setModal] = useState(false);
  const [date, setDate] = useState([]);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    async function getUsers() {
      const response = await api.get('users');
      setUsers(response.data);
    }

    getUsers();
  }, []);
  async function changeModal() {
    setModal(!modal);
  }
  const total = useSelector((state) =>
    formatPrice(
      state.cart.reduce((sumTotal, product) => sumTotal + product.price, 0)
    )
  );
  const tota2 = useSelector((state) =>
    formatPrice(
      state.cart.reduce((sumTotal, product) => sumTotal + product.pricecard, 0)
    )
  );
  function handleChangeDate(e, id) {
    const array = [...date];
    array[id] = e;
    setDate(array);
  }

  const cart = useSelector((state) =>
    state.cart.map((product) => ({
      ...product,
      pricenocard: formatPrice(product.price),
      pricewithcard: formatPrice(product.pricecard),
    }))
  );

  function handleChangeEmail(e) {
    setEmail(e);
  }
  const dispatch = useDispatch();
  async function handleUpload() {
    if (date.length === cart.length) {
      if (cart !== '') {
        setLoading(true);
        for (let i = 0; i < date.length; i += 1) {
          const response = await api.post('appointments', {
            email,
            date: subHours(new Date(date[i]), 3),
            service_id: cart[i].id,
          });

          if (response.status === 200) {
            toast.success('Agendamento feito com sucesso.');
            setLoading(false);
            dispatch(removeFromCart(cart[i].id));
          } else if (response.status === 203) {
            toast.error(
              'Erro ao fazer o agendamento, verifque se o usuario possui um cartão valido'
            );
            setLoading(false);
          }
        }
        changeModal();
      } else {
        toast.error('Preeencha todos os campos.');
      }
    } else {
      toast.error('Preeencha o campo da data.');
    }
  }

  return (
    <Container>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <h1>Carrinho</h1>
        <MDBModal isOpen={modal} toggle={changeModal}>
          <MDBModalHeader toggle={changeModal}>
            E-mail do paciente
          </MDBModalHeader>
          <MDBModalBody>
            <div>
              <h2>Paciente</h2>
              <select
                onChange={(e) => handleChangeEmail(e.currentTarget.value)}
                className="browser-default custom-select"
                name="Pacient"
              >
                <option value={1}>Escolha o nome do cliente</option>
                {users.map(function (object) {
                  return <option value={object.email}>{object.name}</option>;
                })}
              </select>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={() => handleUpload()}>
              {loading ? (
                <>
                  <MDBIcon icon="spinner" spin size="2x" fixed />
                  <span className="sr-only">Carregando...</span>
                </>
              ) : (
                'Enviar'
              )}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <ProductTable>
          <table>
            <thead>
              <tr>
                <th>Código</th>
                <th>Procedimento</th>
                <th>Preço com cartão</th>
                <th>Preço sem cartão</th>
                <th>Remover</th>
                <th>Data</th>
              </tr>
            </thead>

            <tbody>
              {cart.map((product, key) => (
                <tr>
                  <td>
                    <span>{product.code}</span>
                  </td>
                  <td>
                    <span>{product.procedure}</span>
                  </td>
                  <td>
                    <strong>{product.pricewithcard}</strong>
                  </td>
                  <td>
                    <strong>{product.pricenocard}</strong>
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => dispatch(removeFromCart(product.id))}
                    >
                      <MdDelete size={20} color="#0b7269" />
                    </button>
                  </td>
                  <td style={{ width: 100 }}>
                    <DateTimePicker
                      format="DD/MM"
                      value={date[key]}
                      style={{
                        fontWeight: 'bold',
                        color: '#000',
                      }}
                      ampm={false}
                      onChange={(e) => handleChangeDate(e, key)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ProductTable>

        <footer>
          <button type="button" onClick={() => changeModal()}>
            Finalizar pedido
          </button>
          <Total>
            <span>Total com cartão</span>
            <strong>{tota2}</strong>
          </Total>
          <Total>
            <span>Total sem cartão</span>
            <strong>{total}</strong>
          </Total>
        </footer>
      </MuiPickersUtilsProvider>
    </Container>
  );
}
