import React from 'react';
import { Link } from 'react-router-dom';
import { MdShoppingBasket } from 'react-icons/md';
import { Container, Cart } from './styles';

export default function Header({ cartSize }) {
  return (
    <Container>
      <Link to="/" />

      <Cart to="/cartProduct">
        <div className="cart-box">
          <strong>Meu carrinho</strong>
          <span>{cartSize} itens</span>
        </div>
        <MdShoppingBasket size={45} color="#4285F4" />
      </Cart>
    </Container>
  );
}
