import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';
import Input from 'react-input-mask';
import PerfectScrollbar from 'react-scrollbar';

const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;
const nono = keyframes`{
  0%,
  100% {
    transform: translateX(0);
  }
  35% {
    transform: translateX(-15%);
  }
  70% {
    transform: translateX(15%);
  }
}`;
export const InputSmall = styled(Input)`
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #0b7269;
  border-radius: 4px;
  height: 25px;
  width: 25px;
  color: #0b7269;
  margin: 0 0 10px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  &::placeholder {
    color: rgba(255, 255, 255, 1);
  }
`;
export const Container = styled.div`
  max-width: 80%;
  margin-left: 20%;
  margin-right: 5%;
  animation: ${fade} 1s;
  h1 {
    margin-top: 2%;
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }

  h2 {
    font: 18px 'Bebas Neue', sans-serif;
    color: #0b7269;
    margin-bottom: 5px;
    margin-top: 5px;
  }
`;

export const InputBig = styled(Input)`
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #0b7269;
  border-radius: 4px;
  height: 28px;
  width: 100%;
  color: #0b7269;
  margin: 0 0 10px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  &::placeholder {
    color: rgba(255, 255, 255, 1);
  }
`;

export const Button = styled.button`
  margin: 3px 0 0;
  height: 25px;
  width: 100%;
  background: #4285f4;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.03, '#4285F4')};
  }
`;
export const Content = styled(PerfectScrollbar)`
  padding-top: 3%;
  margin-bottom: 2%;
  animation: ${fade} 1s;

  select {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #0b7269;
    border-radius: 4px;
    height: 28px;
    width: 50%;
    color: #0b7269;
    margin: 0 0 10px;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    &::placeholder {
      color: #0b7269;
    }
  }
  h1 {
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }
  h2 {
    font: 12px 'Bebas Neue', sans-serif;
    color: #0b7269;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  div {
    display: flex;
    flex-direction: column;
    h2 {
      font: 20px 'Bebas Neue', sans-serif;
      color: #0b7269;
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    span {
      animation: ${nono} 200ms linear, fade paused;
      animation-iteration-count: 2;
      color: #f44335;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    div {
      display: flex;
      margin-top: 5px;
      flex-direction: row;
      justify-content: space-between;
    }
  }
`;
