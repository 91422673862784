import React from 'react';
import { MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCol } from 'mdbreact';
import { useSelector } from 'react-redux';

export default function AdminCardSection1({ info1, info2, info3, info4 }) {
  const profile = useSelector((state) => state.user.profile);
  return (
    <MDBRow className="mb-4">
      {profile.admin ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color" />
              <div className="data">
                <strong>Rendimento</strong>
                <h4>
                  <strong>{info1}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Redimento total da plataforma</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.admin ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="user" className="primary-color" />
              <div className="data">
                <strong>Franqueados</strong>
                <h4>
                  <strong>{info2}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº total de Franqueados</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.admin ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="user" className="primary-color" />
              <div className="data">
                <strong>Emitentes</strong>
                <h4>
                  <strong>{info3}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº total de Emitentes</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.admin ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="plus" className="primary-color" />
              <div className="data">
                <strong>Nº procedimentos</strong>
                <h4>
                  <strong>{info4}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº procedimentos na plataforma</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.franchisee ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color" />
              <div className="data">
                <strong>Rendimento</strong>
                <h4>
                  <strong>{info1}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Redimento total da plataforma</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.franchisee ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="user" className="primary-color" />
              <div className="data">
                <strong>Emitentes</strong>
                <h4>
                  <strong>{info2}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº total de Emitentes</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.franchisee ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="user" className="primary-color" />
              <div className="data">
                <strong>Nº de vendedores</strong>
                <h4>
                  <strong>{info3}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº de vendedores ativos</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.franchisee ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="plus" className="primary-color" />
              <div className="data">
                <strong>Nº procedimentos</strong>
                <h4>
                  <strong>{info4}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº procedimentos na plataforma</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.issuer || profile.provider ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color" />
              <div className="data">
                <strong>Rendimento</strong>
                <h4>
                  <strong>{info1}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Redimento total da plataforma</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.issuer || profile.provider ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="address-card" className="primary-color" />
              <div className="data">
                <strong>Nº de cartões</strong>
                <h4>
                  <strong>{info2}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº total de cartões</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.issuer || profile.provider ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="user" className="primary-color" />
              <div className="data">
                <strong>Nº de vendedores</strong>
                <h4>
                  <strong>{info3}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº de vendedores ativos</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.issuer || profile.provider ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="plus" className="primary-color" />
              <div className="data">
                <strong>Nº procedimentos</strong>
                <h4>
                  <strong>{info4}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº procedimentos na plataforma</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.seller ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color" />
              <div className="data">
                <strong>Rendimento</strong>
                <h4>
                  <strong>{info1}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Redimento total da plataforma</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.seller ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="address-card" className="primary-color" />
              <div className="data">
                <strong>Nº de cartões</strong>
                <h4>
                  <strong>{info2}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº total de cartões</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.seller ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="user" className="primary-color" />
              <div className="data">
                <strong>Nº de clientes</strong>
                <h4>
                  <strong>{info3}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº de clientes cadastrados</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.seller ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="plus" className="primary-color" />
              <div className="data">
                <strong>Nº procedimentos</strong>
                <h4>
                  <strong>{info4}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Nº procedimentos na plataforma</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
    </MDBRow>
  );
}
