import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Container } from './styles';
import api from '../../services/api';

function ShowCards() {
  const [card, setCard] = useState([]);

  useEffect(() => {
    async function loadCards() {
      const response = await api.get('card');
      setCard({
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 100,
          },

          {
            label: 'Validade',
            field: 'due_date',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Preço',
            field: 'price',
            sort: 'asc',
            width: 100,
          },
        ],
        rows: response.data.map((events) => ({
          ...events,
        })),
      });
    }

    loadCards();
  }, []);

  return (
    <Container>
      <h1>Listar de cartões</h1>
      <MDBDataTable
        striped
        responsive
        entries={10}
        maxHeight={500}
        scrollX
        data={card}
      />
    </Container>
  );
}

export default ShowCards;
