import React, { useEffect, useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch } from 'react-redux';
import { Container } from './styles';
import { updateProfileRequest } from '../../store/modules/user/actions';
import api from '../../services/api';

export default function Profile() {
  const [users, setUsers] = useState([]);
  const [individual, setIndividual] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    async function getUsers() {
      const response = await api.get('users');
      setUsers(response.data);
    }
    getUsers();
  }, []);
  function handleSubmit(datas) {
    const data = [];

    data[0] = datas;
    dispatch(updateProfileRequest(data));
  }
  async function userInfo(email) {
    const datas = await users.filter((info) => {
      return info.email === email;
    });
    setIndividual(datas);
  }

  return (
    <Container>
      <Form
        initialData={individual[0]}
        autocomplete="false"
        onSubmit={handleSubmit}
      >
        <h1>Editar Perfil</h1>
        <h2>Buscar Cliente</h2>
        <select
          onChange={(e) => userInfo(e.currentTarget.value)}
          name="Pacient"
        >
          <option value={1}>Escolha o nome</option>
          {users.map(function (object) {
            return <option value={object.email}>{object.name}</option>;
          })}
        </select>

        <h2>E-mail</h2>
        <Input
          name="email"
          readOnly
          autocapitalize="off"
          placeholder="Seu endereço completo"
        />
        <h2>Nome</h2>
        <Input name="name" readOnly placeholder="Nome completo" />
        <h2>Senha</h2>
        <Input type="password" name="password" placeholder="Nova senha" />
        <h2>Telefone</h2>
        <Input
          name="phone"
          readOnly
          placeholder="Digite o telefone"
          maxlength="11"
        />
        <button type="submit">Atualizar perfil</button>
      </Form>
    </Container>
  );
}
