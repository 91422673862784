import React, { useState, useEffect } from 'react';

import { MDBDataTable } from 'mdbreact';
import { MdShoppingBasket } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Header from '../../components/header';
import { addToCartRequest } from '../../store/modules/cart/actions';

import api from '../../services/api';
import { Container, ContainerSchedule, Button } from './styles';

export default function AddClient() {
  const dispatch = useDispatch();

  const [issuer, setIssuer] = useState([]);

  const [city, setCity] = useState([]);
  const [cityLocal, setCityLocal] = useState([]);
  const [services, setServices] = useState([]);
  const [allservices, setAllServices] = useState([]);

  const [sub, setSub] = useState([]);
  const [group, setGroup] = useState([]);
  const [code, setCode] = useState([]);

  const [change, setChangeState] = useState(true);

  const [card, setCard] = useState([]);
  const cart = useSelector((state) => state.cart.length);

  useEffect(() => {
    async function getCity() {
      const response = await api.get('addressSellerController');
      const datas = await response.data.filter((info) => {
        return info.user.issuer || info.user.provider === true;
      });
      const citylocal = datas.map(function (item) {
        return item.city;
      });
      const result = citylocal.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
      setCity(result);
    }

    async function getServices() {
      const response = await api.get('allServices');
      const datas = await response.data.filter((info) => {
        return info.deactivate === false;
      });
      const datas2 = datas.filter((info) => {
        return info.product === false;
      });
      setAllServices(datas2);
      const servicelocal = datas2.map(function (item) {
        return item.group;
      });
      const result = servicelocal.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
      setServices(result);
    }
    getCity();
    getServices();
  }, [cityLocal, change, group, sub]);
  function handleChangeCity(e) {
    setCityLocal(e);
  }
  function handleAddProduct(id) {
    dispatch(addToCartRequest(id));
  }

  function handleChangeProcedure(e) {
    const datas = allservices.filter((info) => {
      return info.procedure === e;
    });
    const datas2 = datas.filter((info) => {
      return info.product === false;
    });
    const emitentes = datas2.filter((info) => {
      return info.created.issuer || info.created.provider === true;
    });
    const servicelocal = emitentes.map(function (item) {
      return item;
    });
    const result = servicelocal.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    const a = result.sort(function (c, b) {
      return (c.price * c.split) / 100 - (b.price * b.split) / 100;
    });

    setIssuer(a);
    setCode(datas[0].code);
  }
  function handleChangeSub(e) {
    const datas = allservices.filter((info) => {
      return info.subgroup === e;
    });
    const servicelocal = datas.map(function (item) {
      return { name: item.procedure, id: item.id };
    });
    const result = servicelocal.reduce(
      (unique, item) =>
        unique.includes(item.name) ? unique : [...unique, item.name],
      []
    );

    setSub(result);
  }
  function handleChangeGroup(e) {
    const datas = allservices.filter((info) => {
      return info.group === e;
    });
    const servicelocal = datas.map(function (item) {
      return item.subgroup;
    });
    const result = servicelocal.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setGroup(result);
  }

  async function handleChangeServiceCode(e) {
    setChangeState(false);
    const response = await api.post('localServices', {
      code: Number(code),
      created_id: Number(e),
    });
    setCard({
      columns: [
        {
          label: 'Código',
          field: 'code',
          sort: 'asc',
          width: 10,
        },
        {
          label: 'Segunda',
          field: 'seg',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Terça',
          field: 'ter',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Quarta',
          field: 'quar',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Quinta',
          field: 'quin',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Sexta',
          field: 'sex',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Sab',
          field: 'sab',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Dom',
          field: 'dom',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Carrinho',
          field: 'action',
          sort: 'asc',
          width: 100,
        },
      ],
      rows: response.data.reverse().map((events) => ({
        ...events,
        seg: (
          <>
            {events.seg ? (
              <ContainerSchedule>
                <h1>P.C.:{events.pricecard}</h1>
                <h1>P.S.C.:{events.price}</h1>
                <h1>Dr:{events.name}</h1>
                {events.morning ? (
                  <ContainerSchedule>
                    <h1>Manhã</h1>
                  </ContainerSchedule>
                ) : null}
                {events.evening ? <h1>A tarde</h1> : null}
              </ContainerSchedule>
            ) : (
              <ContainerSchedule>
                <h1>Não tem vaga</h1>
              </ContainerSchedule>
            )}
          </>
        ),
        ter: (
          <>
            {events.ter ? (
              <ContainerSchedule>
                <h1>P.C.:{events.pricecard}</h1>
                <h1>P.S.C.:{events.price}</h1>
                <h1>Dr:{events.name}</h1>
                {events.morning ? (
                  <ContainerSchedule>
                    <h1>Manhã</h1>
                  </ContainerSchedule>
                ) : null}
                {events.evening ? <h1>A tarde</h1> : null}
              </ContainerSchedule>
            ) : (
              <ContainerSchedule>
                <h1>Não tem vaga</h1>
              </ContainerSchedule>
            )}
          </>
        ),
        quar: (
          <>
            {events.quar ? (
              <ContainerSchedule>
                <h1>P.C.:{events.pricecard}</h1>
                <h1>P.S.C.:{events.price}</h1>
                <h1>Dr:{events.name}</h1>
                {events.morning ? (
                  <ContainerSchedule>
                    <h1>Manhã</h1>
                  </ContainerSchedule>
                ) : null}
                {events.evening ? <h1>A tarde</h1> : null}
              </ContainerSchedule>
            ) : (
              <ContainerSchedule>
                <h1>Não tem vaga</h1>
              </ContainerSchedule>
            )}
          </>
        ),
        quin: (
          <>
            {events.quin ? (
              <ContainerSchedule>
                <h1>P.C.:{events.pricecard}</h1>
                <h1>P.S.C.:{events.price}</h1>
                <h1>Dr:{events.name}</h1>
                {events.morning ? (
                  <ContainerSchedule>
                    <h1>Manhã</h1>
                  </ContainerSchedule>
                ) : null}
                {events.evening ? <h1>A tarde</h1> : null}
              </ContainerSchedule>
            ) : (
              <ContainerSchedule>
                <h1>Não tem vaga</h1>
              </ContainerSchedule>
            )}
          </>
        ),
        sex: (
          <>
            {events.sex ? (
              <ContainerSchedule>
                <h1>P.C.:{events.pricecard}</h1>
                <h1>P.S.C.:{events.price}</h1>
                <h1>Dr:{events.name}</h1>
                {events.morning ? (
                  <ContainerSchedule>
                    <h1>Manhã</h1>
                  </ContainerSchedule>
                ) : null}
                {events.evening ? <h1>A tarde</h1> : null}
              </ContainerSchedule>
            ) : (
              <ContainerSchedule>
                <h1>Não tem vaga</h1>
              </ContainerSchedule>
            )}
          </>
        ),
        sab: (
          <>
            {events.sab ? (
              <ContainerSchedule>
                <h1>P.C.:{events.pricecard}</h1>
                <h1>P.S.C.:{events.price}</h1>
                <h1>Dr:{events.name}</h1>
                {events.morning ? (
                  <ContainerSchedule>
                    <h1>Manhã</h1>
                  </ContainerSchedule>
                ) : null}
                {events.evening ? <h1>A tarde</h1> : null}
              </ContainerSchedule>
            ) : (
              <ContainerSchedule>
                <h1>Não tem vaga</h1>
              </ContainerSchedule>
            )}
          </>
        ),
        dom: (
          <>
            {events.dom ? (
              <ContainerSchedule>
                <h1>P.C.:{events.pricecard}</h1>
                <h1>P.S.C.:{events.price}</h1>
                <h1>Dr:{events.name}</h1>
                {events.morning ? (
                  <ContainerSchedule>
                    <h1>Manhã</h1>
                  </ContainerSchedule>
                ) : null}
                {events.evening ? <h1>A tarde</h1> : null}
              </ContainerSchedule>
            ) : (
              <ContainerSchedule>
                <h1>Não tem vaga</h1>
              </ContainerSchedule>
            )}
          </>
        ),
        action: (
          <button
            type="button"
            style={{ background: 'transparent', borderWidth: 0 }}
          >
            <MdShoppingBasket
              size={30}
              color="#4285F4"
              onClick={() => handleAddProduct(events.id)}
            />
          </button>
        ),
      })),
    });
  }

  return (
    <Container>
      <Header cartSize={cart} />

      <form style={{ alignSelf: 'center', margin: 50 }}>
        <h1>Agendar Procedimento</h1>

        {change === true ? (
          <>
            <div>
              <h2>Cidade</h2>
              <select
                onChange={(e) => handleChangeCity(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha a cidade</option>
                {city.map(function (object) {
                  return <option value={object}>{object}</option>;
                })}
              </select>
            </div>

            <div>
              <h2>Grupo</h2>
              <select
                onChange={(e) => handleChangeGroup(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha o grupo</option>
                {services.map(function (object) {
                  return <option value={object}>{object}</option>;
                })}
              </select>
              <h2>Sub-Grupo</h2>
              <select
                onChange={(e) => handleChangeSub(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha o sub-grupo</option>
                {group.map(function (object) {
                  return <option value={object}>{object}</option>;
                })}
              </select>
              <h2>Procedimento</h2>
              <select
                onChange={(e) => handleChangeProcedure(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha o procedimento</option>
                {sub.map(function (object) {
                  return <option value={object}>{object}</option>;
                })}
              </select>
              <div>
                <h2>Local</h2>
                <select
                  onChange={(e) =>
                    handleChangeServiceCode(e.currentTarget.value)
                  }
                  name="Pacient"
                >
                  <option value={1}>Escolha o local</option>
                  {issuer.map(function (object) {
                    return (
                      <option value={object.created.id}>
                        {object.created.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        ) : (
          <>
            <MDBDataTable
              striped
              responsive
              entries={10}
              maxHeight={500}
              scrollX
              data={card}
            />
            <Button type="button" onClick={() => setChangeState(true)}>
              Voltar
            </Button>
          </>
        )}
      </form>
    </Container>
  );
}
