/* eslint-disable prefer-spread */
import React, { useEffect, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';

import { Container } from './styles';
import api from '../../services/api';

export default function ReceptionistUpdate() {
  const [users, setUsers] = useState([]);

  const profile = useSelector((s) => s.user.profile);

  const formRef = useRef(null);
  useEffect(() => {
    async function getUsers() {
      const response = await api.get('usersRecepcionist');
      let datas = [];
      if (profile.franchisee) {
        datas = response.data.filter((info) => {
          return info.created.created.id === profile.id;
        });
      } else {
        datas = response.data.filter((info) => {
          return info.created.id === profile.id;
        });
      }

      setUsers(datas);
    }

    getUsers();
  }, []);

  async function handleSubmit(datas) {
    const response = await api.put('userSeller', {
      email: datas.email,
      name: datas.name,
      password: datas.password,
      rg: datas.rg,
      cpf: datas.cpf,
    });
    if (response.status === 200) {
      toast.success('Perfil atualizado com sucesso.');
    } else {
      toast.success('Erro ao atualizar perfil.');
    }
  }
  async function userInfo(email) {
    const datas = await users.filter((info) => {
      return info.email === email;
    });
    setTimeout(function () {
      formRef.current.setData(datas[0]);
    }, 300);
  }

  return (
    <Container>
      <Form ref={formRef} autocomplete="false" onSubmit={handleSubmit}>
        <h1>Editar Recepcionista</h1>
        <h2>Buscar Recepcionista</h2>
        <select
          onChange={(e) => userInfo(e.currentTarget.value)}
          name="Pacient"
        >
          <option value={1}>Escolha o nome</option>
          {users.map(function (object) {
            return <option value={object.email}>{object.name}</option>;
          })}
        </select>

        <h2>E-mail</h2>
        <Input
          name="email"
          readOnly
          autocapitalize="off"
          placeholder="Seu endereço completo"
        />
        <h2>Nome</h2>
        <Input name="name" placeholder="Nome completo" />
        <h2>Senha</h2>
        <Input type="password" name="password" placeholder="Nova senha" />
        <h2>Cpf</h2>
        <InputMask
          name="cpf"
          placeholder="Digite o cpf"
          mask="999.999.999-99"
        />
        <h2>Rg</h2>
        <InputMask name="rg" placeholder="Digite o cpf" mask="99.999.999-99" />
        <h2>Telefone</h2>
        <Input name="phone" placeholder="Digite o telefone" maxlength="11" />
        <button type="submit">Atualizar perfil</button>
      </Form>
    </Container>
  );
}
