import { put, all, takeLatest } from 'redux-saga/effects';
import history from '../../../services/history';

import { addToCartSuccess } from './actions';

function* addToCart({ datas }) {
  const data = {
    data: datas.datas4,
    dataCard: datas.datas5,
    id: datas.datas4[0].user.id,
  };
  yield put(addToCartSuccess(data));
  history.push('/paymantCard');
}

export default all([takeLatest('@cartpayment/ADD_REQUEST', addToCart)]);
