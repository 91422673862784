import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import api from '../../../services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;

    const response = yield call(api.post, 'sessions', {
      email,
      password,
    });

    const { token, user } = response.data;
    if (user.seller && !user.deactivate) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, user));
      history.push('/profile');
    } else if (user.admin && !user.deactivate) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, user));
      history.push('/profile');
    } else if (user.franchisee && !user.deactivate) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, user));
      history.push('/profile');
    } else if (user.issuer && !user.deactivate) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, user));
      history.push('/profile');
    } else if (user.provider && !user.deactivate) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, user));
      history.push('/profile');
    } else if (user.receptionist && !user.deactivate) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, user));
      history.push('/profile');
    } else {
      toast.error(
        'Usuário não é vendedor nem administrador ou não esta mais liberado para uso da plataforma!'
      );
      yield put(signFailure());
    }
  } catch (err) {
    toast.error('Falha na autenticação, verifique seus dados!');
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const {
      name,
      email,
      password,
      rg,
      cpf,
      phone,
      cep,
      born,
      number,
      neighborhood,
      address,
      city,
      state,
    } = payload;

    const result = yield call(api.post, 'users', {
      name,
      email,
      password,
      rg,
      born,
      cpf,
      phone,
    });
    if (result.status === 200) {
      yield call(api.post, 'address', {
        email,
        cep,
        number,
        neighborhood,
        address,
        city,
        state,
      });
      toast.success('Usuario Cadastrado com sucesso!');
    }
  } catch (err) {
    toast.error('Verifique os dados digitados!');
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
