import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Calendar from 'react-calendar';
import moment from 'moment';
import { subHours } from 'date-fns';

import {
  Container,
  Time,
  ContainerCalendar,
  ConfirmationButton,
} from './styles';
import api from '../../services/api';
import 'react-calendar/dist/Calendar.css';

function ShowAppointments() {
  const profile = useSelector((state) => state.user.profile);
  const [appointments, setAppointments] = useState([]);
  const [value, onChange] = useState(new Date());
  const [reaload, setReload] = useState([]);

  useEffect(() => {
    async function loadAppointments() {
      const response = await api.get('allAppointmentsAdmin');
      const datas2 = await response.data.filter((info) => {
        return info.seller.id === Number(profile.id);
      });
      const datas4 = await datas2.filter((info) => {
        return (
          moment(info.date).format('DD/MM/YYYY') ===
          moment(value).format('DD/MM/YYYY')
        );
      });
      setAppointments(datas4);
    }
    loadAppointments();
  }, [value, reaload]);

  async function changeDate(date, ids) {
    const response = await api.put('appointments', {
      ids,
      date: subHours(new Date(date), 3),
    });
    setReload(response);
  }
  async function DeleteAppointment(id) {
    const response = await api.post('appointmentsDelete', {
      id,
    });
    setReload(response);
  }

  return (
    <Container>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <h1>Listar Agendamentos</h1>
        <ContainerCalendar>
          <Calendar onChange={onChange} value={value} />
        </ContainerCalendar>
        <div style={{ flexDirection: 'row', height: 200 }}>
          <ul>
            {appointments.map((appointment) => (
              <div style={{ flexDirection: 'row' }}>
                <Time
                  key={appointment.id}
                  past={appointment.payed}
                  available={appointment.payed}
                >
                  <div>
                    <div style={{ flexDirection: 'column' }}>
                      <strong>{appointment.user.name}</strong>
                      <span>C.P.F: {appointment.user.cpf}</span>
                      <span>Procedimento: {appointment.service.procedure}</span>
                      <span>Local: {appointment.service.created.name}</span>
                      <DateTimePicker
                        disabled={appointment.payed}
                        value={appointment.date}
                        style={{ fontWeight: 'bold', color: '#000' }}
                        ampm={false}
                        onChange={(date) => changeDate(date, appointment.id)}
                        format="DD/MM/YYYY"
                      />
                    </div>
                    <div
                      style={{
                        width: 130,
                        borderRadius: 5,
                        height: 40,
                        marginTop: 5,
                        marginLeft: '30%',
                        background: '#4285F4',
                        alignSelf: 'flex-end',
                        justifyContent: 'center',
                      }}
                    >
                      <ConfirmationButton
                        disabled={appointment.payed}
                        onClick={() => DeleteAppointment(appointment.id)}
                      >
                        Remover
                      </ConfirmationButton>
                    </div>
                  </div>
                </Time>
              </div>
            ))}
          </ul>
        </div>
      </MuiPickersUtilsProvider>
    </Container>
  );
}

export default ShowAppointments;
