import React, { useState } from 'react';
// import cep from 'cep-promise';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import {
  Container,
  Content,
  InputBig,
  InputSmall,
  // Button,
  RegisterButton,
} from './styles';
import api from '../../services/api';

import useForm from '../../utils/useForm';

export default function AddPartner() {
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [loading2, setLoading] = useState(false);

  async function enviar() {
    if (
      values.name &&
      values.email &&
      values.cnpj &&
      values.phone &&
      values.split &&
      values.cep &&
      values.number &&
      values.neighborhood &&
      values.address &&
      values.city &&
      values.state !== ''
    ) {
      setLoading(true);
      const response = await api.post('partner', {
        email: values.email,
        name: values.name,
        cnpj: values.cnpj,
        phone: values.phone,
        split: Number(values.split),
      });
      if (response.status === 200) {
        const response2 = await api.post('paddress', {
          email: values.email,
          cep: values.cep,
          number: Number(values.number),
          neighborhood: values.neighborhood,
          address: values.address,
          city: values.city,
          state: values.state,
        });
        if (response2.status === 200) {
          toast.success('Agendamento feito com sucesso.');
          setLoading(false);
        } else if (response2.status === 203) {
          toast.error(
            'Erro ao fazer o cadastramento de parceiro, verifque os dados'
          );
          setLoading(false);
        } else if (response.status === 203) {
          toast.error(
            'Erro ao fazer o cadastramento de parceiro, verifque os dados'
          );
          setLoading(false);
        }
      } else if (response.status === 204) {
        setLoading(false);
        toast.error('Parceiro ja existe.');
      }
    }
  }

  // async function checkCep() {
  //   const value = await cep(values.cep);
  //   handleChange(value.neighborhood);
  // }

  return (
    <Container>
      <Content>
        <h1>Cadastrar parceiro</h1>
        <form onSubmit={handleSubmit(enviar)}>
          <h2>Nome</h2>
          <InputBig onChange={handleChange} name="name" type="text" />
          <h2>E-mail</h2>
          <InputBig
            onChange={handleChange}
            name="email"
            autocapitalize="off"
            type="email"
          />
          <h2>CNPJ</h2>
          <InputBig
            onChange={handleChange}
            name="cnpj"
            mask="99.999.999/9999-99"
          />
          <h2>Split em %</h2>
          <InputBig onChange={handleChange} name="split" type="number" />
          <h2>Telefone</h2>
          <div>
            <InputBig
              onChange={handleChange}
              name="phone"
              mask="(999)-9999-9999"
            />
          </div>
          <h2>CEP</h2>
          <div>
            <div>
              <InputBig onChange={handleChange} name="cep" mask="99999-999" />
              {/* <Button type="button" onClick={checkCep}>
                Verficar
              </Button> */}
            </div>
            <h2>Número</h2>
            <div>
              <InputSmall onChange={handleChange} name="number" type="number" />
            </div>
          </div>
          <h2>Bairro</h2>
          <InputBig onChange={handleChange} name="neighborhood" type="text" />
          <h2>Rua</h2>
          <div>
            <InputBig onChange={handleChange} name="address" type="text" />
          </div>
          <h2>Cidade</h2>
          <div>
            <div>
              <InputBig onChange={handleChange} name="city" type="text" />
            </div>
            <h2>Estado</h2>
            <div>
              <InputSmall onChange={handleChange} name="state" type="text" />
            </div>
          </div>
          <RegisterButton type="submit">
            {loading2 ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Cadastrar'
            )}
          </RegisterButton>
        </form>
      </Content>
    </Container>
  );
}
