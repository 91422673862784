import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import cart from './cart/reducers';
import paymantcart from './paymantCard/reducers';
import cartProduct from './cartProduct/reducers';
import paymantCardProduct from './paymantCardProduct/reducers';
import updatesProfile from './updatesProfile/reducer';

export default combineReducers({
  auth,
  user,
  cart,
  paymantcart,
  cartProduct,
  paymantCardProduct,
  updatesProfile,
});
