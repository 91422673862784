/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'react-credit-cards/es/styles-compiled.css';
import Cards from 'react-credit-cards';
import { Form, Input, Scope } from '@rocketseat/unform';
import { parseISO, differenceInDays } from 'date-fns';
import publicIp from 'public-ip';

import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBIcon,
} from 'mdbreact';
import InputMask from 'react-input-mask';

import { toast } from 'react-toastify';
import history from '../../services/history';
import api from '../../services/api';

import { formatPrice } from '../../utils/format';
import { Container, ProductTable, Total, Payment } from './styles';

export default function Cart() {
  const [modal, setModal] = useState(false);
  const [withcard, setWithcard] = useState();
  const [adress, setAdress] = useState([]);
  const [debit, setDebit] = useState(true);
  const [credit, setCredit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState({
    holder_name: '',
    number: '',
    expiration_date: '',
    cvv: '',
    share: '',
    id: '',
  });
  const profileUser = useSelector((s) => s.user.profile);

  const profile = useSelector((state) => state.paymantCardProduct);
  async function loadCard() {
    const response = await api.get(`cardPayment/${profile[0].id}`);
    if (response.data[0]) {
      const teste =
        response.data[0].card.due_date +
          differenceInDays(parseISO(response.data[0].payed_at), new Date()) <=
        0
          ? 0
          : response.data[0].card.due_date +
            differenceInDays(parseISO(response.data[0].payed_at), new Date());
      if (teste > 0) {
        setWithcard(true);
      } else {
        setWithcard(false);
      }
    } else {
      setWithcard(false);
    }
  }
  async function loadAdress() {
    const response = await api.get(`address/${profile[0].id}`);
    setAdress(response.data);
  }
  useEffect(() => {
    loadCard();
    loadAdress();
  }, []);
  async function changeModal() {
    setModal(!modal);
  }

  const total = useSelector((state) =>
    formatPrice(
      state.paymantCardProduct[0].data.reduce(
        (sumTotal, product) => sumTotal + product.service.price,
        0
      )
    )
  );
  const tota2 = useSelector((state) =>
    formatPrice(
      state.paymantCardProduct[0].data.reduce(
        (sumTotal, product) => sumTotal + product.service.pricecard,
        0
      )
    )
  );

  const cart = useSelector((state) =>
    state.paymantCardProduct[0].data.map((product) => ({
      ...product,
      pricenocard: formatPrice(product.price),
      pricewithcard: formatPrice(product.pricecard),
    }))
  );
  function handleChangeCard(e) {
    const { name } = e.target;
    const { value } = e.target;

    setCard({ ...card, [name]: value, id: '' });
  }
  async function handleSubmit(money) {
    setLoading(true);

    const value = [];
    const valueseller = [];
    const valueemitp = [];
    const valuefran = [];
    const valueadmin = [];
    const valueemit = value;

    for (let i = 0; i < cart.length; i += 1) {
      if (withcard) {
        value[i] = cart[i].service.pricecard * (cart[i].service.split / 100);
        valueseller[i] =
          (cart[i].service.pricecard - value[i]) *
          (cart[i].seller.splitproduct / 100);
        valueemitp[i] =
          (cart[i].service.pricecard - value[i]) *
          (cart[i].seller.created.splitproduct / 100);
        valuefran[i] =
          (cart[i].service.pricecard - value[i]) *
          (cart[i].seller.created.created.splitproduct / 100);
        valueadmin[i] =
          cart[i].service.pricecard -
          value[i] -
          valueseller[i] -
          valueemitp[i] -
          valuefran[i];
        valueemit[i] = value[i];
      } else {
        value[i] = cart[i].service.price * (cart[i].service.split / 100);
        valueseller[i] =
          (cart[i].service.price - value[i]) *
          (cart[i].seller.splitproduct / 100);
        valueemitp[i] =
          (cart[i].service.price - value[i]) *
          (cart[i].seller.created.splitproduct / 100);
        valuefran[i] =
          (cart[i].service.price - value[i]) *
          (cart[i].seller.created.created.splitproduct / 100);
        valueadmin[i] =
          cart[i].service.price -
          value[i] -
          valueseller[i] -
          valueemitp[i] -
          valuefran[i];
        valueemit[i] = value[i];
      }
    }
    const ip = await publicIp.v4();
    const sValueSeller = valueseller.reduce(function (acumulador, valorAtual) {
      return acumulador + valorAtual;
    }, 0);
    const sValueEmitp = valueemitp.reduce(function (acumulador, valorAtual) {
      return acumulador + valorAtual;
    }, 0);
    const sValueFran = valuefran.reduce(function (acumulador, valorAtual) {
      return acumulador + valorAtual;
    }, 0);
    const sValueAdmin = valueadmin.reduce(function (acumulador, valorAtual) {
      return acumulador + valorAtual;
    }, 0);
    const sValueEmit = valueemit.reduce(function (acumulador, valorAtual) {
      return acumulador + valorAtual;
    }, 0);
    const chaveERP = `IDC${profile[0].id}IDV${cart[0].seller.id}IDP${
      cart[0].service.created.id
    }T${new Date().getTime()}`;
    const data = {
      chaveERP,
      valor: (
        parseFloat(sValueSeller.toFixed(2)) +
        parseFloat(sValueEmitp.toFixed(2)) +
        parseFloat(sValueFran.toFixed(2)) +
        parseFloat(sValueAdmin.toFixed(2)) +
        parseFloat(sValueEmit.toFixed(2))
      ).toFixed(2),
      numeroParcela: Number(card.share),
      CardToken: null,
      Brand: null,
      nome: cart[0].user.name,
      identificador: cart[0].user.cpf,
      email: cart[0].user.email,
      telefone: cart[0].user.phone,
      uf: adress[0].state,
      cidade: adress[0].city,
      logradouro: adress[0].address,
      bairro: adress[0].neighborhood,
      numero: adress[0].number,
      cep: adress[0].cep,
      complemento: null,
      permitePagamentoCartao: Number(credit),
      permitePagamentoDebito: Number(debit),
      permitePagamentoBoleto: 0,
      cartaonome: card.holder_name,
      cartaonumero: card.number,
      cartaovencimento: card.expiration_date,
      cartaocodigoseguranca: card.cvv,
      TokenizaCartao: 0,
      listaDivisao: [
        {
          identificador: cart[0].seller.cpf,
          valorDivisao: sValueSeller.toFixed(2),
          emiteNotaOurecibo: 1,
        },
        {
          identificador: cart[0].seller.created.cnpj,
          valorDivisao: sValueEmitp.toFixed(2),
          emiteNotaOurecibo: 1,
        },
        {
          identificador: cart[0].seller.created.created.cnpj,
          valorDivisao: sValueFran.toFixed(2),
          emiteNotaOurecibo: 1,
        },
        {
          identificador: cart[0].seller.created.created.created.cnpj,
          valorDivisao: sValueAdmin.toFixed(2),
          emiteNotaOurecibo: 1,
        },
        {
          identificador: cart[0].service.created.cnpj,
          valorDivisao: sValueEmit.toFixed(2),
          emiteNotaOurecibo: 1,
        },
      ],
      ipPagamento: ip,
    };
    if (money === true) {
      const response = { data: { Pago: true }, status: 200 };
      if (response.status === 200) {
        if (response.data.Pago === false) {
          setLoading(false);
          toast.error('Erro ao fazer pagamento, verifque os dados.');
        } else {
          for (let i = 0; i < cart.length; i += 1) {
            const response2 = await api.post('fature', {
              seller_id: cart[i].seller.id,
              emitp_id: cart[i].seller.created.id,
              fran_id: cart[i].seller.created.created.id,
              admin_id: cart[i].seller.created.created.created.id,
              emit_id: cart[i].service.created.id,
              appointment_id: cart[i].id,
              withcard,
              chaveERP,
              money: true,
              receptionist_id: profileUser.id,
              fature_id:
                profileUser.seller || profileUser.receptionist
                  ? profileUser.created_id
                  : profileUser.id,
            });
            setLoading(false);
            if (response2.status === 200) {
              toast.success('Pagamento realizado com sucesso.');
              setLoading(false);
              history.push('/createProductsCart');
            }
          }
        }
      } else {
        toast.error('Erro ao fazer pagamento, verifque os dados.');
        setLoading(false);
      }
    } else {
      // const response = { data: { Pago: false }, status: 200 };
      const response = await api.post('makePaymentController', { data });
      if (response.status === 200) {
        if (response.data.Pago === false) {
          setLoading(false);
          toast.error('Erro ao fazer pagamento, verifque os dados.');
        } else {
          for (let i = 0; i < cart.length; i += 1) {
            const response2 = await api.post('fature', {
              seller_id: cart[i].seller.id,
              emitp_id: cart[i].seller.created.id,
              fran_id: cart[i].seller.created.created.id,
              admin_id: cart[i].seller.created.created.created.id,
              emit_id: cart[i].service.created.id,
              appointment_id: cart[i].id,
              withcard,
              receptionist_id: profileUser.id,
              fature_id:
                profileUser.seller || profileUser.receptionist
                  ? profileUser.created_id
                  : profileUser.id,
              chaveERP,
              money: false,
            });
            setLoading(false);
            if (response2.status === 200) {
              toast.success('Pagamento realizado com sucesso.');
              history.push('/createProductsCart');
              setLoading(false);
            }
          }
        }
      } else {
        toast.error('Erro ao fazer pagamento, verifque os dados.');
        setLoading(false);
      }
    }
  }

  return (
    <Container>
      <h1>Carrinho</h1>
      <MDBModal size="lg" isOpen={modal} toggle={changeModal}>
        <MDBModalHeader toggle={changeModal}>Dados bancários</MDBModalHeader>
        <MDBModalBody>
          <Form onSubmit={handleSubmit}>
            <Payment>
              <div className="form-area">
                <Scope path="card">
                  <h6>Nome no cartão</h6>
                  <InputMask
                    name="holder_name"
                    label="Nome no cartão"
                    onChange={handleChangeCard}
                  />
                  <h6>Número do cartão</h6>
                  <InputMask
                    name="number"
                    label="Número do cartão"
                    onChange={handleChangeCard}
                  />
                  <div className="group">
                    <div>
                      <Input
                        label="debito"
                        style={{ height: 20, width: 20 }}
                        type="checkbox"
                        checked={debit}
                        onChange={() => {
                          setDebit(!debit);
                          setCredit(!credit);
                        }}
                      />
                    </div>
                    <div>
                      <Input
                        label="credito"
                        style={{ height: 20, width: 20 }}
                        type="checkbox"
                        checked={credit}
                        onChange={() => {
                          setDebit(!debit);
                          setCredit(!credit);
                        }}
                      />
                    </div>
                  </div>
                  <div className="group">
                    <div>
                      <h6>Data de expiração</h6>
                      <InputMask
                        name="expiration_date"
                        mask="99/99"
                        label="Data de expiração"
                        onChange={handleChangeCard}
                      />
                    </div>
                    <div>
                      <h6>Código de segurança</h6>
                      <InputMask
                        name="cvv"
                        label="Código de segurança"
                        onChange={handleChangeCard}
                      />
                    </div>
                    <div>
                      <h6>Número de parcelas</h6>
                      <InputMask
                        name="share"
                        type="number"
                        label="Número de parcelas"
                        onChange={handleChangeCard}
                      />
                    </div>
                  </div>
                </Scope>
              </div>
              <div className="credit-card">
                <Cards
                  number={card.number}
                  name={card.holder_name}
                  expiry={card.expiration_date}
                  cvc={card.cvv}
                  focused="number"
                />
              </div>
            </Payment>
            <MDBBtn type="submit">
              {loading ? (
                <>
                  <MDBIcon icon="spinner" spin size="2x" fixed />
                  <span className="sr-only">Carregando...</span>
                </>
              ) : (
                'Enviar'
              )}
            </MDBBtn>
          </Form>
        </MDBModalBody>
      </MDBModal>
      <ProductTable>
        <table>
          <thead>
            <tr>
              <th>Código</th>
              <th>Produto</th>
              <th>Preço com cartão</th>
              <th>Preço sem cartão</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {cart.map((product, key) => (
              <tr>
                <td>
                  <span>{product.service.code}</span>
                </td>
                <td>
                  <span>{product.service.procedure}</span>
                </td>
                <td>
                  <strong>{product.service.pricecard}</strong>
                </td>
                <td>
                  <strong>{product.service.price}</strong>
                </td>
                <td>
                  <strong>{moment(product.date).format('DD/MM/YYYY')}</strong>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ProductTable>

      <footer>
        <button type="button" onClick={() => changeModal()}>
          Pagar com cartão (Crédito ou Débito)
        </button>
        {loading ? (
          <>
            <MDBIcon icon="spinner" spin size="2x" fixed />
            <span className="sr-only">Carregando...</span>
          </>
        ) : (
          <button
            type="button"
            onClick={() => {
              handleSubmit(true);
            }}
          >
            Pagar em dinheiro
          </button>
        )}
        {withcard ? (
          <>
            <Total>
              <span>Total com cartão</span>
              <strong>{tota2}</strong>
            </Total>
          </>
        ) : (
          <Total>
            <span>Total sem cartão</span>
            <strong>{total}</strong>
          </Total>
        )}
      </footer>
    </Container>
  );
}
