import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 1400px;
  margin: 50px;
  margin-left: 500px;
  div {
    display: flex;
    flex-direction: row;
    align-self: auto;
  }
  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    h1 {
      font: 40px 'Bebas Neue', sans-serif;
      color: #0b7269;
      margin-bottom: 30px;
    }
    h2 {
      font: 22px 'Bebas Neue', sans-serif;
      color: #0b7269;
      margin-bottom: 10px;
      margin-top: 5px;
    }
    select {
      background: rgba(255, 255, 255, 0.4);
      border: 1px solid #0b7269;
      border-radius: 4px;
      height: 28px;
      width: 70%;
      color: #0b7269;
      margin: 0 0 10px;

      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box;
      &::placeholder {
        color: #0b7269;
      }
    }
    input {
      background: rgba(255, 255, 255, 0.4);
      border: 1px solid #0b7269;
      border-radius: 4px;
      height: 28px;
      width: 70%;
      color: #0b7269;
      margin: 0 0 10px;
      padding: 10px;
      -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
      -moz-box-sizing: border-box; /* Firefox, other Gecko */
      box-sizing: border-box;
      &::placeholder {
        color: #0b7269;
      }
    }
    hr {
      border: 0;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
      margin: 10px 0 20px;
    }
    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 20px 0px;
      padding: 10px;
      width: 70%;
      height: 50;
      background: #0b7269;
      color: #fff;
      border: 0;
      border-radius: 5px;
      font-size: 14px;
      transition: background 0.2s;
      align-self: flex-start;
      font: 15px 'Bebas Neue', sans-serif;
      &:hover {
        background: ${darken(0.1, '#0b7269')};
      }
    }
    a {
      color: #fff;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
  > button {
    width: 800px;
    margin: 10px 0 0;
    height: 44px;
    background: #f64c75;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.08, '#f64c75')};
    }
  }
`;
