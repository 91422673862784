import React, { useEffect, useState, Children } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import api from '../../services/api';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/pt-br';
import { formatPrice } from '../../utils/format';
import { Container } from './styles';

const localizer = momentLocalizer(moment); // or globalizeLocalizer
const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(Children.only(children), {
    style: {
      ...children.style,
      backgroundColor: 'transparent',
    },
  });

export default function Basic() {
  const [info1, setInfo1] = useState([]);
  function eventStyleGetter() {
    const backgroundColor = `#0b7269`;
    const style = {
      backgroundColor,
      borderRadius: '2px',
      opacity: 0.8,
      paddingTop: 10,
      paddingBottom: 10,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  }

  useEffect(() => {
    async function getFature() {
      const response = await api.get('fature');
      const datas = response.data.filter((info) => {
        return info.money === true;
      });
      const datas2 = response.data.filter((info) => {
        return info.money === false;
      });

      const calendarData = await response.data.map((info) => {
        return {
          id: info.money
            ? new Date(info.date).toJSON().slice(0, 10).replace(/-/g, '/')
            : new Date(info.date).toJSON().slice(0, 10).replace(/-/g, '/'),
          title: info.money
            ? `Dinheiro: ${formatPrice(
                datas
                  .filter(
                    ({ date }) =>
                      new Date(date)
                        .toJSON()
                        .slice(0, 10)
                        .replace(/-/g, '/') ===
                      new Date(info.date)
                        .toJSON()
                        .slice(0, 10)
                        .replace(/-/g, '/')
                  )
                  .reduce(
                    (sum, record) =>
                      sum +
                      record.valueadmin +
                      record.valuefran +
                      record.valueemit +
                      record.valueemitp +
                      record.valueseller,
                    0
                  )
              )}`
            : `Cartão: ${formatPrice(
                datas2
                  .filter(
                    ({ date }) =>
                      new Date(date)
                        .toJSON()
                        .slice(0, 10)
                        .replace(/-/g, '/') ===
                      new Date(info.date)
                        .toJSON()
                        .slice(0, 10)
                        .replace(/-/g, '/')
                  )
                  .reduce(
                    (sum, record) =>
                      sum +
                      record.valueadmin +
                      record.valuefran +
                      record.valueemit +
                      record.valueemitp +
                      record.valueseller,
                    0
                  )
              )}`,
          allDay: true,
          start: new Date(info.date),
          end: new Date(info.date),
        };
      });
      const reduced = [];
      calendarData.forEach((item) => {
        const duplicated =
          reduced.findIndex((redItem) => {
            return item.id === redItem.id;
          }) > -1;

        if (!duplicated) {
          reduced.push(item);
        }
      });
      setInfo1(reduced);
    }

    getFature();
  }, []);

  return (
    <Container>
      <h1>Calendário</h1>
      <Calendar
        localizer={localizer}
        events={info1}
        step={90}
        views={['month']}
        style={{ height: 600 }}
        showMultiDayTimes
        eventPropGetter={eventStyleGetter}
        components={{
          dateCellWrapper: ColoredDateCellWrapper,
        }}
      />
    </Container>
  );
}
