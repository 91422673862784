import React, { useState, useEffect } from 'react';
import {
  MDBDataTable,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from 'mdbreact';
import ceps from 'cep-promise';
import { toast } from 'react-toastify';
import {
  Container,
  Button,
  InputBig,
  InputSmall,
  Content,
  ConfirmationButton,
} from './styles';
import api from '../../services/api';

function ShowIssuer() {
  const [usersFranchisee, setUsersFranchisee] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [cep, setCep] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [number, setNumber] = useState('');
  const [state, setState] = useState('');
  const [ids, setIds] = useState('');
  const [info, setInfo] = useState([]);
  const [find, setFind] = useState(false);

  const [reload, setReload] = useState(false);

  async function changeModal(id) {
    setModal(!modal);

    const response = await api.get(`/address/${id}`);
    setInfo(response.data[0]);
  }

  async function changeModalEdt(id) {
    setModal2(!modal2);
    const response = await api.get(`/address/${id}`);
    setIds(response.data[0].id);
    setNumber(response.data[0].number);
    setNeighborhood(response.data[0].neighborhood);
    setCep(response.data[0].cep);
    setAddress(response.data[0].address);
    setCity(response.data[0].city);
    setState(response.data[0].state);
  }
  function checkCep() {
    setLoading2(true);
    ceps(cep)
      .then((res) => {
        setLoading2(false);
        setFind(true);
        setCity(res.city);
        setAddress(res.street);
        setNeighborhood(res.neighborhood);
        setState(res.state);
      })
      .catch(() => {
        setLoading2(false);
        setFind(false);
        toast.error('Cep invalido!');
      });
  }

  async function enviarContato() {
    if (find === true) {
      if (cep && number && neighborhood && address && city && state !== '') {
        const response = await api.put('addressUpdate', {
          id: Number(ids),
          cep,
          address,
          number,
          city,
          neighborhood,
          state,
        });
        if (response.status === 200) {
          toast.success('Dados atualizados!');
        } else {
          toast.error('Erro ao atualizar tente novamente!');
        }
      } else {
        toast.error('Todos os campos devem estar preenchidos!');
      }
    } else {
      toast.error('Valide o cep!');
    }
  }

  async function deleteUser(id) {
    await api.put('userSellerDelete', {
      id,
    });
    setReload(!reload);
  }

  async function deactivateUser(deactivate, email) {
    await api.put('userseller', {
      deactivate: !deactivate,
      email,
    });
    setReload(!reload);
  }

  useEffect(() => {
    async function Appointments() {
      const response = await api.get('getusersIssuer');
      setUsersFranchisee({
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 80,
          },
          {
            label: 'Nome',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Cnpj',
            field: 'cnpj',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Telefone',
            field: 'phone',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Editar Endereço',
            field: 'edtAd',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Endereço',
            field: 'action',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Desativar',
            field: 'deactivate',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Deletar',
            field: 'deleteService',
            sort: 'asc',
            width: 200,
          },
        ],
        rows: response.data.map((events) => ({
          ...events,
          edtAd: (
            <Button onClick={() => changeModalEdt(events.id)}>
              Editar Endereço
            </Button>
          ),
          action: (
            <Button onClick={() => changeModal(events.id)}>Endereço</Button>
          ),
          deactivate: (
            <>
              {events.deactivate ? (
                <Button
                  onClick={() =>
                    deactivateUser(events.deactivate, events.email)
                  }
                >
                  Ativar
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    deactivateUser(events.deactivate, events.email)
                  }
                >
                  Desativar
                </Button>
              )}
            </>
          ),
          deleteService: (
            <Button onClick={() => deleteUser(events.id)}>Deletar</Button>
          ),
        })),
      });
    }
    Appointments();
  }, [reload]);

  return (
    <Container>
      <h1>Buscar Emitentes</h1>
      <MDBModal isOpen={modal} toggle={changeModal}>
        <MDBModalHeader toggle={changeModal}>
          <h1>Endereço</h1>
        </MDBModalHeader>
        <MDBModalBody>
          <h4>
            <strong>Rua:</strong> {info.address}
          </h4>
          <h4>
            <strong>Cep:</strong> {info.cep}
          </h4>
          <h4>
            <strong>Bairro:</strong> {info.neighborhood}
          </h4>
          <h4>
            <strong>Numero:</strong> {info.number}
          </h4>
          <h4>
            <strong>Cidade:</strong> {info.city}
          </h4>
          <h4>
            <strong>Estado:</strong> {info.state}
          </h4>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={() => changeModal()}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <MDBModal isOpen={modal2} toggle={changeModalEdt}>
        <MDBModalHeader toggle={changeModalEdt}>
          <h1>Endereço</h1>
        </MDBModalHeader>
        <MDBModalBody>
          <Content>
            <h2>CEP</h2>
            <div>
              <div style={{ flexDirection: 'row' }}>
                <div>
                  <InputBig
                    onChange={(e) => setCep(e.target.value)}
                    value={cep}
                    name="cep"
                    mask="99999-999"
                  />
                </div>
                <div>
                  {loading2 ? (
                    <ConfirmationButton type="button">
                      <MDBIcon icon="compass" spin size="1x" fixed />
                      <span className="sr-only">Carregando...</span>
                    </ConfirmationButton>
                  ) : (
                    <ConfirmationButton type="button" onClick={checkCep}>
                      <MDBIcon icon="compass" size="1x" fixed />
                    </ConfirmationButton>
                  )}
                </div>
              </div>
              <h2>Número</h2>
              <div>
                <InputSmall
                  onChange={(e) => setNumber(e.target.value)}
                  value={number}
                  type="number"
                />
              </div>
            </div>
            <h2>Bairro</h2>
            <InputBig
              onChange={(e) => setNeighborhood(e.target.value)}
              value={neighborhood}
              name="neighborhood"
              type="text"
            />
            <h2>Rua</h2>
            <div>
              <InputBig
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                name="address"
                type="text"
              />
            </div>
            <h2>Cidade</h2>
            <div>
              <div>
                <InputBig
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                  name="city"
                  type="text"
                />
              </div>
              <h2>Estado</h2>
              <div>
                <InputSmall
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                  name="state"
                  type="text"
                  maxlength="2"
                />
              </div>
            </div>
          </Content>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={() => changeModalEdt()}>
            Fechar
          </MDBBtn>
          <MDBBtn color="primary" onClick={enviarContato}>
            Enviar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <MDBDataTable
        striped
        responsive
        entries={10}
        maxHeight={500}
        scrollX
        data={usersFranchisee}
      />
    </Container>
  );
}

export default ShowIssuer;
