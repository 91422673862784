import React, { useState, useEffect } from 'react';

import { MDBDataTable } from 'mdbreact';
import { MdShoppingBasket } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/headerProduct';
import { addToCartRequest } from '../../store/modules/cartProduct/actions';

import api from '../../services/api';
import { Container, Button } from './styles';

export default function AddClient() {
  const dispatch = useDispatch();

  const [issuer, setIssuer] = useState([]);

  const [issuerLocal, setLocalIssuer] = useState([]);

  const [city, setCity] = useState([]);
  const [cityLocal, setCityLocal] = useState([]);
  const [services, setServices] = useState([]);
  const [allservices, setAllServices] = useState([]);

  const [sub, setSub] = useState([]);
  const [group, setGroup] = useState([]);
  const [code, setCode] = useState([]);

  const [change, setChangeState] = useState(true);
  const [card, setCard] = useState([]);
  const cart = useSelector((state) => state.cartProduct.length);

  useEffect(() => {
    async function getCity() {
      const response = await api.get('addressSellerController');
      const datas = await response.data.filter((info) => {
        return info.user.admin === true;
      });
      const citylocal = datas.map(function (item) {
        return item.city; // retorna o item original elevado ao quadrado
      });
      const result = citylocal.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
      setCity(result);
    }

    // async function getIssuer() {
    //   const response = await api.get('addressSellerController');
    //   const datas = await response.data.filter((info) => {
    //     return info.city === cityLocal;
    //   });
    //   const emitentes = await datas.filter((info) => {
    //     return info.user.admin === true;
    //   });
    //   setIssuer(emitentes);
    // }
    async function getServices() {
      const response = await api.get('allServices');
      const datas = await response.data.filter((info) => {
        return info.deactivate === false;
      });
      const datas2 = datas.filter((info) => {
        return info.product === true;
      });
      setAllServices(datas2);
      const servicelocal = datas2.map(function (item) {
        return item.group;
      });
      const result = servicelocal.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
      setServices(result);
    }
    getCity();
    getServices();
  }, [cityLocal, issuerLocal, change]);
  function handleChangeCity(e) {
    setCityLocal(e);
  }
  function handleAddProduct(id) {
    dispatch(addToCartRequest(id));
  }

  function handleChangeProcedure(e) {
    const datas = allservices.filter((info) => {
      return info.procedure === e;
    });
    const datas2 = datas.filter((info) => {
      return info.product === true;
    });

    const servicelocal = datas2.map(function (item) {
      return item;
    });
    const result = servicelocal.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    const a = result.sort(function (c, b) {
      return (c.price * c.split) / 100 - (b.price * b.split) / 100;
    });

    setIssuer(a);
    setCode(datas[0].code);
  }
  function handleChangeSub(e) {
    const datas = allservices.filter((info) => {
      return info.subgroup === e;
    });
    const servicelocal = datas.map(function (item) {
      return { name: item.procedure, id: item.id };
    });
    const result = servicelocal.reduce(
      (unique, item) =>
        unique.includes(item.name) ? unique : [...unique, item.name],
      []
    );

    setSub(result);
  }
  function handleChangeGroup(e) {
    const datas = allservices.filter((info) => {
      return info.group === e;
    });
    const servicelocal = datas.map(function (item) {
      return item.subgroup;
    });
    const result = servicelocal.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setGroup(result);
  }

  async function handleChangeServiceCode(e) {
    setChangeState(false);
    const response = await api.post('localServices', {
      code: Number(code),
      created_id: Number(e),
    });
    setCard({
      columns: [
        {
          label: 'Código',
          field: 'code',
          sort: 'asc',
          width: 10,
        },
        {
          label: 'Grupo',
          field: 'group',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Sub-Grupo',
          field: 'subgroup',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Produto',
          field: 'procedure',
          sort: 'asc',
          width: 100,
        },
        {
          label: 'Carrinho',
          field: 'action',
          sort: 'asc',
          width: 100,
        },
      ],
      rows: response.data.reverse().map((events) => ({
        ...events,
        action: (
          <button
            type="button"
            style={{ background: 'transparent', borderWidth: 0 }}
          >
            <MdShoppingBasket
              size={30}
              color="#4285F4"
              onClick={() => handleAddProduct(events.id)}
            />
          </button>
        ),
      })),
    });
  }

  return (
    <Container>
      <Header cartSize={cart} />

      <form style={{ alignSelf: 'center', margin: 50 }}>
        <h1>Produtos</h1>

        {change === true ? (
          <>
            <div>
              <h2>Cidade</h2>
              <select
                onChange={(e) => handleChangeCity(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha a cidade</option>
                {city.map(function (object) {
                  return <option value={object}>{object}</option>;
                })}
              </select>
            </div>
            <div>
              <h2>Grupo</h2>
              <select
                onChange={(e) => handleChangeGroup(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha o grupo</option>
                {services.map(function (object) {
                  return <option value={object}>{object}</option>;
                })}
              </select>
              <h2>Sub-Grupo</h2>
              <select
                onChange={(e) => handleChangeSub(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha o sub-grupo</option>
                {group.map(function (object) {
                  return <option value={object}>{object}</option>;
                })}
              </select>
              <h2>Procedimento</h2>
              <select
                onChange={(e) => handleChangeProcedure(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha o procedimento</option>
                {sub.map(function (object) {
                  return <option value={object}>{object}</option>;
                })}
              </select>
              <div>
                <h2>Local</h2>
                <select
                  onChange={(e) =>
                    handleChangeServiceCode(e.currentTarget.value)
                  }
                  name="Pacient"
                >
                  <option value={1}>Escolha o local</option>
                  {issuer.map(function (object) {
                    return (
                      <option value={object.created.id}>
                        {object.created.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        ) : (
          <>
            <MDBDataTable
              striped
              responsive
              entries={10}
              maxHeight={500}
              scrollX
              data={card}
            />
            <Button type="button" onClick={() => setChangeState(true)}>
              Voltar
            </Button>
          </>
        )}
      </form>
    </Container>
  );
}
