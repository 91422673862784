import React, { useState, useEffect } from 'react';

import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import api from '../../services/api';
import { Container, Content, RegisterButton } from './styles';

export default function CreateCardLink() {
  const profile = useSelector((state) => state.user.profile);
  const [users, setUsers] = useState([]);
  const [card, setCard] = useState([]);

  const [email, setEmail] = useState('');

  const [card_id, setCard_id] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getUsers() {
      const response = await api.get('users');
      setUsers(response.data);
    }
    async function getCard() {
      const response = await api.get('cardSeller');
      const datas = await response.data.filter((info) => {
        return info.created_id === profile.created_id;
      });
      setCard(datas);
    }
    getUsers();
    getCard();
  }, []);
  function handleChangeEmail(e) {
    setEmail(e);
  }

  function handleChangeCardId(e) {
    setCard_id(Number(e));
  }

  async function handleUpload() {
    if ((email && card_id) !== '') {
      setLoading(true);
      const response = await api.post('cardPayment', {
        email,
        card_id,
      });

      if (response.status === 200) {
        toast.success('Cartão gerado com sucesso.');
        setLoading(false);
      } else if (response.status === 203) {
        toast.error('Erro ao gerar cartão, usuario possui um cartão');
        setLoading(false);
      }
    } else {
      toast.error('Preeencha todos os campos.');
    }
  }

  return (
    <Container>
      <form style={{ alignSelf: 'center', margin: 50 }}>
        <Content>
          <h1>Gerar cartão do cliente</h1>
          <div>
            <h2>Paciente</h2>
            <select
              onChange={(e) => handleChangeEmail(e.currentTarget.value)}
              name="Pacient"
            >
              <option value={1}>Escolha o nome do cliente</option>
              {users.map(function (object) {
                return <option value={object.email}>{object.name}</option>;
              })}
            </select>
          </div>
          <div>
            <h2>Cartão</h2>
            <select
              onChange={(e) => handleChangeCardId(e.currentTarget.value)}
              name="Doctor"
            >
              <option value={1}>Escolha qual o cartão usar</option>
              {card.map(function (object) {
                return (
                  <option value={object.id}>
                    Val: {object.due_date} dias Preço: R${object.price}
                  </option>
                );
              })}
            </select>
          </div>
        </Content>
        <RegisterButton type="button" onClick={() => handleUpload()}>
          {loading ? (
            <>
              <MDBIcon icon="spinner" spin size="2x" fixed />
              <span className="sr-only">Carregando...</span>
            </>
          ) : (
            'Cadastrar'
          )}
        </RegisterButton>
      </form>
    </Container>
  );
}
