import { put, all, takeLatest } from 'redux-saga/effects';
import history from '../../../services/history';

import { addToCartSuccess } from './actions';

function* addToCart({ datas }) {
  const data = {
    data: datas,
    id: datas[0].user.id,
  };

  yield put(addToCartSuccess(data));
  history.push('/paymantCardProduct');
}

export default all([takeLatest('@paymantCardProduct/ADD_REQUEST', addToCart)]);
