import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { Container, Button } from './styles';
import api from '../../services/api';

function ShowServices() {
  const [usersFranchisee, setUsersFranchisee] = useState([]);

  const [reload, setReload] = useState(false);

  async function deleteService(id) {
    await api.post('generaldelete', {
      id,
    });
    setReload(!reload);
  }
  useEffect(() => {
    async function Appointments() {
      const response = await api.get('generalProducts');

      setUsersFranchisee({
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 80,
          },
          {
            label: 'Código',
            field: 'code',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Grupo',
            field: 'group',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Sub-Grupo',
            field: 'subgroup',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Produto',
            field: 'procedure',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Deletar',
            field: 'deleteService',
            sort: 'asc',
            width: 200,
          },
        ],
        rows: response.data.map((events) => ({
          ...events,
          deleteService: (
            <Button onClick={() => deleteService(events.id)}>Deletar</Button>
          ),
        })),
      });
    }
    Appointments();
  }, [reload]);

  return (
    <Container>
      <h1>Mostrar Produtos</h1>
      <MDBDataTable
        striped
        responsive
        entries={10}
        maxHeight={500}
        scrollX
        data={usersFranchisee}
      />
    </Container>
  );
}

export default ShowServices;
