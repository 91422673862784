import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;
export const Container = styled.div`
  max-width: 80%;
  margin-left: 20%;
  margin-right: 5%;
  animation: ${fade} 1s;
  h1 {
    margin-top: 2%;
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }
  h2 {
    margin-top: 2%;
    font: 20px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }

  select {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid #0b7269;
    border-radius: 4px;
    height: 28px;
    width: 70%;
    color: #0b7269;
    margin: 0 0 10px;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    &::placeholder {
      color: #0b7269;
    }
  }
`;

export const ContainerSchedule = styled.div`
  width: 100px;
  background: transparent;
  border-radius: 5px;
  animation: ${fade} 1s;
  h1 {
    margin-top: 2%;
    font-size: 15px;
    color: #323a3d;
  }
  h2 {
    margin-top: 2%;
    font: 8px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }
`;

export const Button = styled.button`
  height: 35px;
  width: 100%;
  background: #4285f4;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  align-self: flex-end;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.03, '#4285F4')};
  }
`;

export const ConfirmationButton = styled.button`
  height: 35px;
  width: 50%;
  background: #4285f4;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.03, '#4285F4')};
  }
`;
