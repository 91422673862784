import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBIcon } from 'mdbreact';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '../../store/modules/auth/actions';

import logo from '../../assets/logo.png';
import { Container, Button } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <Container>
      <img src={logo} height="250" width="280" alt="Climed" />

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input
          name="email"
          autocapitalize="off"
          type="email"
          placeholder="Digite seu e-mail"
        />
        <Input name="password" type="password" placeholder="Digite sua senha" />
        <Button type="submit">
          {loading ? (
            <>
              <MDBIcon icon="spinner" spin size="2x" fixed />
              <span className="sr-only">Carregando...</span>
            </>
          ) : (
            'Acessar'
          )}
        </Button>
      </Form>
    </Container>
  );
}
