import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SideBar from '../../../components/SideBar';

import { Wrapper } from './styles';
import api from '../../../services/api';

export default function DefaultLayout({ children }) {
  const [deactivate, setDeactivate] = useState(false);

  // useEffect(() => {
  //   async function getUserInfo() {
  //     const response2 = await api.get('sessions');
  //     setDeactivate(response2.data[0].deactivate);
  //   }
  //   getUserInfo();
  // }, []);

  return (
    <Wrapper>
      {children}
      {!deactivate ? <SideBar /> : null}
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
