/* eslint-disable prefer-spread */
import React, { useEffect, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';

import { Container } from './styles';
import { updateProfileRequest } from '../../store/modules/user/actions';
import api from '../../services/api';

export default function Profile() {
  const [users, setUsers] = useState([]);
  const [sellers, setSellers] = useState([]);

  const [sellersShow, setSellersShow] = useState([]);

  const [products, setProducts] = useState(false);
  const [appointments, setAppointments] = useState(false);
  const [card, setCard] = useState(false);
  const [provider, setProvider] = useState(false);
  const [issuer, setIssuer] = useState(false);

  const profile = useSelector((s) => s.user.profile);

  const dispatch = useDispatch();
  const formRef = useRef(null);
  useEffect(() => {
    async function getUsers() {
      const response = await api.get('usersIssuerCreated');
      setUsers(response.data);
    }
    async function getSellers() {
      const response = await api.get('getSellerUserController');
      setSellers(response.data);
    }

    getUsers();
    getSellers();
  }, []);

  function handleSubmit(datas) {
    const split = [];
    const splitcard = [];
    const splitproduct = [];
    for (let i = 0; i < sellersShow.length; i += 1) {
      split[i] = sellersShow[i].split;
      splitcard[i] = sellersShow[i].splitcard;
      splitproduct[i] = sellersShow[i].splitproduct;
    }
    const Ssplit = Math.max.apply(Math, split);
    const SsplitCard = Math.max.apply(Math, splitcard);
    const Ssplitproduct = Math.max.apply(Math, splitproduct);

    const data = [{ ...datas, products, appointments, card, provider, issuer }];
    if (100 - profile.split - datas.split - Ssplit < 0) {
      toast.error(
        `valor permitido para agendamentos de até, ${
          100 - profile.split - Ssplit
        }`
      );
    } else if (100 - profile.splitcard - datas.splitcard - SsplitCard < 0) {
      toast.error(
        `valor permitido para cartão de até, ${
          100 - profile.splitcard - SsplitCard
        }`
      );
    } else if (
      100 - profile.splitproduct - datas.splitproduct - Ssplitproduct <
      0
    ) {
      toast.error(
        `valor permitido para produtos de até, ${
          100 - profile.splitproduct - Ssplitproduct
        }`
      );
    } else {
      dispatch(updateProfileRequest(data));
    }
  }
  async function userInfo(email) {
    const datas = await users.filter((info) => {
      return info.email === email;
    });
    const sellersSho = await sellers.filter((info) => {
      return info.created.created.email === email;
    });

    await setSellersShow(sellersSho);
    await setAppointments(datas[0].appointments);
    await setProducts(datas[0].products);
    await setCard(datas[0].card);
    await setProvider(datas[0].provider);
    await setIssuer(datas[0].issuer);

    setTimeout(function () {
      formRef.current.setData(datas[0]);
    }, 300);
  }

  return (
    <Container>
      <Form ref={formRef} autocomplete="false" onSubmit={handleSubmit}>
        <h1>Editar Emitentes ou prestadores</h1>
        <h2>Buscar Emitentes ou prestadores</h2>
        <select
          onChange={(e) => userInfo(e.currentTarget.value)}
          name="Pacient"
        >
          <option value={1}>Escolha o nome</option>
          {users.map(function (object) {
            return <option value={object.email}>{object.name}</option>;
          })}
        </select>

        <h2>E-mail</h2>
        <Input
          name="email"
          readOnly
          autocapitalize="off"
          placeholder="Seu endereço completo"
        />
        <h2>Nome</h2>
        <Input name="name" placeholder="Nome completo" />
        <h2>Senha</h2>
        <Input type="password" name="password" placeholder="Nova senha" />
        <h2>Cnpj</h2>
        <InputMask
          name="cnpj"
          placeholder="Digite o cnpj"
          mask="99.999.999/9999-99"
        />
        <h2>Telefone</h2>

        <Input name="phone" placeholder="Digite o telefone" maxlength="11" />
        <div style={{ marginTop: 20 }}>
          <h2 style={{ marginRight: -289 }}>Emitente</h2>
          <input
            type="checkbox"
            checked={issuer}
            onChange={() => setIssuer(!issuer)}
          />
          <h2 style={{ marginRight: -300 }}>Prestador</h2>
          <input
            type="checkbox"
            checked={provider}
            onChange={() => setProvider(!provider)}
          />
        </div>
        {issuer ? (
          <>
            <div style={{ marginTop: 30 }}>
              <h2 style={{ marginRight: -300 }}>Produtos</h2>
              <input
                style={{ marginRight: -232 }}
                type="checkbox"
                checked={products}
                onChange={() => setProducts(!products)}
              />
              {products ? (
                <div>
                  <h2>Split de produto em %</h2>
                  <Input
                    style={{ marginLeft: 60 }}
                    name="splitproduct"
                    number
                  />
                </div>
              ) : null}
            </div>
            <div>
              <h2 style={{ marginRight: -278 }}>Cartão</h2>
              <input
                style={{ marginRight: -232 }}
                type="checkbox"
                checked={card}
                onChange={() => setCard(!card)}
              />
              {card ? (
                <div>
                  <h2>Split de cartão em %</h2>
                  <Input style={{ marginLeft: 72 }} name="splitcard" number />
                </div>
              ) : null}
            </div>
            <div>
              <h2 style={{ marginRight: -349 }}>Agendamento</h2>
              <input
                style={{ marginRight: -232 }}
                type="checkbox"
                checked={appointments}
                onChange={() => setAppointments(!appointments)}
              />
              {appointments ? (
                <div>
                  <h2>Split de agendamento em %</h2>
                  <Input name="split" number />
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        <button type="submit">Atualizar perfil</button>
      </Form>
    </Container>
  );
}
