export function addToCartRequest(datas) {
  return {
    type: '@paymantCardProduct/ADD_REQUEST',
    datas,
  };
}

export function addToCartSuccess(product) {
  return {
    type: '@paymantCardProduct/ADD_SUCCESS',
    product,
  };
}

export function removeFromCart(id) {
  return {
    type: '@paymantCardProduct/REMOVE',
    id,
  };
}
