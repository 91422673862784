import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'react-calendar';
import moment from 'moment';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
} from 'mdbreact';
import {
  Container,
  Time,
  Time2,
  ContainerModal,
  ContainerCalendar,
  ConfirmationButton,
} from './styles';
import api from '../../services/api';
import { addToCartRequest } from '../../store/modules/paymantCard/actions';
import 'react-calendar/dist/Calendar.css';

function ShowAppointments() {
  const profile = useSelector((state) => state.user.profile);
  const [appointments, setAppointments] = useState([]);
  const [value, onChange] = useState(new Date());
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState([]);
  const [infoCard, setInfoCard] = useState([]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadAppointments() {
      const response = await api.get('allAppointmentsAdmin');
      let datas2 = [];
      if (profile.receptionist) {
        datas2 = await response.data.filter((info) => {
          return info.service.created.id === profile.created_id;
        });
      } else {
        datas2 = await response.data.filter((info) => {
          return info.service.created.id === profile.id;
        });
      }
      const datas4 = await datas2.filter((info) => {
        return (
          moment(info.date).format('DD/MM/YYYY') ===
          moment(value).format('DD/MM/YYYY')
        );
      });
      const reduced = [];
      datas4.forEach((item) => {
        const duplicated =
          reduced.findIndex((redItem) => {
            return item.user.name === redItem.user.name;
          }) > -1;

        if (!duplicated) {
          reduced.push(item);
        }
      });
      setAppointments(reduced);
    }
    loadAppointments();
  }, [value]);

  async function enviar(id) {
    setLoading(true);
    const response = await api.get('allAppointmentsAdmin');

    const datas = await response.data.filter((info) => {
      return info.user.id === id;
    });
    let datas2 = [];
    if (profile.receptionist) {
      datas2 = await datas.filter((info) => {
        return info.service.created.id === profile.created_id;
      });
    } else {
      datas2 = await datas.filter((info) => {
        return info.service.created.id === profile.id;
      });
    }

    const datas4 = await datas2.filter((info) => {
      return (
        moment(info.date).format('DD/MM/YYYY') ===
        moment(value).format('DD/MM/YYYY')
      );
    });
    const response2 = await api.get(`cardPayment/${id}`);
    const datas5 = await response2.data.filter((info) => {
      return info.payed_at === null;
    });

    dispatch(addToCartRequest({ datas4, datas5 }));

    setLoading(false);
  }
  async function enviar2(id) {
    setLoading(true);
    const response = await api.get('allAppointmentsAdmin');

    const datas = await response.data.filter((info) => {
      return info.user.id === id;
    });
    let datas2 = [];
    if (profile.receptionist) {
      datas2 = await datas.filter((info) => {
        return info.service.created.id === profile.created_id;
      });
    } else {
      datas2 = await datas.filter((info) => {
        return info.service.created.id === profile.id;
      });
    }

    const datas4 = await datas2.filter((info) => {
      return (
        moment(info.date).format('DD/MM/YYYY') ===
        moment(value).format('DD/MM/YYYY')
      );
    });

    dispatch(addToCartRequest({ datas4, datas5: [] }));

    setLoading(false);
  }
  async function changeModal(id) {
    setLoading(true);
    const response = await api.get('allAppointmentsAdmin');

    const datas = await response.data.filter((infos) => {
      return infos.user.id === id;
    });
    let datas2 = [];
    if (profile.receptionist) {
      datas2 = await datas.filter((infos) => {
        return infos.service.created.id === profile.created_id;
      });
    } else {
      datas2 = await datas.filter((infos) => {
        return infos.service.created.id === profile.id;
      });
    }
    // const datas3 = await datas2.filter((info) => {
    //   return info.payed === false;
    // });
    const datas4 = await datas2.filter((infos) => {
      return (
        moment(infos.date).format('DD/MM/YYYY') ===
        moment(value).format('DD/MM/YYYY')
      );
    });

    setInfo(datas4);
    setLoading(false);
    setModal(true);
  }
  return (
    <>
      <ContainerModal>
        <MDBModal isOpen={modal}>
          <MDBModalHeader>Informações</MDBModalHeader>
          <MDBModalBody>
            <div style={{ flexDirection: 'row', height: 200 }}>
              <ul>
                {info.map((appointment) => (
                  <div style={{ flexDirection: 'row' }}>
                    <Time2 key={appointment.id}>
                      <div>
                        <div style={{ flexDirection: 'column' }}>
                          <strong>{appointment.user.name}</strong>
                          <span>C.P.F: {appointment.user.cpf}</span>
                          <span>
                            Procedimento: {appointment.service.procedure}
                          </span>
                          <span>Local: {appointment.service.created.name}</span>
                          <span>
                            Data:{' '}
                            {moment(appointment.date).format('DD/MM/YYYY')}
                          </span>
                        </div>
                      </div>
                    </Time2>
                  </div>
                ))}
              </ul>
            </div>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={() => setModal(false)}>
              {loading ? (
                <>
                  <MDBIcon icon="spinner" spin size="2x" fixed />
                  <span className="sr-only">Carregando...</span>
                </>
              ) : (
                'Fechar'
              )}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </ContainerModal>
      <Container>
        <h1>Buscar Agendamentos</h1>

        <ContainerCalendar>
          <Calendar onChange={onChange} value={value} />
        </ContainerCalendar>
        <ul>
          {appointments.map((appointment) => (
            <div style={{ flexDirection: 'row' }}>
              <Time
                key={appointment.id}
                past={appointment.payed}
                available={appointment.payed}
              >
                <div>
                  <div style={{ flexDirection: 'column' }}>
                    <strong>{appointment.user.name}</strong>
                    <span>C.P.F: {appointment.user.cpf}</span>
                  </div>

                  <div
                    style={{
                      width: 150,
                      borderRadius: 5,
                      height: 40,
                      marginTop: 5,
                      marginLeft: '30%',
                      background: '#4285F4',
                      alignSelf: 'flex-end',
                      justifyContent: 'center',
                    }}
                  >
                    <ConfirmationButton
                      disabled={appointment.payed}
                      onClick={() => enviar(appointment.user.id)}
                    >
                      Pagar agendamento com cartão
                    </ConfirmationButton>
                  </div>
                </div>
                <div
                  style={{
                    width: 150,
                    borderRadius: 5,
                    height: 40,
                    marginTop: 5,
                    marginLeft: '65%',
                    background: '#4285F4',
                    alignSelf: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  <ConfirmationButton
                    disabled={appointment.payed}
                    onClick={() => enviar2(appointment.user.id)}
                  >
                    Pagar somente agendamento
                  </ConfirmationButton>
                </div>
                <div
                  style={{
                    width: 150,
                    borderRadius: 5,
                    height: 40,
                    marginTop: 5,
                    marginLeft: '65%',
                    background: '#4285F4',
                    alignSelf: 'flex-end',
                    justifyContent: 'center',
                  }}
                >
                  <ConfirmationButton
                    onClick={() => changeModal(appointment.user.id)}
                  >
                    Informações
                  </ConfirmationButton>
                </div>
              </Time>
            </div>
          ))}
        </ul>
      </Container>
    </>
  );
}

export default ShowAppointments;
