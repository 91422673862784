import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;
export const Container = styled.div`
  animation: ${fade} 1s;
`;
export const Button = styled.button`
  margin: 3px 0 0;
  height: 44px;
  width: 100%;
  background: #0b7269;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.03, '#0B7269')};
  }
`;
