import styled, { keyframes } from 'styled-components';

const fade = keyframes`{
    from { 
      opacity: 0;
      transform: translateY(-50px); 
      
      }
    to { 
      opacity: 1;
      transform: translateY(0px);  
    } 
}`;
export const Container = styled.div`
  animation: ${fade} 1s;
  overflow-x: scroll;

  button {
    background: transparent;
    color: #515a63;
    border-width: 0;
    border-color: transparent;
    font-size: 14px;
    margin-bottom: 10px;
    align-self: flex-start;
  }
`;

export const ContainerAnimation = styled.div`
  animation: ${fade} 0.5s;
  margin-left: 15px;
  margin-bottom: 15px;
`;
