import React from 'react';
import { MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBRow } from 'mdbreact';
import { Bar, Line } from 'react-chartjs-2';

export default function ChartSection1({ dataBar, dataPie }) {
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          barPercentage: 1,
          gridLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            color: 'rgba(0, 0, 0, 0.1)',
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <MDBRow className="mb-4">
      <MDBCol md="5" className="mb-4">
        <MDBCard className="mb-4">
          <MDBCardHeader>Rendimento</MDBCardHeader>
          <MDBCardBody>
            <Line data={dataPie} height={200} />
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol md="7" className="mb-4">
        <MDBCard className="mb-4">
          <MDBCardBody>
            <Bar data={dataBar} height={500} options={barChartOptions} />
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  );
}
