import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;
export const Container = styled.div`
  max-width: 80%;
  margin-left: 20%;
  margin-right: 5%;
  animation: ${fade} 1s;
  h1 {
    margin-top: 2%;
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
    font-weight: 400;
  }
  strong {
    color: #0b7269;
    font-size: 18px;
  }
  h4 {
    font-size: 18px;
  }
`;

export const Button = styled.button`
  margin: 3px 0 0;
  height: 40px;
  width: 100px;
  background: #4285f4;
  font-weight: bold;
  align-self: flex-end;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.03, '#4285F4')};
  }
`;
