import React, { useState, useEffect } from 'react';
import {
  MDBDataTable,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
import { Container, Button } from './styles';
import api from '../../services/api';

function ShowSeller() {
  const [usersFranchisee, setUsersFranchisee] = useState([]);
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState([]);
  const [reload, setReload] = useState(false);

  async function changeModal(id) {
    setModal(!modal);

    const response = await api.get(`/address/${id}`);
    setInfo(response.data[0]);
  }

  async function deactivateUser(deactivate, email) {
    await api.put('userseller', {
      deactivate: !deactivate,
      email,
    });
    setReload(!reload);
  }
  useEffect(() => {
    async function Appointments() {
      const response = await api.get('usersSeller');

      setUsersFranchisee({
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 80,
          },
          {
            label: 'Nome',
            field: 'name',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Cpf',
            field: 'cpf',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Rg',
            field: 'rg',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Telefone',
            field: 'phone',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Endereço',
            field: 'action',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Desativar',
            field: 'deactivate',
            sort: 'asc',
            width: 200,
          },
        ],
        rows: response.data.map((events) => ({
          ...events,
          action: (
            <Button onClick={() => changeModal(events.id)}>Endereço</Button>
          ),
          deactivate: (
            <>
              {events.deactivate ? (
                <Button
                  onClick={() =>
                    deactivateUser(events.deactivate, events.email)
                  }
                >
                  Ativar
                </Button>
              ) : (
                <Button
                  onClick={() =>
                    deactivateUser(events.deactivate, events.email)
                  }
                >
                  Desativar
                </Button>
              )}
            </>
          ),
        })),
      });
    }
    Appointments();
  }, [reload]);

  return (
    <Container>
      <h1>Buscar Emitentes</h1>
      <MDBModal isOpen={modal} toggle={changeModal}>
        <MDBModalHeader toggle={changeModal}>
          <h1>Endereço</h1>
        </MDBModalHeader>
        <MDBModalBody>
          <h4>
            <strong>Rua:</strong> {info.address}
          </h4>
          <h4>
            <strong>Cep:</strong> {info.cep}
          </h4>
          <h4>
            <strong>Bairro:</strong> {info.neighborhood}
          </h4>
          <h4>
            <strong>Numero:</strong> {info.number}
          </h4>
          <h4>
            <strong>Cidade:</strong> {info.city}
          </h4>
          <h4>
            <strong>Estado:</strong> {info.state}
          </h4>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={() => changeModal()}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <MDBDataTable
        striped
        responsive
        entries={10}
        maxHeight={500}
        scrollX
        data={usersFranchisee}
      />
    </Container>
  );
}

export default ShowSeller;
