import styled from 'styled-components';
import { darken } from 'polished';
import Input from 'react-input-mask';

export const Payment = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  grid-template-areas:
    'header header'
    'header header'
    'main sidebar';
  .cards {
    grid-area: header;
  }
  .save-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
    font-size: 13px;
    color: #ffffff;
    input {
      margin-right: 5px;
    }
  }
  .form-area {
    grid-area: main;
    & > label,
    div.group label {
      display: block;
      color: #87868b;
      font-size: 14px;
      font-weight: bold;
    }
    & > input,
    div.group input {
      width: 100%;
      background: #fff;
      border: 2px solid #25242c;
      border-radius: 5px;
      padding: 15px 21px;
      margin-top: 8px;
      font-size: 14px;
      margin-bottom: 15px;
      color: #000;
    }
    .group {
      width: 100%;
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 20px;
      div {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .credit-card {
    display: flex;
    align-items: center;
    grid-area: sidebar;
  }
`;
export const InputSmall = styled(Input)`
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #0b7269;
  border-radius: 4px;
  height: 25px;
  width: 25px;
  color: #0b7269;
  margin: 0 0 10px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  &::placeholder {
    color: rgba(255, 255, 255, 1);
  }
`;
export const Container = styled.div`
  padding: 30px;
  border-radius: 4px;
  margin-left: 350px;
  margin-right: 200px;
  margin-top: 100px;
  background: transparent;
  h1 {
    margin-top: 2%;
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    button {
      padding: 12px 20px;
      border: 0;
      border-radius: 4px;
      color: #fff;
      background: #0b7269;
      font-weight: bold;
      text-transform: uppercase;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, '#0b7269')};
      }
    }
  }
`;

export const ProductTable = styled.div`
  overflow: auto;
  height: 300px;
  table {
    width: 100%;
  }
  thead th {
    padding: 12px;
    color: #999;
    text-align: left;
  }
  tbody td {
    padding: 12px;
    border-bottom: 1px solid #eee;
  }
  img {
    height: 100px;
  }
  strong {
    display: block;
    color: #333;
  }
  span {
    display: block;
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
  }
  div {
    display: flex;
    align-items: center;
    input {
      width: 50px;
      padding: 6px;
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #666;
    }
  }
  button {
    padding: 6px;
    border: 0;
    background: none;
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: baseline;
  span {
    color: #999;
    font-weight: bold;
  }
  strong {
    margin-left: 5px;
    font-size: 28px;
  }
`;
