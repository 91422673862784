import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import cart from './cart/sagas';
import paymantcart from './paymantCard/sagas';
import cartProduct from './cartProduct/sagas';
import paymantCardProduct from './paymantCardProduct/sagas';
import updatesProfile from './updatesProfile/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    user,
    cart,
    paymantcart,
    cartProduct,
    paymantCardProduct,
    updatesProfile,
  ]);
}
