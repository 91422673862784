export default function cart(state = [], action) {
  switch (action.type) {
    case '@cartpayment/ADD_SUCCESS':
      return [action.product];
    case '@cartpayment/REMOVE':
      return state.filter((product) => product.id !== action.id);
    case '@cartpayment/UPDATE_AMOUNT_SUCCESS':
      return state.map((item) =>
        item.id === action.id ? { ...item, amount: action.amount } : item
      );
    default:
      return state;
  }
}
