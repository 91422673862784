import React, { useState, useEffect } from 'react';
import ceps from 'cep-promise';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import {
  Container,
  Content,
  InputBig,
  InputSmall,
  Button,
  RegisterButton,
} from './styles';
import api from '../../services/api';

import useForm from '../../utils/useForm';

export default function AddReceptionist() {
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [loading, setLoading] = useState(false);
  const [allIssuer, setAllIssuer] = useState([]);

  const [cep, setCep] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [find, setFind] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [issuer, setIssuer] = useState([]);
  const profile = useSelector((s) => s.user.profile);

  useEffect(() => {
    async function loadIssuer() {
      const response = await api.get('providers');
      setAllIssuer(response.data);
    }
    if (profile.franchisee) {
      loadIssuer();
    }
  }, []);
  async function enviar() {
    if (find === true) {
      if (
        values.name &&
        values.email &&
        values.password &&
        values.rg &&
        values.cpf &&
        values.phone &&
        cep &&
        values.number &&
        neighborhood &&
        values.born &&
        address &&
        city &&
        state !== ''
      ) {
        setLoading(true);
        const response = await api.post('usersRecepcionist', {
          id: profile.franchisee ? issuer : profile.id,
          email: values.email,
          name: values.name,
          password: values.password,
          born: values.born,
          rg: values.rg,
          cpf: values.cpf,
          phone: values.phone,
        });
        if (response.status === 200) {
          const response2 = await api.post('address', {
            email: values.email,
            cep,
            number: Number(values.number),
            neighborhood,
            address,
            city,
            state,
          });
          if (response2.status === 200) {
            toast.success('Usuario criado com sucesso.');
            setLoading(false);
          } else if (response2.status === 203) {
            toast.error(
              'Erro ao fazer o cadastramento do usuario, verifque os dados ou você não possui permissão.'
            );
            setLoading(false);
          } else if (response.status === 203) {
            toast.error(
              'Erro ao fazer o cadastramento do usuario, verifque os dados ou você não possui permissão.'
            );
            setLoading(false);
          }
        } else if (response.status === 203) {
          setLoading(false);
          toast.error(
            'Erro ao fazer o cadastramento do usuario, verifque os dados ou você não possui permissão.'
          );
        }
      } else {
        toast.error('Digite todos os dados!');
      }
    } else {
      toast.error('Valide o cep!');
    }
  }

  function checkCep() {
    setLoading2(true);
    ceps(cep)
      .then((res) => {
        setLoading2(false);
        setFind(true);
        setCity(res.city);
        setAddress(res.street);
        setNeighborhood(res.neighborhood);
        setState(res.state);
      })
      .catch(() => {
        setLoading2(false);
        setFind(false);
        toast.error('Cep invalido!');
      });
  }

  function handleChangeIssuer(e) {
    setIssuer(e);
  }
  return (
    <Container>
      <Content>
        <h1>Cadastrar recepcionista</h1>
        <form onSubmit={handleSubmit(enviar)}>
          {profile.franchisee ? (
            <>
              <h2>Prestador</h2>
              <select
                style={{ width: '70%' }}
                onChange={(e) => handleChangeIssuer(e.currentTarget.value)}
                name="Pacient"
              >
                <option value={1}>Escolha o prestador</option>
                {allIssuer.map(function (object) {
                  return <option value={object.id}>{object.name}</option>;
                })}
              </select>
            </>
          ) : null}

          <h2>Nome</h2>
          <InputBig onChange={handleChange} name="name" type="text" />
          <h2>E-mail</h2>
          <InputBig
            onChange={handleChange}
            name="email"
            autocapitalize="off"
            type="email"
          />
          <h2>RG</h2>
          <InputBig onChange={handleChange} name="rg" mask="99.999.999-99" />
          <h2>Senha</h2>
          <InputBig onChange={handleChange} name="password" type="password" />
          <h2>CPF</h2>
          <InputBig onChange={handleChange} name="cpf" mask="999.999.999-99" />
          <h2>Data de nascimento</h2>
          <InputBig
            onChange={handleChange}
            name="born"
            maskPlaceholder="dd/mm/yyyy"
            mask="99/99/9999"
          />
          <h2>Telefone</h2>
          <div>
            <InputBig onChange={handleChange} name="phone" maxlength="11" />
          </div>
          <h2>CEP</h2>
          <div>
            <div>
              <InputBig
                onChange={(e) => setCep(e.target.value)}
                value={cep}
                name="cep"
                mask="99999-999"
              />
              {loading2 ? (
                <Button type="button">
                  <MDBIcon icon="compass" spin size="1x" fixed />
                  <span className="sr-only">Carregando...</span>
                </Button>
              ) : (
                <Button type="button" onClick={checkCep}>
                  <MDBIcon icon="compass" size="1x" fixed />
                </Button>
              )}
            </div>
            <h2>Número</h2>
            <div>
              <InputSmall onChange={handleChange} name="number" type="number" />
            </div>
          </div>
          <h2>Bairro</h2>
          <InputBig
            onChange={(e) => setNeighborhood(e.target.value)}
            value={neighborhood}
            name="neighborhood"
            type="text"
          />
          <h2>Rua</h2>
          <div>
            <InputBig
              onChange={(e) => setAddress(e.target.value)}
              value={address}
              name="address"
              type="text"
            />
          </div>
          <h2>Cidade</h2>
          <div>
            <div>
              <InputBig
                onChange={(e) => setCity(e.target.value)}
                value={city}
                name="city"
                type="text"
              />
            </div>
            <h2>Estado</h2>
            <div>
              <InputSmall
                onChange={(e) => setState(e.target.value)}
                value={state}
                name="state"
                type="text"
                maxlength="2"
              />
            </div>
          </div>
          <RegisterButton type="submit">
            {loading ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Cadastrar'
            )}
          </RegisterButton>
        </form>
      </Content>
    </Container>
  );
}
