import styled, { keyframes } from 'styled-components';

const fade = keyframes`{
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;
export const Container = styled.div`
  margin-left: 350px;
  width: 70%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-self: center;
  flex-direction: column;
  animation: ${fade} 1s;

  @media (min-width: 1000px) {
    height: 100%;
  }
  h1 {
    margin-top: 5%;
    margin-bottom: 3%;
    font: 40px 'Bebas Neue', sans-serif;
    color: #0b7269;
  }
`;
