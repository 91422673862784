import React, { useState, useEffect } from 'react';

import {
  MDBIcon,
  MDBDatePicker,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBBtn,
} from 'mdbreact';
import { toast } from 'react-toastify';
import { Form, Input, Scope } from '@rocketseat/unform';
import 'react-credit-cards/es/styles-compiled.css';
import Cards from 'react-credit-cards';
import publicIp from 'public-ip';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import api from '../../services/api';
import {
  Container,
  Content,
  RegisterButton,
  ContentTwo,
  Payment,
} from './styles';
import history from '../../services/history';

export default function ValidationCardPayed() {
  const [users, setUsers] = useState([]);
  const [adress, setAdress] = useState([]);
  const [modal, setModal] = useState(false);
  const [infoCard, SetInfoCard] = useState([]);
  const [email, setEmail] = useState('');
  const [debit, setDebit] = useState(true);
  const [credit, setCredit] = useState(false);
  const [payed_at, setPayed_at] = useState(new Date());
  const [card, setCard] = useState({
    holder_name: '',
    number: '',
    expiration_date: '',
    cvv: '',
    id: '',
    share: '',
  });
  const [loading, setLoading] = useState(false);
  const profileUser = useSelector((s) => s.user.profile);

  useEffect(() => {
    async function getUsers() {
      const response = await api.get('paymentCardSellerShow');
      setUsers(response.data);
    }

    getUsers();
  }, []);
  async function handleChangeEmail(e) {
    setEmail(e);
    const data = await users.filter((info) => {
      return info.user.email === e;
    });
    const response = await api.get(`cardPayment/${data[0].user.id}`);
    SetInfoCard(response.data[0]);
    if (response.data.length === 0) {
      toast.error('Usuário não possui cartão para realizar o pagamento');
    } else {
      const response2 = await api.get(`address/${response.data[0].user.id}`);
      setAdress(response2.data);
    }
  }

  function handleChangePayedAt(e) {
    setPayed_at(e);
  }
  function handleChangeCard(e) {
    const { name } = e.target;
    const { value } = e.target;

    setCard({ ...card, [name]: value, id: '' });
  }
  async function handleUpload(money) {
    setLoading(true);
    if ((email && payed_at) !== '') {
      const value = infoCard.card.price;
      const valueseller = value * (infoCard.seller.splitcard / 100);
      const valueemitp = value * (infoCard.seller.created.splitcard / 100);
      const valuefran =
        value * (infoCard.seller.created.created.splitcard / 100);
      const valueadmin = value - valueseller - valueemitp - valuefran;
      const ip = await publicIp.v4();
      const chave = `IDC${infoCard.user.id}IDV${infoCard.seller.id}IDE${
        infoCard.seller.created.id
      }IDC${infoCard.card.id}T${Math.floor(Math.random() * 100000)}`;
      const data = {
        chaveERP: chave,
        valor: (
          parseFloat(valueseller.toFixed(2)) +
          parseFloat(valueemitp.toFixed(2)) +
          parseFloat(valuefran.toFixed(2)) +
          parseFloat(valueadmin.toFixed(2))
        ).toFixed(2),
        numeroParcela: Number(card.share),
        CardToken: null,
        Brand: null,
        nome: infoCard.user.name,
        identificador: infoCard.user.cpf,
        email: infoCard.user.email,
        telefone: infoCard.user.phone,
        uf: adress[0].state,
        cidade: adress[0].city,
        logradouro: adress[0].address,
        bairro: adress[0].neighborhood,
        numero: adress[0].number,
        cep: adress[0].cep,
        complemento: null,
        permitePagamentoCartao: Number(credit),
        permitePagamentoDebito: Number(debit),
        permitePagamentoBoleto: 0,
        cartaonome: card.holder_name,
        cartaonumero: card.number,
        cartaovencimento: card.expiration_date,
        cartaocodigoseguranca: card.cvv,
        TokenizaCartao: 0,
        listaDivisao: [
          {
            identificador: infoCard.seller.cpf,
            valorDivisao: valueseller.toFixed(2),
            emiteNotaOurecibo: 1,
          },
          {
            identificador: infoCard.seller.created.cnpj,
            valorDivisao: valueemitp.toFixed(2),
            emiteNotaOurecibo: 1,
          },
          {
            identificador: infoCard.seller.created.created.cnpj,
            valorDivisao: valuefran.toFixed(2),
            emiteNotaOurecibo: 1,
          },
          {
            identificador: infoCard.seller.created.created.created.cnpj,
            valorDivisao: valueadmin.toFixed(2),
            emiteNotaOurecibo: 1,
          },
        ],
        ipPagamento: ip,
      };
      if (money === true) {
        const response = { data: { Pago: true }, status: 200 };
        if (response.status === 200) {
          if (response.data.Pago === false) {
            toast.error('Erro ao fazer pagamento, verifque os dados.');
            setLoading(false);
          } else {
            const response2 = await api.put('cardPayment', {
              email,
              payed_at,
            });
            if (response2.status === 200) {
              toast.success('Pagamento realizado com sucesso.');
              const response3 = await api.post('fatureCard', {
                seller_id: infoCard.seller.id,
                emitp_id: infoCard.seller.created.id,
                fran_id: infoCard.seller.created.created.id,
                admin_id: infoCard.seller.created.created.created.id,
                card_id: infoCard.card.id,
                user_id: infoCard.user.id,
                chaveERP: data.chaveERP,
                receptionist_id: profileUser.id,
                money: true,
                fature_id:
                  profileUser.seller || profileUser.receptionist
                    ? profileUser.created_id
                    : profileUser.id,
              });

              if (response3.status === 200) {
                toast.success('Cartão ativado com sucesso.');
                history.push('/createCardLink');

                setLoading(false);
              } else {
                toast.error(
                  'Erro ao ativar cartão, usuario logado não é um vendedor ou usuario não tem cartão'
                );
                setLoading(false);
              }
            } else {
              toast.error('Erro ao fazer pagamento, verifque os dados.');
              setLoading(false);
            }
          }
        } else {
          toast.error('Erro ao fazer pagamento, tente novamente.');
          setLoading(false);
        }
      } else {
        const response = await api.post('makePaymentController', { data });
        // const response = { data: { Pago: false } };
        if (response.status === 200) {
          if (response.data.Pago === false) {
            toast.error('Erro ao fazer pagamento, verifque os dados.');
            setLoading(false);
          } else {
            const response2 = await api.put('cardPayment', {
              email,
              payed_at,
            });
            if (response2.status === 200) {
              toast.success('Pagamento realizado com sucesso.');

              const response3 = await api.post('fatureCard', {
                seller_id: infoCard.seller.id,
                emitp_id: infoCard.seller.created.id,
                fran_id: infoCard.seller.created.created.id,
                admin_id: infoCard.seller.created.created.created.id,
                card_id: infoCard.card.id,
                user_id: infoCard.user.id,
                chaveERP: data.chaveERP,
                receptionist_id: profileUser.id,
                money: false,
                fature_id:
                  profileUser.seller || profileUser.receptionist
                    ? profileUser.created_id
                    : profileUser.id,
              });

              if (response3.status === 200) {
                toast.success('Cartão ativado com sucesso.');
                history.push('/createCardLink');

                setLoading(false);
              } else {
                toast.error(
                  'Erro ao ativar cartão, usuario logado não é um vendedor ou usuario não tem cartão'
                );
                setLoading(false);
              }
            } else {
              toast.error('Erro ao fazer pagamento, verifque os dados.');
              setLoading(false);
            }
          }
        } else {
          toast.error('Erro ao fazer pagamento, tente novamente.');
          setLoading(false);
        }
      }
    } else {
      toast.error('Erro ao fazer pagamento, preencha todos os dados.');
      setLoading(false);
    }
  }

  async function changeModal() {
    setModal(!modal);
  }
  return (
    <Container>
      <MDBModal size="lg" isOpen={modal} toggle={changeModal}>
        <MDBModalHeader toggle={changeModal}>Dados bancários</MDBModalHeader>
        <MDBModalBody>
          <Form onSubmit={handleUpload}>
            <Payment>
              <div className="form-area">
                <Scope path="card">
                  <h6>Nome no cartão</h6>
                  <InputMask
                    name="holder_name"
                    label="Nome no cartão"
                    onChange={handleChangeCard}
                  />
                  <h6>Número do cartão</h6>
                  <InputMask
                    name="number"
                    label="Número do cartão"
                    onChange={handleChangeCard}
                  />
                  <div className="group">
                    <div>
                      <Input
                        label="debito"
                        style={{ height: 20, width: 20 }}
                        type="checkbox"
                        checked={debit}
                        onChange={() => {
                          setDebit(!debit);
                          setCredit(!credit);
                        }}
                      />
                    </div>
                    <div>
                      <Input
                        label="credito"
                        style={{ height: 20, width: 20 }}
                        type="checkbox"
                        checked={credit}
                        onChange={() => {
                          setDebit(!debit);
                          setCredit(!credit);
                        }}
                      />
                    </div>
                  </div>
                  <div className="group">
                    <div>
                      <h6>Data de expiração</h6>
                      <InputMask
                        name="expiration_date"
                        mask="99/99"
                        label="Data de expiração"
                        onChange={handleChangeCard}
                      />
                    </div>
                    <div>
                      <h6>Código de segurança</h6>
                      <InputMask
                        name="cvv"
                        label="Código de segurança"
                        onChange={handleChangeCard}
                      />
                    </div>
                    <div>
                      <h6>Número de parcelas</h6>
                      <InputMask
                        name="share"
                        type="number"
                        label="Número de parcelas"
                        onChange={handleChangeCard}
                      />
                    </div>
                  </div>
                </Scope>
              </div>
              <div className="credit-card">
                <Cards
                  number={card.number}
                  name={card.holder_name}
                  expiry={card.expiration_date}
                  cvc={card.cvv}
                  focused="number"
                />
              </div>
            </Payment>
            <MDBBtn type="submit">
              {loading ? (
                <>
                  <MDBIcon icon="spinner" spin size="2x" fixed />
                  <span className="sr-only">Carregando...</span>
                </>
              ) : (
                'Enviar'
              )}
            </MDBBtn>
          </Form>
        </MDBModalBody>
      </MDBModal>
      <form style={{ alignSelf: 'center', margin: 50 }}>
        <Content>
          <h1>Validar cartão do cliente</h1>
          <div>
            <h2>Paciente</h2>
            <select
              onChange={(e) => handleChangeEmail(e.currentTarget.value)}
              name="Pacient"
            >
              <option value={1}>Escolha o nome do cliente</option>
              {users.map(function (object) {
                return (
                  <option value={object.user.email}>{object.user.name}</option>
                );
              })}
            </select>
          </div>
        </Content>
        <ContentTwo>
          <h2>Data</h2>
          <div>
            <MDBDatePicker
              disablePast
              format="DD/MM/YYYY"
              getValue={(e) => handleChangePayedAt(e)}
            />
          </div>
        </ContentTwo>
        <Content>
          <RegisterButton type="button" onClick={() => changeModal()}>
            {loading ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Pagar com cartão (Crédito ou Débito)'
            )}
          </RegisterButton>
          <RegisterButton
            type="button"
            onClick={() => {
              handleUpload(true);
            }}
          >
            {loading ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Pagar em dinheiro'
            )}
          </RegisterButton>
        </Content>
      </form>
    </Container>
  );
}
