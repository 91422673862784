import React, { useState, useEffect } from 'react';
import {
  MDBDataTable,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
} from 'mdbreact';
import { useSelector } from 'react-redux';
import moment from 'moment';
import JsPDF from 'jspdf';
import { Container, Button } from './styles';
import api from '../../services/api';
import 'jspdf-autotable';

function ShowCards() {
  const [card, setCard] = useState([]);
  const [body, setBody] = useState([]);
  const [columns, setColumns] = useState([]);
  const [modal, setModal] = useState(false);
  const [info, setInfo] = useState([]);
  const profile = useSelector((state) => state.user.profile);

  async function changeModal(ChaveERP) {
    setModal(!modal);

    const response = await api.post('statusPaymantController', {
      data: ChaveERP,
    });
    setInfo(response.data);
  }
  useEffect(() => {
    async function loadCards() {
      const response = await api.get('fature');
      if (profile.provider && !profile.issuer) {
        const datas = await response.data.filter((info) => {
          return info.emit.id === profile.id;
        });
        setCard({
          columns: [
            {
              label: 'id',
              field: 'id',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'ChaveERP',
              field: 'chaveerp',
              sort: 'asc',
              width: 190,
            },
            {
              label: 'Valor Prestador',
              field: 'valueemit',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Data',
              field: 'date',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Status',
              field: 'status',
              sort: 'asc',
              width: 100,
            },
          ],
          rows: datas.map((events) => ({
            ...events,
            date: moment(events.date).format('DD/MM/YYYY'),
            status: (
              <Button onClick={() => changeModal(events.chaveerp)}>Info</Button>
            ),
          })),
        });
        const bodys = datas.map((product) => ({
          id: product.id,
          chaveerp: product.chaveerp,
          valueemit: `R$: ${product.valueemit}`,
          date: moment(product.date).format('DD/MM/YYYY'),
        }));
        const columnss = [
          { header: 'ID', dataKey: 'id' },
          { header: 'ChaveERP', dataKey: 'chaveerp' },
          { header: 'Valor Prestador', dataKey: 'valueemit' },
          { header: 'Data', dataKey: 'date' },
        ];
        setBody(bodys);
        setColumns(columnss);
      }
      if (!profile.provider && profile.issuer) {
        const datas = await response.data.filter((info) => {
          return info.emitp.id === profile.id;
        });
        setCard({
          columns: [
            {
              label: 'id',
              field: 'id',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'ChaveERP',
              field: 'chaveerp',
              sort: 'asc',
              width: 190,
            },
            {
              label: 'Valor Emitente',
              field: 'valueemit',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Vendedor',
              field: 'valueseller',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Data',
              field: 'date',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Status',
              field: 'status',
              sort: 'asc',
              width: 100,
            },
          ],
          rows: datas.map((events) => ({
            ...events,
            date: moment(events.date).format('DD/MM/YYYY'),
            status: (
              <Button onClick={() => changeModal(events.chaveerp)}>Info</Button>
            ),
          })),
        });
        const bodys = datas.map((product) => ({
          id: product.id,
          chaveerp: product.chaveerp,
          valueseller: `R$: ${product.valueseller}`,
          valueemitp: `R$: ${product.valueemitp}`,
          date: moment(product.date).format('DD/MM/YYYY'),
        }));
        const columnss = [
          { header: 'ID', dataKey: 'id' },
          { header: 'ChaveERP', dataKey: 'chaveerp' },
          { header: 'Valor Vendedor', dataKey: 'valueseller' },
          { header: 'Valor Emitente', dataKey: 'valueemitp' },
          { header: 'Data', dataKey: 'date' },
        ];
        setBody(bodys);
        setColumns(columnss);
      }
      if (profile.provider && profile.issuer) {
        const datas = await response.data.filter((info) => {
          return info.emitp.id === profile.id;
        });
        setCard({
          columns: [
            {
              label: 'id',
              field: 'id',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'ChaveERP',
              field: 'chaveerp',
              sort: 'asc',
              width: 190,
            },
            {
              label: 'Valor Emitente',
              field: 'valueemitp',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Vendedor',
              field: 'valueseller',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Prestador',
              field: 'valueemit',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Data',
              field: 'date',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Status',
              field: 'status',
              sort: 'asc',
              width: 100,
            },
          ],
          rows: datas.map((events) => ({
            ...events,
            date: moment(events.date).format('DD/MM/YYYY'),
            status: (
              <Button onClick={() => changeModal(events.chaveerp)}>Info</Button>
            ),
          })),
        });
        const bodys = datas.map((product) => ({
          id: product.id,
          chaveerp: product.chaveerp,
          valueseller: `R$: ${product.valueseller}`,
          valueemitp: `R$: ${product.valueemitp}`,
          valueemit: `R$: ${product.valueemit}`,
          date: moment(product.date).format('DD/MM/YYYY'),
        }));
        const columnss = [
          { header: 'ID', dataKey: 'id' },
          { header: 'ChaveERP', dataKey: 'chaveerp' },
          { header: 'Valor Vendedor', dataKey: 'valueseller' },
          { header: 'Valor Emitente', dataKey: 'valueemitp' },
          { header: 'Valor Prestador', dataKey: 'valueemit' },
          { header: 'Data', dataKey: 'date' },
        ];
        setBody(bodys);
        setColumns(columnss);
      }
      if (profile.seller) {
        const datas = await response.data.filter((info) => {
          return info.seller.id === profile.id;
        });
        setCard({
          columns: [
            {
              label: 'id',
              field: 'id',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'ChaveERP',
              field: 'chaveerp',
              sort: 'asc',
              width: 190,
            },
            {
              label: 'Valor Vendedor',
              field: 'valueseller',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Data',
              field: 'date',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Status',
              field: 'status',
              sort: 'asc',
              width: 100,
            },
          ],
          rows: datas.map((events) => ({
            ...events,
            date: moment(events.date).format('DD/MM/YYYY'),
            status: (
              <Button onClick={() => changeModal(events.chaveerp)}>Info</Button>
            ),
          })),
        });
        const bodys = datas.map((product) => ({
          id: product.id,
          chaveerp: product.chaveerp,
          valueseller: `R$: ${product.valueseller}`,
          date: moment(product.date).format('DD/MM/YYYY'),
        }));
        const columnss = [
          { header: 'ID', dataKey: 'id' },
          { header: 'ChaveERP', dataKey: 'chaveerp' },
          { header: 'Valor Vendedor', dataKey: 'valueseller' },
          { header: 'Data', dataKey: 'date' },
        ];
        setBody(bodys);
        setColumns(columnss);
      }
      if (profile.franchisee) {
        const datas = await response.data.filter((info) => {
          return info.fran.id === profile.id;
        });
        setCard({
          columns: [
            {
              label: 'id',
              field: 'id',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'ChaveERP',
              field: 'chaveerp',
              sort: 'asc',
              width: 190,
            },
            {
              label: 'Valor Vendedor',
              field: 'valueseller',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Emitente',
              field: 'valueemitp',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Franqueado',
              field: 'valuefran',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Prestador',
              field: 'valueemit',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Data',
              field: 'date',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Status',
              field: 'status',
              sort: 'asc',
              width: 100,
            },
          ],
          rows: datas.map((events) => ({
            ...events,
            date: moment(events.date).format('DD/MM/YYYY'),
            status: (
              <Button onClick={() => changeModal(events.chaveerp)}>Info</Button>
            ),
          })),
        });
        const bodys = datas.map((product) => ({
          id: product.id,
          chaveerp: product.chaveerp,
          valueseller: `R$: ${product.valueseller}`,
          valueemitp: `R$: ${product.valueemitp}`,
          valueemit: `R$: ${product.valueemit}`,
          valuefran: `R$: ${product.valuefran}`,
          date: moment(product.date).format('DD/MM/YYYY'),
        }));
        const columnss = [
          { header: 'ID', dataKey: 'id' },
          { header: 'ChaveERP', dataKey: 'chaveerp' },
          { header: 'Valor Vendedor', dataKey: 'valueseller' },
          { header: 'Valor Emitente', dataKey: 'valueemitp' },
          { header: 'Valor Prestador', dataKey: 'valueemit' },
          { header: 'Valor Franqueado', dataKey: 'valuefran' },
          { header: 'Data', dataKey: 'date' },
        ];
        setBody(bodys);
        setColumns(columnss);
      }
      if (profile.admin) {
        const datas = await response.data.filter((info) => {
          return info.admin.id === profile.id;
        });
        setCard({
          columns: [
            {
              label: 'id',
              field: 'id',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'ChaveERP',
              field: 'chaveerp',
              sort: 'asc',
              width: 190,
            },
            {
              label: 'Valor Vendedor',
              field: 'valueseller',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Emitente',
              field: 'valueemitp',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Prestador',
              field: 'valueemit',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Franqueado',
              field: 'valuefran',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Valor Admin',
              field: 'valueadmin',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Data',
              field: 'date',
              sort: 'asc',
              width: 100,
            },
            {
              label: 'Status',
              field: 'status',
              sort: 'asc',
              width: 100,
            },
          ],
          rows: datas.map((events) => ({
            ...events,
            date: moment(events.date).format('DD/MM/YYYY'),
            status: (
              <Button onClick={() => changeModal(events.chaveerp)}>Info</Button>
            ),
          })),
        });
        const bodys = datas.map((product) => ({
          id: product.id,
          chaveerp: product.chaveerp,
          valueseller: `R$: ${product.valueseller}`,
          valueemitp: `R$: ${product.valueemitp}`,
          valueemit: `R$: ${product.valueemit}`,
          valuefran: `R$: ${product.valuefran}`,
          valueadmin: `R$: ${product.valueadmin}`,
          date: moment(product.date).format('DD/MM/YYYY'),
        }));
        const columnss = [
          { header: 'ID', dataKey: 'id' },
          { header: 'ChaveERP', dataKey: 'chaveerp' },
          { header: 'Valor Vendedor', dataKey: 'valueseller' },
          { header: 'Valor Emitente', dataKey: 'valueemitp' },
          { header: 'Valor Prestador', dataKey: 'valueemit' },
          { header: 'Valor Franqueado', dataKey: 'valuefran' },
          { header: 'Valor Admin', dataKey: 'valueadmin' },
          { header: 'Data', dataKey: 'date' },
        ];
        setBody(bodys);
        setColumns(columnss);
      }
    }

    loadCards();
  }, []);

  function createPdf() {
    const doc = new JsPDF('p', 'pt');
    doc.setFontSize(20);
    doc.setTextColor(40);
    doc.setFontStyle('normal');

    doc.autoTable(columns, body, {
      startY: doc.autoTableEndPosY() + 70,
      margin: { horizontal: 10 },
      styles: { overflow: 'linebreak' },
      bodyStyles: { valign: 'top' },
      columnStyles: { email: { columnWidth: 'wrap' } },
      theme: 'striped',
    });

    doc.save('Fature.pdf');
  }

  return (
    <Container>
      <h1>Buscar Faturamento</h1>
      <MDBModal isOpen={modal} toggle={changeModal}>
        <MDBModalHeader toggle={changeModal}>
          <h1>Informações</h1>
        </MDBModalHeader>
        <MDBModalBody>
          <h4>
            <strong>Numero do cartão: </strong>
            {info.numeroCartao}
          </h4>
          <h4>
            <strong>Nome: </strong>
            {info.solicitacao && info.solicitacao.nome}
          </h4>
          <h4>
            <strong>Status da Transação: </strong>
            {info.descricaoStatusTransacao}
          </h4>
          <h4>
            <strong>Tipo Pagamento: </strong>
            {info.tipoPagamento}
          </h4>
          <h4>
            <strong>Cod. Transação: </strong>
            {info.codTransacao}
          </h4>
          <h4>
            <strong>Valor Economia: </strong> {info.valorEconomia}
          </h4>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="primary" onClick={() => changeModal()}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <MDBDataTable
        striped
        responsive
        entries={10}
        maxHeight={500}
        scrollX
        data={card}
      />
      <Button onClick={() => createPdf(card.columns, card.rows)}>
        Gerar Pdf
      </Button>
    </Container>
  );
}

export default ShowCards;
