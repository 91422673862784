import React, { useState } from 'react';
import { MDBListGroup, MDBListGroupItem, MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/logo.png';
import { signOut } from '../../store/modules/auth/actions';
import { Container, ContainerAnimation } from './styles';

export default function TopNavigation() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [products, setProducts] = useState(false);
  const [franc, setFranc] = useState(false);
  const [cards, setCards] = useState(false);
  const [emit, setEmit] = useState(false);
  const [vend, setVend] = useState(false);
  const [serv, setServ] = useState(false);
  const [clie, setClie] = useState(false);
  const [pro, setPro] = useState(false);
  const [car, setCar] = useState(false);
  const [recep, setRecep] = useState(false);

  function handleSignOut() {
    dispatch(signOut());
  }
  function changeProducts() {
    setProducts(!products);
  }
  function changeRecep() {
    setRecep(!recep);
  }
  function changeFranc() {
    setFranc(!franc);
  }
  function changeCards() {
    setCards(!cards);
  }
  function changeEmit() {
    setEmit(!emit);
  }
  function changeVend() {
    setVend(!vend);
  }
  function changeServ() {
    setServ(!serv);
  }
  function changeClie() {
    setClie(!clie);
  }
  function changePro() {
    setPro(!pro);
  }
  function changeCar() {
    setCar(!car);
  }

  return (
    <Container
      style={{ position: 'fixed' }}
      className="sidebar-fixed position-fixed"
    >
      <a href="/" className="logo-wrapper waves-effect">
        <img
          alt="Logo"
          height="200"
          width="200"
          className="img-fluid"
          src={logo}
        />
      </a>
      <MDBListGroup className="list-group-flush">
        {!profile.receptionist ? (
          <NavLink
            to="/dashboard"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="chart-pie" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Dashboard
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}

        <NavLink
          to="/profile"
          activeClassName="activeClass"
          className="navLink"
        >
          <MDBListGroupItem className="mr-3">
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                height: 25,
              }}
            >
              <MDBIcon icon="user" className="mr-3" />
              <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                Profile
              </h5>
            </div>
          </MDBListGroupItem>
        </NavLink>

        {!profile.receptionist ? (
          <NavLink
            to="/fature"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="dollar-sign" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Faturamento
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {profile.provider || profile.receptionist ? (
          <NavLink
            to="/validationCardPayed"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="dollar-sign" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Realizar pagamento de cartão
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {profile.admin ? (
          <NavLink
            to="/calendar"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="dollar-sign" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Faturamento Calendário
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {profile.admin ? (
          <NavLink
            to="/dashboardMoney"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="dollar-sign" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Split dinheiro
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {!products ? (
          <>
            {profile.admin ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeProducts}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Produtos
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.admin ? (
              <button type="button" onClick={changeProducts}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Produtos
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}
        {products ? (
          <ContainerAnimation>
            {profile.admin ? (
              <NavLink
                style={{ marginLeft: 15 }}
                to="/addGeneralProducts"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="plus" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cadastrar produto
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.admin ? (
              <NavLink
                style={{ marginLeft: 15 }}
                to="/AddProducts"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="plus" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Liberar Produtos
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.admin ? (
              <NavLink
                style={{ marginLeft: 15 }}
                to="/showProducts"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="clipboard-list" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar Produtos Liberados
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.admin ? (
              <NavLink
                style={{ marginLeft: 15 }}
                to="/showProductsGeneral"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="clipboard-list" className="mr-3" />

                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar Produtos
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}

        {!franc ? (
          <>
            {profile.admin ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeFranc}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />

                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Franquedo
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.admin ? (
              <button type="button" onClick={changeFranc}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />

                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Franquedo
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}
        {franc ? (
          <ContainerAnimation>
            {profile.admin ? (
              <NavLink
                to="/showFranchisee"
                activeClassName="activeClass"
                className="navLink"
                style={{ marginLeft: 15 }}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />

                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar Franqueados
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}

            {profile.admin ? (
              <NavLink
                to="/addFranchisee"
                activeClassName="activeClass"
                className="navLink"
                style={{ marginLeft: 15 }}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />

                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cadastrar Franqueado
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.admin ? (
              <NavLink
                to="/franchiseeUpdate"
                activeClassName="activeClass"
                className="navLink"
                style={{ marginLeft: 15 }}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />

                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Editar Franqueado
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}
        {!cards ? (
          <>
            {profile.franchisee && profile.card ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeCards}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />

                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cartão
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.franchisee && profile.card ? (
              <button type="button" onClick={changeCards}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cartão
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}
        {cards ? (
          <ContainerAnimation>
            {profile.franchisee && profile.card ? (
              <NavLink
                to="/addCard"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="address-card" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cadastrar cartão
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee && profile.card ? (
              <NavLink
                to="/uploadCards"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="address-card" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Upload de Imagem
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee && profile.card ? (
              <NavLink
                to="/showCardFranchisee"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="address-card" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar Cartões
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}
        {!emit ? (
          <>
            {profile.franchisee ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeEmit}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Emitente / Prestador
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.franchisee ? (
              <button type="button" onClick={changeEmit}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Emitente / Prestador
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}

        {emit ? (
          <ContainerAnimation>
            {profile.franchisee ? (
              <NavLink
                to="/addIssuer"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cadastrar Emitente / Prestador
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee ? (
              <NavLink
                to="/showIssuer"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar Emitentes / Prestador
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee ? (
              <NavLink
                to="/issuerUpdate"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Editar Emitente / Prestador
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}

        {!vend ? (
          <>
            {profile.franchisee ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeVend}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Vendedor
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.franchisee ? (
              <button type="button" onClick={changeVend}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Vendedor
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}
        {vend ? (
          <ContainerAnimation>
            {profile.franchisee ? (
              <NavLink
                to="/addSeller"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cadastrar vendedor
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee ? (
              <NavLink
                to="/showSeller"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar vendedores
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee ? (
              <NavLink
                to="/sellerUpdate"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Editar vendedores
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}
        {!recep ? (
          <>
            {profile.franchisee || profile.provider ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeRecep}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Recepcionista
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.franchisee || profile.provider ? (
              <button type="button" onClick={changeRecep}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Recepcionista
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}
        {recep ? (
          <ContainerAnimation>
            {profile.franchisee || profile.provider ? (
              <NavLink
                to="/addReceptionist"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cadastrar recepcionista
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee || profile.provider ? (
              <NavLink
                to="/showReceptionist"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar recepcionista
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee || profile.provider ? (
              <NavLink
                to="/receptionistUpdate"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Editar recepcionista
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}

        {!serv ? (
          <>
            {profile.franchisee && profile.appointments ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeServ}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Serviço
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.franchisee && profile.appointments ? (
              <button type="button" onClick={changeServ}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Serviço
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}
        {serv ? (
          <ContainerAnimation>
            {profile.franchisee && profile.appointments ? (
              <NavLink
                to="/addGeneral"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="plus" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cadastrar serviço
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee && profile.appointments ? (
              <NavLink
                to="/generalService"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="plus" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar serviços
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.franchisee && profile.appointments ? (
              <NavLink
                to="/addService"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="plus" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Linkar serviço
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}

            {profile.franchisee && profile.appointments ? (
              <NavLink
                to="/showServices"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="plus" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Listar serviços Linkados
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}
        {profile.provider || profile.receptionist ? (
          <NavLink
            to="/showAppointment"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="archive" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Buscar Agendamento
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {profile.issuer && profile.card ? (
          <NavLink
            to="/showCards"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="address-card" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Listar cartões
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {profile.issuer ? (
          <NavLink
            to="/showSellerIssuer"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="user" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Listar vendedores
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}

        {profile.seller && profile.appointments ? (
          <NavLink
            to="/createAppointment"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="clipboard-list" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Agendar procedimento
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {profile.seller && profile.appointments ? (
          <NavLink
            to="/listAppointments"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="address-card" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Listar Agendamentos
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {profile.seller && profile.appointments ? (
          <NavLink
            to="/showAppointmentUsers"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  height: 25,
                }}
              >
                <MDBIcon icon="user" className="mr-3" />
                <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                  Pesquisar Cliente
                </h5>
              </div>
            </MDBListGroupItem>
          </NavLink>
        ) : null}
        {!clie ? (
          <>
            {profile.seller ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeClie}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cliente
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.seller ? (
              <button type="button" onClick={changeClie}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cliente
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}
        {clie ? (
          <ContainerAnimation>
            {profile.seller ? (
              <NavLink
                exact
                to="/addClient"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cadastrar cliente
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.seller ? (
              <NavLink
                exact
                to="/clientUpdate"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="user" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Editar Cliente
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}

        {!pro ? (
          <>
            {profile.seller && profile.products ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changePro}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Produtos
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.seller && profile.products ? (
              <button type="button" onClick={changePro}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Produtos
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}
        {pro ? (
          <ContainerAnimation>
            {profile.seller && profile.products ? (
              <NavLink
                to="/createProductsCart"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="clipboard-list" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Reservar produtos
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.seller && profile.products ? (
              <NavLink
                to="/paymantValidationProducts"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="dollar-sign" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Realizar pagamento de produto
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}

        {!car ? (
          <>
            {profile.seller && profile.card ? (
              <button
                type="button"
                activeClassName="activeClass"
                onClick={changeCar}
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-down" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cartão
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        ) : (
          <>
            {profile.seller && profile.card ? (
              <button type="button" onClick={changeCar}>
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="arrow-up" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Cartão
                    </h5>
                  </div>
                </MDBListGroupItem>
              </button>
            ) : null}
          </>
        )}

        {car ? (
          <ContainerAnimation>
            {profile.seller && profile.card ? (
              <NavLink
                to="/createCardLink"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="address-card" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Gerar cartão para cliente
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
            {profile.seller && profile.card ? (
              <NavLink
                to="/validationCardPayed"
                activeClassName="activeClass"
                className="navLink"
              >
                <MDBListGroupItem>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      alignItems: 'center',
                      height: 25,
                    }}
                  >
                    <MDBIcon icon="dollar-sign" className="mr-3" />
                    <h5
                      style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}
                    >
                      Realizar pagamento de cartão
                    </h5>
                  </div>
                </MDBListGroupItem>
              </NavLink>
            ) : null}
          </ContainerAnimation>
        ) : null}

        {/* {profile.admin ? (
          <NavLink
            to="/balance"
            activeClassName="activeClass"
            className="navLink"
          >
            <MDBListGroupItem>
              <MDBIcon icon="dollar-sign" className="mr-3" />
              Balanço
            </MDBListGroupItem>
          </NavLink>
        ) : null} */}
        <button type="button" onClick={handleSignOut}>
          <MDBListGroupItem>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                height: 25,
              }}
            >
              <MDBIcon icon="sign-out-alt" className="mr-3" />
              <h5 style={{ fontSize: 15, fontWeight: '400', marginTop: 10 }}>
                Sair
              </h5>
            </div>
          </MDBListGroupItem>
        </button>
      </MDBListGroup>
    </Container>
  );
}
