import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import { formatPrice } from '../../../utils/format';

import { addToCartSuccess } from './actions';

function* addToCart({ id }) {
  const response = yield call(api.get, `servicesid/${id}`);
  const data = {
    ...response.data[0],
    priceFormatted: formatPrice(response.data[0].price),
  };
  yield put(addToCartSuccess(data));
  toast.success('Adicionado ao carrinho!');
  // history.push('/cart');
}

export default all([takeLatest('@cart/ADD_REQUEST', addToCart)]);
