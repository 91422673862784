import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import NotFound from '../pages/NotFound';
import Dashboard from '../pages/Dashboard';
import AddClient from '../pages/AddClient';
import AddGeneral from '../pages/AddGeneral';
import FranchiseeUpdate from '../pages/FranchiseeUpdate';
import ShowCards from '../pages/ShowCards';
import ShowAppointments from '../pages/ShowAppointments';
import CreateAppointment from '../pages/CreateAppointments';
import CreateCardLink from '../pages/CreateCardLink';
import ValidationCardPayed from '../pages/ValidationCardPayed';
import CreatePartner from '../pages/AddPartner';
import AddFranchisee from '../pages/AddFranchisee';
import ShowFranchisee from '../pages/ShowFranchisee';
import AddSeller from '../pages/AddSeller';
import AddCard from '../pages/AddCard';
import AddServices from '../pages/AddServices';
import AddIssuer from '../pages/AddIssuer';
import AddReceptionist from '../pages/AddReceptionist';
import UploadCards from '../pages/UploadCards';

import ShowIssuer from '../pages/ShowIssuer';
import ShowSeller from '../pages/ShowSeller';
import ShowSellerIssuer from '../pages/ShowSellerIssuer';
import ShowProducts from '../pages/ShowProducts';
import ShowProductsGeneral from '../pages/ShowProductsGeneral';
import ShowServices from '../pages/ShowServices';
import Cart from '../pages/Cart';
import CartProduct from '../pages/CartProduct';

import paymantCard from '../pages/PaymantCard';
import AddGeneralProducts from '../pages/AddGeneralProducts';
import AddProducts from '../pages/AddProducts';
import CreateProductsCart from '../pages/CreateProductsCart';
import ShowProductsToPay from '../pages/ShowProductsToPay';
import PaymantCardProduct from '../pages/PaymantCardProduct';
import Fature from '../pages/ShowFature';
import ClientUpdate from '../pages/ClientUpdate';
import ShowCardFranchisee from '../pages/ShowCardFranchisee';
import Profile from '../pages/Profile';
import IssuerUpdate from '../pages/IssuerUpdate';
import SellerUpdate from '../pages/SellerUpdate';
import CalendarFature from '../pages/CalendarFature';
import Money from '../pages/DashboardMoney';
import ShowGeneral from '../pages/ShowGeneral';
import ShowAppointmentsSeller from '../pages/ShowAppointmentsSeller';
import ShowReceptionist from '../pages/ShowReceptionist';
import ReceptionistUpdate from '../pages/ReceptionistUpdate';
import ShowAppointmentsUsers from '../pages/ShowAppointmentsUsers';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/addClient" component={AddClient} isPrivate />
      <Route path="/addReceptionist" component={AddReceptionist} isPrivate />
      <Route path="/showReceptionist" component={ShowReceptionist} isPrivate />
      <Route
        path="/receptionistUpdate"
        component={ReceptionistUpdate}
        isPrivate
      />
      <Route path="/uploadCards" component={UploadCards} isPrivate />

      <Route path="/clientUpdate" component={ClientUpdate} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/issuerUpdate" component={IssuerUpdate} isPrivate />
      <Route path="/sellerUpdate" component={SellerUpdate} isPrivate />
      <Route path="/calendar" component={CalendarFature} isPrivate />
      <Route path="/dashboardMoney" component={Money} isPrivate />
      <Route path="/generalService" component={ShowGeneral} isPrivate />
      <Route
        path="/listAppointments"
        component={ShowAppointmentsSeller}
        isPrivate
      />

      <Route path="/addFranchisee" component={AddFranchisee} isPrivate />
      <Route path="/addIssuer" component={AddIssuer} isPrivate />
      <Route path="/cart" component={Cart} isPrivate />
      <Route path="/cartProduct" component={CartProduct} isPrivate />
      <Route path="/franchiseeUpdate" component={FranchiseeUpdate} isPrivate />
      <Route
        path="/paymantValidationProducts"
        component={ShowProductsToPay}
        isPrivate
      />
      <Route path="/fature" component={Fature} isPrivate />
      <Route
        path="/paymantCardProduct"
        component={PaymantCardProduct}
        isPrivate
      />

      <Route path="/paymantCard" component={paymantCard} isPrivate />
      <Route
        path="/addGeneralProducts"
        component={AddGeneralProducts}
        isPrivate
      />
      <Route
        path="/createProductsCart"
        component={CreateProductsCart}
        isPrivate
      />
      <Route path="/AddProducts" component={AddProducts} isPrivate />
      <Route path="/addSeller" component={AddSeller} isPrivate />
      <Route path="/addCard" component={AddCard} isPrivate />
      <Route path="/addService" component={AddServices} isPrivate />
      <Route path="/addGeneral" component={AddGeneral} isPrivate />
      <Route path="/createPartner" component={CreatePartner} isPrivate />
      <Route path="/showCards" component={ShowCards} isPrivate />
      <Route path="/showAppointment" component={ShowAppointments} isPrivate />
      <Route
        path="/showAppointmentUsers"
        component={ShowAppointmentsUsers}
        isPrivate
      />

      <Route path="/showFranchisee" component={ShowFranchisee} isPrivate />
      <Route
        path="/showCardFranchisee"
        component={ShowCardFranchisee}
        isPrivate
      />

      <Route path="/showSeller" component={ShowSeller} isPrivate />
      <Route path="/showSellerIssuer" component={ShowSellerIssuer} isPrivate />
      <Route path="/showProducts" component={ShowProducts} isPrivate />
      <Route
        path="/showProductsGeneral"
        component={ShowProductsGeneral}
        isPrivate
      />

      <Route path="/showIssuer" component={ShowIssuer} isPrivate />
      <Route path="/showServices" component={ShowServices} isPrivate />
      <Route
        path="/createAppointment"
        component={CreateAppointment}
        isPrivate
      />
      <Route
        path="/validationCardPayed"
        component={ValidationCardPayed}
        isPrivate
      />
      <Route path="/createCardLink" component={CreateCardLink} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      {/* <Route path="/profile" component={Profile} isPrivate /> */}
      <Route component={NotFound} />
    </Switch>
  );
}
