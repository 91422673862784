import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { useSelector } from 'react-redux';
import { Container, Button } from './styles';
import api from '../../services/api';

function ShowServices() {
  const [usersFranchisee, setUsersFranchisee] = useState([]);

  const profile = useSelector((state) => state.user.profile);
  const [reload, setReload] = useState(false);

  async function deactivateUser(deactivate, id) {
    await api.put('servicesFrancheeseController', {
      deactivate: !deactivate,
      id,
    });
    setReload(!reload);
  }
  async function deleteService(id) {
    await api.post('servicesFrancheeseController', {
      id,
    });
    setReload(!reload);
  }
  useEffect(() => {
    async function Appointments() {
      const response = await api.get('servicesFrancheeseController');
      const datas = response.data.filter((info) => {
        return info.product === true;
      });
      const datas2 = datas.filter((info) => {
        return info.created.id === profile.id;
      });
      setUsersFranchisee({
        columns: [
          {
            label: 'id',
            field: 'id',
            sort: 'asc',
            width: 80,
          },
          {
            label: 'Código',
            field: 'code',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Grupo',
            field: 'group',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Sub-Grupo',
            field: 'subgroup',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Produto',
            field: 'procedure',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Preço com cartão',
            field: 'pricecard',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Preço sem cartão',
            field: 'price',
            sort: 'asc',
            width: 150,
          },
          {
            label: 'Split',
            field: 'split',
            sort: 'asc',
            width: 100,
          },
          {
            label: 'Desativar',
            field: 'deactivate',
            sort: 'asc',
            width: 200,
          },
          {
            label: 'Deletar',
            field: 'deleteService',
            sort: 'asc',
            width: 200,
          },
        ],
        rows: datas2.map((events) => ({
          ...events,
          deactivate: (
            <>
              {events.deactivate ? (
                <Button
                  onClick={() => deactivateUser(events.deactivate, events.id)}
                >
                  Ativar
                </Button>
              ) : (
                <Button
                  onClick={() => deactivateUser(events.deactivate, events.id)}
                >
                  Desativar
                </Button>
              )}
            </>
          ),
          deleteService: (
            <Button onClick={() => deleteService(events.id)}>Deletar</Button>
          ),
        })),
      });
    }
    Appointments();
  }, [reload]);

  return (
    <Container>
      <h1>Mostrar Produtos Liberados</h1>
      <MDBDataTable
        striped
        responsive
        entries={10}
        maxHeight={500}
        scrollX
        data={usersFranchisee}
      />
    </Container>
  );
}

export default ShowServices;
