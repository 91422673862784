import React from 'react';
import { MDBCard, MDBCardBody, MDBIcon, MDBRow, MDBCol } from 'mdbreact';
import { useSelector } from 'react-redux';

export default function AdminCardSection1({ info1, info2, info3, info4 }) {
  const profile = useSelector((state) => state.user.profile);
  return (
    <MDBRow className="mb-4">
      {profile.admin ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color" />
              <div className="data">
                <strong>Rendimento RCB</strong>
                <h4>
                  <strong>{info1}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Redimento total da RCB</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.admin ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color" />
              <div className="data">
                <strong>Rendimento franqueado</strong>
                <h4>
                  <strong>{info2}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Redimento total dos franqueados</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.admin ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color" />
              <div className="data">
                <strong>Rendimento emitente</strong>
                <h4>
                  <strong>{info3}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Redimento total dos emitentes</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
      {profile.admin ? (
        <MDBCol xl="3" md="6" className="mb-r">
          <MDBCard className="cascading-admin-card">
            <div className="admin-up">
              <MDBIcon icon="money-bill-alt" className="primary-color" />
              <div className="data">
                <strong>Rendimento do prestador</strong>
                <h4>
                  <strong>{info4}</strong>
                </h4>
              </div>
            </div>
            <MDBCardBody>
              <strong>Redimento total do prestador</strong>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      ) : null}
    </MDBRow>
  );
}
