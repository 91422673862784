/* eslint-disable prefer-spread */
import React, { useEffect, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';

import { Container } from './styles';
import { updateProfileRequest } from '../../store/modules/user/actions';
import api from '../../services/api';

export default function Profile() {
  const [users, setUsers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [sellersIndividual, setSellersIndividual] = useState([]);
  const [products, setProducts] = useState(false);
  const [appointments, setAppointments] = useState(false);
  const [card, setCard] = useState(false);
  const [issures, setIssures] = useState([]);
  const [issuresIndividual, setIndividualIssures] = useState([]);

  const dispatch = useDispatch();
  const formRef = useRef(null);
  useEffect(() => {
    async function getUsers() {
      const response = await api.get('usersFranchisee');
      setUsers(response.data);
    }
    async function getSellers() {
      const response = await api.get('AllSellerUserController');
      setSellers(response.data);
    }
    async function getIssuers() {
      const response = await api.get('usersIssuer');
      setIssures(response.data);
    }

    getUsers();
    getSellers();
    getIssuers();
  }, []);

  function handleSubmit(datas) {
    const splitSeller = [];
    const splitcardSeller = [];
    const splitproductSeller = [];
    const splitIssuer = [];
    const splitcardIssuer = [];
    const splitproductIssuer = [];
    for (let i = 0; i < sellersIndividual.length; i += 1) {
      splitSeller[i] = sellersIndividual[i].split;
      splitcardSeller[i] = sellersIndividual[i].splitcard;
      splitproductSeller[i] = sellersIndividual[i].splitproduct;
    }
    for (let i = 0; i < issuresIndividual.length; i += 1) {
      splitIssuer[i] = issuresIndividual[i].split;
      splitcardIssuer[i] = issuresIndividual[i].splitcard;
      splitproductIssuer[i] = issuresIndividual[i].splitproduct;
    }
    const SsplitSeller = Math.max.apply(Math, splitSeller);
    const SsplitCardSeller = Math.max.apply(Math, splitcardSeller);
    const SsplitproductSeller = Math.max.apply(Math, splitproductSeller);
    const SsplitIssuer = Math.max.apply(Math, splitIssuer);
    const SsplitCardIssuer = Math.max.apply(Math, splitcardIssuer);
    const SsplitproductIssuer = Math.max.apply(Math, splitproductIssuer);

    const data = [{ ...datas, products, appointments, card }];

    if (100 - datas.split - SsplitSeller - SsplitIssuer < 0) {
      toast.error(
        `valor permitido para agendamentos de até, ${
          100 - SsplitSeller - SsplitIssuer
        }`
      );
    } else if (
      100 - datas.splitcard - SsplitCardSeller - SsplitCardIssuer <
      0
    ) {
      toast.error(
        `valor permitido para cartão de até, ${
          100 - SsplitCardSeller - SsplitCardIssuer
        }`
      );
    } else if (
      100 - datas.splitproduct - SsplitproductSeller - SsplitproductIssuer <
      0
    ) {
      toast.error(
        `valor permitido para produtos de até, ${
          100 - SsplitproductSeller - SsplitproductIssuer
        }`
      );
    } else {
      dispatch(updateProfileRequest(data));
    }
  }
  async function userInfo(email) {
    const datas = await users.filter((info) => {
      return info.email === email;
    });
    const datas2 = await sellers.filter((info) => {
      return info.created.created.email === email;
    });
    const issureSho = await issures.filter((info) => {
      return info.created.email === email;
    });
    await setIndividualIssures(issureSho);
    await setSellersIndividual(datas2);
    await setAppointments(datas[0].appointments);
    await setProducts(datas[0].products);
    await setCard(datas[0].card);
    setTimeout(function () {
      formRef.current.setData(datas[0]);
    }, 300);
  }

  return (
    <Container>
      <Form ref={formRef} autocomplete="false" onSubmit={handleSubmit}>
        <h1>Editar Franqueado</h1>
        <h2>Buscar Cliente</h2>
        <select
          onChange={(e) => userInfo(e.currentTarget.value)}
          name="Pacient"
        >
          <option value={1}>Escolha o nome</option>
          {users.map(function (object) {
            return <option value={object.email}>{object.name}</option>;
          })}
        </select>

        <h2>E-mail</h2>
        <Input
          name="email"
          readOnly
          autocapitalize="off"
          placeholder="Seu endereço completo"
        />
        <h2>Nome</h2>
        <Input name="name" placeholder="Nome completo" />
        <h2>Senha</h2>
        <Input type="password" name="password" placeholder="Nova senha" />
        <h2>Cnpj</h2>
        <InputMask
          name="cnpj"
          placeholder="Digite o cnpj"
          mask="99.999.999/9999-99"
        />
        <h2>Telefone</h2>

        <Input name="phone" placeholder="Digite o telefone" maxlength="11" />
        <div style={{ marginTop: 30 }}>
          <h2 style={{ marginRight: -300 }}>Produtos</h2>
          <input
            style={{ marginRight: -232 }}
            type="checkbox"
            checked={products}
            onChange={() => setProducts(!products)}
          />
          {products ? (
            <div>
              <h2>Split de produto em %</h2>
              <Input style={{ marginLeft: 60 }} name="splitproduct" number />
            </div>
          ) : null}
        </div>
        <div>
          <h2 style={{ marginRight: -278 }}>Cartão</h2>
          <input
            style={{ marginRight: -232 }}
            type="checkbox"
            checked={card}
            onChange={() => setCard(!card)}
          />
          {card ? (
            <div>
              <h2>Split de cartão em %</h2>
              <Input style={{ marginLeft: 72 }} name="splitcard" number />
            </div>
          ) : null}
        </div>
        <div>
          <h2 style={{ marginRight: -349 }}>Agendamento</h2>
          <input
            style={{ marginRight: -232 }}
            type="checkbox"
            checked={appointments}
            onChange={() => setAppointments(!appointments)}
          />
          {appointments ? (
            <div>
              <h2>Split de agendamento em %</h2>
              <Input name="split" number />
            </div>
          ) : null}
        </div>
        <button type="submit">Atualizar perfil</button>
      </Form>
    </Container>
  );
}
