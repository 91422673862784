import React, { useState, useEffect } from 'react';
// import cep from 'cep-promise';
import { MDBIcon } from 'mdbreact';
import { toast } from 'react-toastify';
import {
  Container,
  Content,
  InputBig,
  RegisterButton,
  InputSmall,
} from './styles';
import api from '../../services/api';

import useForm from '../../utils/useForm';

export default function AddServices() {
  const [{ values }, handleChange, handleSubmit] = useForm();
  const [loading2, setLoading] = useState(false);
  const [morning, setMorning] = useState(false);
  const [evening, setEvening] = useState(false);
  const [seg, setSeg] = useState(false);
  const [ter, setTer] = useState(false);
  const [quar, setQuar] = useState(false);
  const [quin, setQuin] = useState(false);
  const [sex, setSex] = useState(false);
  const [sab, setSab] = useState(false);
  const [dom, setDom] = useState(false);
  const [provider, setProvider] = useState([]);
  const [general, setGeneral] = useState([]);
  const [procedure, setProcedure] = useState([]);
  const [allservices, setAllServices] = useState([]);

  const [sub, setSub] = useState([]);
  const [group, setGroup] = useState([]);

  const [Allprovider, setAllProvider] = useState([]);

  useEffect(() => {
    async function loadProviders() {
      const response = await api.get('providers');

      setAllProvider(response.data);
    }
    async function loadGeneral() {
      const response = await api.get('general');

      const servicelocal = response.data.map(function (item) {
        return item.group;
      });
      const result = servicelocal.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
      setGeneral(result);

      setAllServices(response.data);
    }

    loadProviders();
    loadGeneral();
  }, []);
  async function enviar() {
    if (
      procedure &&
      values.name &&
      values.crm &&
      values.pricecard &&
      values.price &&
      values.splitcard &&
      values.split !== ''
    ) {
      setLoading(true);

      const response = await api.post('services', {
        id: Number(provider),
        code: Number(procedure[0].code),
        group: procedure[0].group,
        subgroup: procedure[0].subgroup,
        procedure: procedure[0].procedure,
        name: values.name,
        crm: values.crm,
        product: false,
        evening,
        morning,
        seg,
        ter,
        quar,
        quin,
        sex,
        sab,
        dom,
        pricecard: values.pricecard,
        price: values.price,
        split: values.split,
        splitcard: values.splitcard,
      });
      setLoading(true);
      if (response.status === 200) {
        toast.success('Serviço criado com sucesso.');
        setLoading(false);
      } else if (response.status === 203) {
        setLoading(false);
        toast.error(
          'Erro ao criar serviço, verifque os dados ou você não possui permissão.'
        );
      }
    } else {
      toast.error('Todos os campos devem estar preenchidos!');
    }
  }
  function handleChangeProvider(e) {
    setProvider(e);
  }
  function handleChangeGeneral(e) {
    const datas = allservices.filter((info) => {
      return info.procedure === e;
    });

    setProcedure(datas);
  }
  function handleChangeSubGroup(e) {
    const datas = allservices.filter((info) => {
      return info.subgroup === e;
    });
    const servicelocal = datas.map(function (item) {
      return item.procedure;
    });
    const result = servicelocal.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setSub(result);
  }
  function handleChangeGroup(e) {
    const datas = allservices.filter((info) => {
      return info.group === e;
    });
    const servicelocal = datas.map(function (item) {
      return item.subgroup;
    });
    const result = servicelocal.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      []
    );
    setGroup(result);
  }
  function handleChangeCode(e) {
    const datas = allservices.filter((info) => {
      return info.code === Number(e);
    });
    setProcedure(datas);
  }
  return (
    <Container>
      <Content horizontal={false}>
        <h1>Linkar serviço</h1>

        <form onSubmit={handleSubmit(enviar)}>
          <h2>Prestador</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeProvider(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o prestador</option>
            {Allprovider.map(function (object) {
              return <option value={object.id}>{object.name}</option>;
            })}
          </select>
          <h2>Grupo</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeGroup(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o grupo</option>
            {general.map(function (object) {
              return <option value={object}>{object}</option>;
            })}
          </select>
          <h2>Sub-Grupo</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeSubGroup(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o sub-grupo</option>
            {group.map(function (object) {
              return <option value={object}>{object}</option>;
            })}
          </select>
          <h2>Procedimento</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeGeneral(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o procedimento</option>
            {sub.map(function (object) {
              return <option value={object}>{object}</option>;
            })}
          </select>

          <h2>Código</h2>
          <select
            style={{ width: 735 }}
            onChange={(e) => handleChangeCode(e.currentTarget.value)}
            name="Pacient"
          >
            <option value={1}>Escolha o código</option>
            {procedure.map(function (object) {
              return <option value={object.code}>{object.code}</option>;
            })}
          </select>
          <h2>Nome do médico</h2>
          <InputBig onChange={handleChange} name="name" type="text" />
          <h2>CRM do médico</h2>
          <InputBig onChange={handleChange} name="crm" type="text" />
          <h2>Preço com cartão</h2>
          <InputBig onChange={handleChange} name="pricecard" type="text" />
          <div>
            <div>
              <h2>Split com cartão (%) </h2>
              <InputBig
                onChange={handleChange}
                style={{ width: 100 }}
                name="splitcard"
                type="text"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -450 }}>
                Valor Rede: R$
                {Number.isNaN((values.splitcard / 100) * values.pricecard)
                  ? 0
                  : ((values.splitcard / 100) * values.pricecard).toFixed(2)}
              </h2>
              <h2 style={{ marginLeft: 0 }}>
                Valor Prestador: R$
                {Number.isNaN(
                  values.pricecard - (values.splitcard / 100) * values.pricecard
                )
                  ? 0
                  : (
                      values.pricecard -
                      (values.splitcard / 100) * values.pricecard
                    ).toFixed(2)}
              </h2>
            </div>
          </div>
          <h2>Preço sem cartão</h2>
          <InputBig onChange={handleChange} name="price" type="text" />
          <div>
            <div>
              <h2>Split sem cartão (%) </h2>
              <InputBig
                onChange={handleChange}
                style={{ width: 100 }}
                name="split"
                type="text"
              />
            </div>
            <div style={{ marginBottom: 10 }}>
              <h2 style={{ marginLeft: -450 }}>
                Valor Rede: R$
                {Number.isNaN((values.split / 100) * values.price)
                  ? 0
                  : ((values.split / 100) * values.price).toFixed(2)}
              </h2>
              <h2 style={{ marginLeft: 0 }}>
                Valor Prestador: R$
                {Number.isNaN(
                  values.price - (values.split / 100) * values.price
                )
                  ? 0
                  : (
                      values.price -
                      (values.split / 100) * values.price
                    ).toFixed(2)}
              </h2>
            </div>
          </div>

          <div style={{ marginBottom: 15, marginTop: 15 }}>
            <div>
              <h2 style={{ marginLeft: 85 }}>Manhã</h2>
              <InputSmall
                type="checkbox"
                value={morning}
                onChange={() => setMorning(!morning)}
                name="morning"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -207 }}>Tarde</h2>
              <InputSmall
                type="checkbox"
                value={evening}
                onChange={() => setEvening(!evening)}
                name="evening"
              />
            </div>
          </div>
          <div style={{ marginLeft: 3 }}>
            <div>
              <h2>Seg</h2>
              <InputSmall
                type="checkbox"
                value={seg}
                onChange={() => setSeg(!seg)}
                name="seg"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -105 }}>Ter</h2>
              <InputSmall
                type="checkbox"
                value={ter}
                onChange={() => setTer(!ter)}
                name="ter"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -210 }}>Quar</h2>
              <InputSmall
                type="checkbox"
                value={quar}
                onChange={() => setQuar(!quar)}
                name="quar"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -315 }}>Quin</h2>
              <InputSmall
                type="checkbox"
                value={quin}
                onChange={() => setQuin(!quin)}
                name="quin"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -420 }}>Sex</h2>
              <InputSmall
                type="checkbox"
                value={sex}
                onChange={() => setSex(!sex)}
                name="morning"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -525 }}>Sab</h2>
              <InputSmall
                type="checkbox"
                value={sab}
                onChange={() => setSab(!sab)}
                name="evening"
              />
            </div>
            <div>
              <h2 style={{ marginLeft: -630 }}>Dom</h2>
              <InputSmall
                type="checkbox"
                value={dom}
                onChange={() => setDom(!dom)}
                name="morning"
              />
            </div>
          </div>

          <RegisterButton type="submit">
            {loading2 ? (
              <>
                <MDBIcon icon="spinner" spin size="2x" fixed />
                <span className="sr-only">Carregando...</span>
              </>
            ) : (
              'Cadastrar'
            )}
          </RegisterButton>
        </form>
      </Content>
    </Container>
  );
}
